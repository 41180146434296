import { categories, IMarketplaceBlueprint, mainCategories } from '@/models'
import { MarketplaceBlueprintsTableColumns } from '@/modules/marketplace/blueprints/columns'
import { useQueryGetMarketplaceBlueprints } from '@/services/marketplace-cms.service'

export const useMarketplaceBlueprintsTable = () => {
  const { data, isFetching } = useQueryGetMarketplaceBlueprints()

  return {
    data: (data?.marketplace_blueprints?.map((blueprint) => ({
      ...blueprint,
      main_category: mainCategories.find(
        (category) => category.id === blueprint.main_category
      )?.name as string,
      categories: (blueprint.categories
        ?.map((category) => categories.find((c) => c.id === category)?.name)
        .filter((c) => Boolean(c)) || []) as string[]
    })) || []) as IMarketplaceBlueprint[],
    columns: MarketplaceBlueprintsTableColumns,
    isLoading: isFetching
  }
}
