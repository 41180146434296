import { getClient } from '@epilot/organization-client-internal'
import { useMemo } from 'react'

import { config } from '../configs/api.config'

import { configureClient } from './api-utils'

let client: ReturnType<typeof getClient>

export const getOrganizationClient = () => {
  if (!client) {
    client = getClient()

    configureClient(client, {
      baseURL: config.ORGANIZATION
    })
  }

  return client
}

export const useOrganizationClient = () =>
  useMemo(() => getOrganizationClient(), [])

export type {
  Components as OrganizationComponents,
  Paths as OrganizationPaths
} from '@epilot/organization-client-internal'
