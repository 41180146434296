import { Role } from '@epilot/permissions/types'
import { ChevronDownIcon } from 'lucide-react'
import { Link } from 'react-router-dom'

import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import { DataTableColumnHeader } from '@/components/ui/data-table-column-header'
import { DataTableRowActions } from '@/components/ui/data-table-row-actions'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu'
import { IUser, Status, TableColumn } from '@/models'
import { RoutePaths } from '@/routes/routes'
import { unsensitiveFilter } from '@/utils'

export const UserTableColumns: TableColumn<IUser>[] = [
  {
    id: 'select',
    header: ({ table }) => (
      <div className="flex items-center space-x-2">
        <Checkbox
          aria-label="Select all in the page"
          checked={table.getIsAllPageRowsSelected()}
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        />
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <div className="cursor-pointer">
              <ChevronDownIcon className="w-4 h-4" />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="flex items-center w-56">
            <Checkbox
              aria-label="Select all"
              checked={table.getIsAllRowsSelected()}
              className="mr-2"
              id="select-all"
              onCheckedChange={(value) => table.toggleAllRowsSelected(!!value)}
            />
            <span>Select all items</span>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    ),
    cell: ({ row }) => (
      <Checkbox
        aria-label="Select row"
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
      />
    ),
    enableSorting: false,
    enableHiding: false,
    enableResizing: false,
    size: 40,
    enableGlobalFilter: false,
    enableColumnFilter: false
  },
  {
    id: 'id',
    accessorKey: 'id',
    displayName: 'ID',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="ID" />
    ),
    enableResizing: false,
    size: 60,
    filterFn: unsensitiveFilter,
    sortingFn: (a, b) => {
      const idA = a.original.id
      const idB = b.original.id

      if (!idA && !idB) {
        return 0
      } else if (!idA) {
        return 1
      } else if (!idB) {
        return -1
      }

      return parseInt(idA) - parseInt(idB)
    }
  },
  {
    id: 'display_name',
    accessorKey: 'display_name',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Name" />
    ),
    displayName: 'Name',
    size: 300,
    enableResizing: true,
    sortUndefined: 1,
    filterFn: unsensitiveFilter
  },
  {
    id: 'email',
    accessorFn: (row) => (row.email ? row.email : undefined),
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Email" />
    ),
    displayName: 'Email',
    sortUndefined: -1,
    filterFn: unsensitiveFilter
  },
  {
    id: 'status',
    accessorFn: (row) => (row.status ? row.status : undefined),
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Status" />
    ),
    cell: ({ row }) => {
      const status = Status.find(
        (status) => status.value === row.getValue('status')
      )

      if (!status) {
        return null
      }

      return (
        <div className="flex w-[100px] items-center">
          {status.icon && (
            <status.icon className="w-4 h-4 mr-2 text-muted-foreground" />
          )}
          <span>{status.label}</span>
        </div>
      )
    },
    displayName: 'Status',
    sortUndefined: -1,
    sortingFn: (a, b) => {
      const statusA = a.original.status
      const statusB = b.original.status

      if (statusA === null && statusB === null) {
        return 0
      }

      if (statusA === null) {
        return -1
      }

      if (statusB === null) {
        return 1
      }

      if (statusA === 'Deactivated' && statusB === 'Deactivated') {
        return 0
      }

      if (statusA === 'Deactivated') {
        return -1
      }

      if (statusB === 'Deactivated') {
        return 1
      }

      if (statusA === 'Pending' && statusB === 'Pending') {
        return 0
      }

      if (statusA === 'Pending') {
        return -1
      }

      if (statusB === 'Pending') {
        return 1
      }

      return 0
    },
    filterFn: unsensitiveFilter
  },
  {
    id: 'organization',
    accessorFn: (row) =>
      row.organization?.name ? row.organization?.name : undefined,
    displayName: 'Organization',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Organization" />
    ),
    filterFn: unsensitiveFilter,
    cell: ({ row }) => {
      return (
        <Button className="p-0" variant={'link'}>
          <Link
            onClick={(e) => {
              e.stopPropagation()
            }}
            to={`${RoutePaths.ORGANIZATIONS}/${row.original.organization?.id}`}
          >
            {row.original.organization?.name ?? undefined}
          </Link>
        </Button>
      )
    }
  },
  {
    id: 'roles',
    enableSorting: false,
    accessorFn: (row) =>
      row.roles ? row.roles?.map((x) => x?.name) : undefined,
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="360 Roles" />
    ),
    displayName: '360 Roles',
    filterFn: unsensitiveFilter,
    enableGlobalFilter: false,
    maxSize: 130,
    cell: ({ row }) => {
      const roles: Role[] = row.getValue('roles') ?? []

      return (
        <div className="flex h-full py-3 overflow-y-scroll">
          {roles?.map((role) => (
            <Badge
              className="mr-2 cursor-pointer hover:bg-secondary min-w-min whitespace-nowrap"
              key={role?.id + row.index}
              onClick={(e) => {
                e.stopPropagation()
              }}
              variant={'outline'}
            >
              {role?.name}
            </Badge>
          ))}
        </div>
      )
    }
  },
  {
    id: 'tags',
    accessorKey: 'tags',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Tags" />
    ),
    enableSorting: false,
    enableResizing: true,
    displayName: 'Tags',
    filterFn: unsensitiveFilter,
    enableGlobalFilter: false,
    maxSize: 130,
    cell: ({ cell, row }) => {
      return (
        <div className="flex h-full py-3 -m-2 overflow-y-scroll">
          {((cell?.getValue() as undefined | string[]) ?? []).map((tag) => (
            <Badge
              className="mr-2 min-w-min whitespace-nowrap"
              key={tag + row.index}
              variant={'outline'}
            >
              {tag}
            </Badge>
          ))}
        </div>
      )
    }
  },
  {
    id: 'created_at',
    accessorKey: 'created_at',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Creation Date" />
    ),
    accessorFn: (row) => (row.created_at ? row.created_at : undefined),
    cell: ({ row }) => {
      const date = new Date(row.original.created_at!)

      return date.toLocaleString()
    },
    sortingFn: (a, b) => {
      const dateA = new Date(a.original.created_at!)
      const dateB = new Date(b.original.created_at!)

      if (dateA === null && dateB === null) {
        return 0
      } else if (dateA === null) {
        return 1
      } else if (dateB === null) {
        return -1
      }

      return dateA.getTime() - dateB.getTime()
    },
    displayName: 'Creation Date',
    sortUndefined: -1,
    enableGlobalFilter: false,
    enableColumnFilter: false
  },
  {
    id: 'actions',
    cell: ({ row }) => {
      return <DataTableRowActions row={row} />
    },
    enableSorting: false,
    enableHiding: false,
    enableResizing: false,
    size: 30
  }
]
