import { CheckIcon } from '@radix-ui/react-icons'
import { Column } from '@tanstack/react-table'
import * as _ from 'lodash'
import { FilterIcon } from 'lucide-react'
import React from 'react'

import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator
} from '@/components/ui/command'
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/components/ui/popover'
import { Separator } from '@/components/ui/separator'
import { cn } from '@/lib/utils'

interface DataTableFacetedFilterProps<TData, TValue> {
  column: Column<TData, TValue>
}

export function DataTableFacetedFilter<TData, TValue>({
  column
}: DataTableFacetedFilterProps<TData, TValue>) {
  const selectedValues = new Set(column?.getFilterValue() as string[])

  const [input, setInput] = React.useState('')

  const options: string[] = React.useMemo(
    () =>
      _.uniq(
        Array.from(column.getFacetedUniqueValues().keys())
          .flat()
          .filter((i) => Boolean(i))
          .sort()
      ),
    [column.getFacetedUniqueValues()]
  )

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button className="h-8 border-dashed" size="sm" variant="outline">
          <FilterIcon className="h-4 w-4" />
          {selectedValues?.size > 0 && (
            <>
              <Separator className="mx-2 h-4" orientation="vertical" />
              <Badge
                className="rounded-sm px-1 font-normal lg:hidden"
                variant="secondary"
              >
                {selectedValues.size}
              </Badge>
              <div className="hidden space-x-1 lg:flex">
                {selectedValues.size > 2 ? (
                  <Badge
                    className="rounded-sm px-1 font-normal"
                    variant="secondary"
                  >
                    {selectedValues.size} selected
                  </Badge>
                ) : (
                  options
                    .filter((option) => selectedValues.has(option))
                    .map((option) => (
                      <Badge
                        className="rounded-sm px-1 font-normal"
                        key={option}
                        variant="secondary"
                      >
                        {option}
                      </Badge>
                    ))
                )}
              </div>
            </>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent align="start" className="w-[200px] p-0">
        <Command>
          <CommandInput
            onValueChange={(v) => setInput(v)}
            placeholder="Search"
            value={input}
          />
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup>
              {options
                .filter((o) =>
                  input
                    ? o
                        ?.toString()
                        ?.toLowerCase()
                        ?.includes(input.toString()?.toLowerCase())
                    : true
                )
                .map((opt) => {
                  const option = _.isArray(opt) ? opt[0] : opt
                  const isSelected = selectedValues.has(option)

                  return (
                    <CommandItem
                      key={option}
                      onSelect={() => {
                        if (isSelected) {
                          selectedValues.delete(option)
                        } else {
                          selectedValues.add(option)
                        }
                        const filterValues = Array.from(selectedValues)

                        column?.setFilterValue(
                          filterValues.length ? filterValues : undefined
                        )
                      }}
                    >
                      <div
                        className={cn(
                          'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-border border-primary',
                          isSelected
                            ? 'bg-primary'
                            : 'opacity-50 [&_svg]:invisible'
                        )}
                      >
                        <CheckIcon className={cn('h-4 w-4')} />
                      </div>

                      <span>{option}</span>
                    </CommandItem>
                  )
                })}
            </CommandGroup>
          </CommandList>
          {selectedValues.size > 0 && (
            <>
              <CommandSeparator />
              <CommandGroup>
                <CommandItem
                  className="justify-center text-center"
                  onSelect={() => column?.setFilterValue(undefined)}
                >
                  Clear filters
                </CommandItem>
              </CommandGroup>
            </>
          )}
        </Command>
      </PopoverContent>
    </Popover>
  )
}
