import { Navigate } from 'react-router-dom'

import { UserAuthForm } from './user-auth-form'

import { Icons } from '@/components/ui/icons'
import { useAuth } from '@/providers/AuthContextProvider'
import { RoutePaths } from '@/routes/routes'
export default function AuthenticationPage() {
  const { userData } = useAuth()

  if (userData) {
    return <Navigate to={RoutePaths.HOME} />
  }

  return (
    <>
      <div className="text-primary container relative flex-col items-center justify-center h-screen lg:grid lg:max-w-none lg:grid-cols-2 px-0 bg-muted">
        <div className="relative flex-col h-full p-10 lg:flex sm:hidden hidden">
          <div className="absolute inset-0 bg-background" />
          <div className="relative z-20 flex items-center text-lg font-medium">
            <Icons.logo className="w-16 h-16 fill-primary" />
          </div>
          <div className="relative z-20 mt-auto">
            <blockquote className="space-y-2">
              <p className="text-lg">epilot Admin Portal</p>
              <footer className="text-sm">Made with ❤️ by Raven</footer>
            </blockquote>
          </div>
        </div>
        <div className="bg-login h-full items-center flex p-8">
          <div className="mx-auto flex w-full flex-col justify-center space-y-6">
            <div className="flex flex-col space-y-2 text-center">
              <div className="lg:hidden flex justify-center">
                <Icons.logo className="w-16 h-16 fill-primary" />
              </div>
              <h1 className="text-2xl font-semibold text-primary tracking-tight">
                Login to your account
              </h1>
              <p className="text-sm text-muted-foreground">
                Use one of the following methods to login
              </p>
            </div>
            <UserAuthForm />
          </div>
        </div>
      </div>
    </>
  )
}
