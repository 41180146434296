import { ChevronDownIcon } from 'lucide-react'

import { Badge } from '@/components/ui/badge'
import { Checkbox } from '@/components/ui/checkbox'
import { DataTableColumnHeader } from '@/components/ui/data-table-column-header'
import { DataTableRowActions } from '@/components/ui/data-table-row-actions'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu'
import { IMarketplaceBlueprintResource, TableColumn } from '@/models'
import { unsensitiveFilter } from '@/utils'

export const MarketplaceBlueprintResourcesTableColumns: TableColumn<IMarketplaceBlueprintResource>[] =
  [
    {
      id: 'select',
      size: 30,
      header: ({ table }) => (
        <div className="flex items-center space-x-2">
          <Checkbox
            aria-label="Select all in the page"
            checked={table.getIsAllPageRowsSelected()}
            onCheckedChange={(value) =>
              table.toggleAllPageRowsSelected(!!value)
            }
          />
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <div className="cursor-pointer">
                <ChevronDownIcon className="w-4 h-4" />
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="flex items-center w-56">
              <Checkbox
                aria-label="Select all"
                checked={table.getIsAllRowsSelected()}
                className="mr-2"
                id="select-all"
                onCheckedChange={(value) =>
                  table.toggleAllRowsSelected(!!value)
                }
              />
              <span>Select all items</span>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      ),
      cell: ({ row }) => (
        <Checkbox
          aria-label="Select row"
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
        />
      ),
      enableSorting: false,
      enableHiding: false,
      enableResizing: false,
      enableGlobalFilter: false,
      enableColumnFilter: false
    },
    {
      id: 'id',
      accessorKey: 'id',
      displayName: 'ID',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="ID" />
      ),
      enableResizing: false,
      filterFn: unsensitiveFilter,
      sortingFn: (a, b) => {
        const idA = a.original.id
        const idB = b.original.id

        if (!idA && !idB) {
          return 0
        } else if (!idA) {
          return 1
        } else if (!idB) {
          return -1
        }

        return parseInt(idA) - parseInt(idB)
      }
    },
    {
      id: 'name',
      accessorKey: 'name',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Name" />
      ),
      displayName: 'Name',
      enableResizing: true,
      sortUndefined: 1,
      filterFn: unsensitiveFilter
    },
    {
      id: 'connected_blueprints',
      accessorKey: 'connected_blueprints',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Connected Blueprints" />
      ),
      displayName: 'Connected Blueprints',
      enableResizing: true,
      sortUndefined: 1,
      filterFn: unsensitiveFilter,
      enableSorting: false,
      enableGlobalFilter: false,
      maxSize: 250,
      cell: ({ cell, row }) => {
        return (
          <div className="flex overflow-auto h-full -m-2 py-3">
            {((cell?.getValue() as undefined | string[]) ?? []).map(
              (blueprint) => (
                <Badge
                  className="mr-2 min-w-min whitespace-nowrap"
                  key={blueprint + row.index}
                  variant={'outline'}
                >
                  {blueprint}
                </Badge>
              )
            )}
          </div>
        )
      }
    },
    {
      id: 'order',
      accessorKey: 'order',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Order" />
      ),
      displayName: 'Order',
      enableResizing: true,
      sortUndefined: 1,
      filterFn: unsensitiveFilter
    },
    {
      id: 'created_at',
      accessorKey: 'created_at',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Creation Date" />
      ),
      accessorFn: (row) => (row.created_at ? row.created_at : undefined),
      cell: ({ row }) => {
        if (!row.original.created_at) {
          return ''
        }

        const date = new Date(row.original.created_at)

        return date.toLocaleString()
      },
      sortingFn: (a, b) => {
        if (!a.original.created_at && !b.original.created_at) {
          return 0
        }

        if (!a.original.created_at) {
          return 1
        }

        if (!b.original.created_at) {
          return -1
        }

        const dateA = new Date(a.original.created_at)
        const dateB = new Date(b.original.created_at)

        if (dateA === null && dateB === null) {
          return 0
        } else if (dateA === null) {
          return 1
        } else if (dateB === null) {
          return -1
        }

        return dateA.getTime() - dateB.getTime()
      },
      displayName: 'Creation Date',
      sortUndefined: -1,
      enableGlobalFilter: false,
      enableColumnFilter: false
    },
    {
      id: 'actions',
      cell: ({ row }) => {
        return <DataTableRowActions row={row} />
      },
      enableSorting: false,
      enableHiding: false,
      enableResizing: false,
      size: 30
    }
  ]
