import { config as URL_CONFIG } from '@/configs/api.config'

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
)

// Assuming you have two redirect URIs, and the first is for localhost and second is for production

export const awsConfig = {
  ...globalThis.EnvConfig.aws,
  oauth: {
    ...globalThis.EnvConfig.aws.oauth,
    redirectSignIn: isLocalhost
      ? 'http://localhost:3002/'
      : globalThis.EnvConfig.aws?.oauth?.redirectSignIn?.split(',')[0],
    redirectSignOut: isLocalhost
      ? 'http://localhost:3002/'
      : globalThis.EnvConfig.aws?.oauth?.redirectSignOut?.split(',')[0]
  },
  API: {
    endpoints: [
      {
        name: 'internal-auth',
        endpoint: URL_CONFIG.API_INTERNAL_AUTH
      }
    ]
  },
  Auth: {
    ...globalThis.EnvConfig.aws.Auth,
    authenticationFlowType: null
  }
}
