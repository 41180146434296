import React, { useState } from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/solarized.css';
import 'codemirror/mode/javascript/javascript';

type JSONEditorProps = {
  originalData: unknown;
  setDataState?: React.Dispatch<React.SetStateAction<unknown>>;
  onUpdate?: (data: unknown) => Promise<void>;
};

const JSONEditor = ({ originalData, setDataState }: JSONEditorProps) => {
  const [editorValue, setEditorValue] = useState(JSON.stringify(originalData, null, 2));
  const [parsingError, setParsingError] = useState<string | null>(null);

  const readOnly = !Boolean(setDataState);

  const handleEditorChange = (_editor: any, _data: any, value: string) => {
    if (readOnly) {
      return;
    }

    setEditorValue(value);
    try {
      const parsedData = JSON.parse(value);
      setDataState?.(parsedData);
      setParsingError(null);
    } catch (error) {
      setParsingError('Invalid JSON');
    }
  };

  return (
    <>
      <div style={{ border: parsingError ? '1px solid red' : 'none' }}>
        <CodeMirror
          value={editorValue}
          options={{
            mode: { name: 'javascript', json: true },
            theme: 'solarized dark',
            lineNumbers: true,
            lineWrapping: true,
            readOnly
          }}
          onBeforeChange={(_editor, _data, value) => {
            setEditorValue(value);
            if (parsingError) setParsingError(null);
          }}
          onChange={handleEditorChange}
          editorDidMount={(editor) => {
            editor.setSize('100%', '100%');
          }}
        />
      </div>
      {parsingError && <div style={{ color: 'red' }}>{parsingError}</div>}
    </>
  );
};

export default JSONEditor;
