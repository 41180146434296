import { createContext, useContext } from 'react'

import { PricingTierComponents } from '@/api/pricing-tier-client'
import {
  useQueryGetPricingTierAssignments,
  useQueryGetPricingTiers
} from '@/services'

export interface PricingTierContext {
  pricingTierAssignments: PricingTierComponents.Schemas.Assignment[]
  pricingTiers: PricingTierComponents.Schemas.PricingTier[]
  isLoading: boolean
}

const PricingTiersContext = createContext<Partial<PricingTierContext>>({})

export const PricingTierContextProvider = (props: {
  children: JSX.Element
}) => {
  const pricingTiersQueryResp = useQueryGetPricingTiers()
  const pricingTierAssignmentsQueryResp = useQueryGetPricingTierAssignments()

  return (
    <PricingTiersContext.Provider
      value={{
        pricingTierAssignments: pricingTierAssignmentsQueryResp.data ?? [],
        pricingTiers: pricingTiersQueryResp.data ?? [],
        isLoading:
          pricingTiersQueryResp.isFetching ||
          pricingTierAssignmentsQueryResp.isFetching
      }}
    >
      {props.children}
    </PricingTiersContext.Provider>
  )
}

export const usePricingTiers = () => useContext(PricingTiersContext)
