import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { getBlueprintClient, BlueprintComponents } from '@/api/blueprint-client'

const blueprintClient = getBlueprintClient()

export const BLUEPRINT_QUERY_IDS = {
  GET_INSTALLED_BLUEPRINTS: 'GET_INSTALLED_BLUEPRINTS',
  GET_AVAILABLE_BLUEPRINTS: 'GET_AVAILABLE_BLUEPRINTS'
}

export type Blueprint = BlueprintComponents.Schemas.Blueprint
export type BlueprintInstallation =
  BlueprintComponents.Schemas.BlueprintInstallation

type UseQueryReturn<T> = ReturnType<typeof useQuery<T>>

export const useQueryGetInstalledBlueprints = (
  orgId: string,
  opts: Partial<UseQueryOptions<BlueprintInstallation[]>> = {}
): UseQueryReturn<BlueprintInstallation[]> => {
  return useQuery<BlueprintInstallation[]>({
    queryKey: [BLUEPRINT_QUERY_IDS.GET_INSTALLED_BLUEPRINTS, orgId],
    queryFn: () =>
      blueprintClient
        .getInstalledBlueprints({}, {}, { headers: { 'x-ivy-org-id': orgId } })
        .then((res) => res.data),
    refetchOnWindowFocus: true,
    ...opts
  })
}

export const useQueryGetAvailableBlueprints = (
  orgId: string,
  opts: Partial<UseQueryOptions<Blueprint[]>> = {}
): UseQueryReturn<Blueprint[]> => {
  return useQuery<Blueprint[]>({
    queryKey: [BLUEPRINT_QUERY_IDS.GET_AVAILABLE_BLUEPRINTS, orgId],
    queryFn: () =>
      blueprintClient
        .listBlueprints({}, {}, { headers: { 'x-ivy-org-id': orgId } })
        .then((res) => res.data?.results ?? []),
    refetchOnWindowFocus: true,
    ...opts
  })
}
