import { getClient } from '@epilot/internal-entity-client'
import { useMemo } from 'react'

import { config } from '../configs/api.config'

import { configureClient } from './api-utils'

let client: ReturnType<typeof getClient>

export const getEntityClient = () => {
  if (!client) {
    client = getClient()

    return configureClient(client, {
      baseURL: config.ENTITY
    })
  }

  return client
}

export const useEntityClient = () => useMemo(() => getEntityClient(), [])

export type { Components as EntityComponents } from '@epilot/internal-entity-client'
