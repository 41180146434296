import { ChevronDownIcon } from 'lucide-react'

import { BlueprintComponents } from '@/api/blueprint-client'
import { Checkbox } from '@/components/ui/checkbox'
import { DataTableColumnHeader } from '@/components/ui/data-table-column-header'
import { DataTableRowActions } from '@/components/ui/data-table-row-actions'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu'
import { TableColumn } from '@/models'
import { unsensitiveFilter } from '@/utils'

export const InstalledBlueprintsColumns: TableColumn<BlueprintComponents.Schemas.BlueprintInstallation>[] =
  [
    {
      id: 'select',
      header: ({ table }) => (
        <div className="flex items-center space-x-2">
          <Checkbox
            aria-label="Select all in the page"
            checked={table.getIsAllPageRowsSelected()}
            onCheckedChange={(value) =>
              table.toggleAllPageRowsSelected(!!value)
            }
          />
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <div className="cursor-pointer">
                <ChevronDownIcon className="h-4 w-4" />
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56 flex items-center">
              <Checkbox
                aria-label="Select all"
                checked={table.getIsAllRowsSelected()}
                className="mr-2"
                id="select-all"
                onCheckedChange={(value) =>
                  table.toggleAllRowsSelected(!!value)
                }
              />
              <span>Select all items</span>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      ),
      cell: ({ row }) => (
        <Checkbox
          aria-label="Select row"
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
        />
      ),
      enableSorting: false,
      enableHiding: false,
      enableResizing: false,
      size: 40,
      enableGlobalFilter: false,
      enableColumnFilter: false
    },
    {
      id: 'id',
      accessorKey: 'blueprint_id',
      displayName: 'ID',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="ID" />
      ),
      enableResizing: false,
      size: 60,
      filterFn: unsensitiveFilter
    },
    {
      id: 'slug',
      accessorKey: 'blueprint_slug',
      displayName: 'Slug',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Slug" />
      ),
      enableResizing: false,
      size: 60,
      filterFn: unsensitiveFilter
    },
    {
      id: 'Version',
      accessorKey: 'installed_version',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Version" />
      ),
      displayName: 'Version',
      size: 300,
      enableResizing: true,
      sortUndefined: 1,
      filterFn: unsensitiveFilter
    },
    {
      id: 'created_at',
      accessorKey: 'created_at',
      displayName: 'Installed Date',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Installed Date" />
      ),
      accessorFn: (row) => (row.created_at ? row.created_at : undefined),
      cell: ({ row }) => {
        const date = new Date(row.original.created_at!)

        return date.toLocaleString()
      },
      sortingFn: (a, b) => {
        const dateA = new Date(a.original.created_at!)
        const dateB = new Date(b.original.created_at!)

        if (dateA === null && dateB === null) {
          return 0
        } else if (dateA === null) {
          return 1
        } else if (dateB === null) {
          return -1
        }

        return dateA.getTime() - dateB.getTime()
      },
      sortUndefined: -1,
      enableGlobalFilter: false,
      enableColumnFilter: false
    },
    {
      id: 'updated_at',
      accessorKey: 'updated_at',
      displayName: 'Last Updated',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Last Updated" />
      ),
      accessorFn: (row) => (row.updated_at ? row.updated_at : undefined),
      cell: ({ row }) => {
        const date = new Date(row.original.updated_at!)

        return date.toLocaleString()
      },
      sortingFn: (a, b) => {
        const dateA = new Date(a.original.updated_at!)
        const dateB = new Date(b.original.updated_at!)

        if (dateA === null && dateB === null) {
          return 0
        } else if (dateA === null) {
          return 1
        } else if (dateB === null) {
          return -1
        }

        return dateA.getTime() - dateB.getTime()
      },
      sortUndefined: -1,
      enableGlobalFilter: false,
      enableColumnFilter: false
    },
    {
      id: 'actions',
      cell: ({ row }) => {
        return <DataTableRowActions row={row} />
      },
      enableSorting: false,
      enableHiding: false,
      enableResizing: false,
      size: 30
    }
  ]
