import { getRoleId } from '@epilot/permissions/utils'
import { ChevronDown, ChevronUp } from 'lucide-react'
import { useState } from 'react'

import JSONEditor from './json-editor'
import { Button } from './ui/button'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger
} from './ui/collapsible'

import { PricingTierComponents } from '@/api'
import { getGrantsForPricingTier } from '@/utils'

type PricingTier = PricingTierComponents.Schemas.PricingTier

interface Props {
  pricingTier: PricingTier
  defaultOpen?: boolean
}
export const PricingTierRootRolePreview = (props: Props) => {
  const [open, setOpen] = useState(props.defaultOpen ?? true)

  return (
    <Collapsible
      className="px-4 py-2 space-y-2 border border-border rounded-md"
      onOpenChange={(e) => setOpen(e)}
      open={open}
    >
      <CollapsibleTrigger asChild>
        <div className="flex items-center justify-between cursor-pointer">
          <h4 className="text-sm font-semibold text-primary">
            Root Role Template
          </h4>
          <Button className="p-0 w-9" size="sm" type="button" variant="ghost">
            {open ? (
              <ChevronUp className="w-4 h-4" />
            ) : (
              <ChevronDown className="w-4 h-4" />
            )}
            <span className="sr-only">Toggle</span>
          </Button>
        </div>
      </CollapsibleTrigger>
      <CollapsibleContent className="space-y-2">
        <div className="rounded-md mx-2 p-2 overflow-y-auto relative">
          <JSONEditor
            originalData={getRootRolePreview(props.pricingTier) || {}}
          />
        </div>
      </CollapsibleContent>
    </Collapsible>
  )
}

const getRootRolePreview = (pricingTier: PricingTier) => {
  const rootRole = {
    id: getRoleId({ orgId: '0', slug: 'root' }),
    name: 'Organization Permissions',
    slug: 'root',
    organization_id: '0',
    type: 'org_role',
    pricing_tier: pricingTier?.name,
    grants: getGrantsForPricingTier(pricingTier)
  }

  return rootRole
}
