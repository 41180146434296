import classNames from "classnames";
import { useState } from "react";

import { Button } from "./ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "./ui/dialog";
import { Label } from "./ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { Textarea } from "./ui/textarea";

import { getUserClient } from "@/api";
import { config } from "@/configs/env.config";
import { toast } from "./ui/use-toast";

const consentItems = [
  {
    title: "Accountability",
    description:
      "All actions performed while using this feature are recorded and attributed to your admin account. Ensure that you adhere to our ethical guidelines and company policies during its use.",
  },
  {
    title: "Confidentiality",
    description:
      "You are gaining access to sensitive customer data. It is imperative to maintain confidentiality and not misuse any information encountered.",
  },
  {
    title: "Time-Limited Access",
    description:
      "Be aware of the 30 minute maximum session length and complete your tasks efficiently and responsibly. Remember to log out of the session once finished!",
  },
  {
    title: "Consent & Compliance",
    description:
      "Ensure that your use of this feature is in compliance with legal standards and, where applicable, customer consent has been obtained.",
  },
  {
    title: "Purpose of Use",
    description:
      "Utilize this feature strictly for legitimate administrative purposes, such as troubleshooting, support, or audit tasks.",
  },
];

export enum LOGIN_AS_PURPOSES {
  TROUBLESHOOTING = "TROUBLESHOOTING",
  CUSTOMER_SUPPORT = "CUSTOMER_SUPPORT",
  INCIDENT_RESPONSE = "INCIDENT_RESPONSE",
  PRODUCT_RESEARCH = "PRODUCT_RESEARCH",
  DATA_VERIFICATION = "DATA_VERIFICATION",
  PERFORMANCE_MONITORING = "PERFORMANCE_MONITORING",
}

const loginAsPurposeItems = [
  {
    title: "Troubleshooting a Technical Issue",
    description:
      "To diagnose and resolve technical problems that a customer is experiencing with the application.",
    value: LOGIN_AS_PURPOSES.TROUBLESHOOTING,
  },
  {
    title: "Customer Support",
    description:
      "Assisting customers with configurations, setups, or other user-specific issues that require direct interaction with their account.",
    value: LOGIN_AS_PURPOSES.CUSTOMER_SUPPORT,
  },
  {
    title: "Incident Response",
    description:
      "To respond to and manage incidents such as data corruption, data loss, or service outages",
    value: LOGIN_AS_PURPOSES.INCIDENT_RESPONSE,
  },
  {
    title: "Product Research",
    description:
      "To understand how users interact and configure epilot and identify potential areas for improvement in terms of usability and functionality.",
    value: LOGIN_AS_PURPOSES.PRODUCT_RESEARCH,
  },
  {
    title: "Data Verification",
    description:
      "To verify the accuracy of user data or configuration such as part of a data migration or recovery effort.",
    value: LOGIN_AS_PURPOSES.DATA_VERIFICATION,
  },
  {
    title: "Performance Monitoring",
    description:
      "To assess the performance of the application from the user's perspective, identifying issues using real data.",
    value: LOGIN_AS_PURPOSES.PERFORMANCE_MONITORING,
  },
];

const userClient = getUserClient();

interface LoginAsUserDialogProps {
  user: {
    email: string;
    orgId: string;
  }
  open: boolean;
  onOpenChange: (open: boolean) => void;
  redirectUrl?: URL;
}

export function LoginAsUserDialog({
  user,
  onOpenChange,
  open,
  redirectUrl,
}: LoginAsUserDialogProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPurpose, setSelectedPurpose] = useState<
    LOGIN_AS_PURPOSES | ""
  >("");
  const [purposeDescription, setPurposeDescription] = useState("");

  const handleLoginAsUser = async () => {
    setIsLoading(true);
    const res = await userClient.loginTo360PortalAsUser(null, {
      email: user.email,
      org_id: user.orgId,
      purpose: {
        name: selectedPurpose,
        description: purposeDescription,
      },
    });

    if (!res.data.login_as_token) {
      setIsLoading(false);
      toast({
        title: "Error",
        description: "Error getting login token. Please try again later.",
      });
      return;
    }

    const url = new URL(config["360_PORTAL_URL"]);
    url.pathname = "/admin-login";
    url.searchParams.append("token", res.data.login_as_token);

    redirectUrl &&
      url.searchParams.append(
        "login_redirect_url",
        encodeURIComponent(redirectUrl.pathname) +
          encodeURIComponent(redirectUrl.search)
      );

    window.open(url, "_blank", "noopener,noreferrer");
    setIsLoading(false);
    onOpenChange(false);
  };

  return (
    <Dialog onOpenChange={onOpenChange} open={open}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="text-primary">
            You're about to activate the Login as feature
          </DialogTitle>
          <DialogDescription>
            This powerful tool temporarily elevates your privileges, enabling
            you to log into our application as a selected customer user. Before
            proceeding, please note the following critical points:
          </DialogDescription>
        </DialogHeader>

        <div className="grid gap-2">
          {consentItems.map((item, i) => (
            <div
              key={i}
              className="flex items-center justify-between space-x-2"
            >
              <Label className="flex flex-col space-y-1" htmlFor={item.title}>
                <span className="font-bold">{item.title}</span>
                <span className="font-normal leading-snug text-muted-foreground">
                  {item.description}
                </span>
              </Label>
            </div>
          ))}
        </div>
        <div className="grid w-full gap-1.5">
          <Label>Purpose</Label>
          <Select
            onValueChange={(e: LOGIN_AS_PURPOSES | "") => setSelectedPurpose(e)}
            value={selectedPurpose}
          >
            <SelectTrigger
              className={classNames({
                "text-primary": selectedPurpose,
                "text-placeholder": !selectedPurpose,
              })}
            >
              <SelectValue placeholder="Select the purpose of login" />
            </SelectTrigger>
            <SelectContent className="w-[462px]">
              {loginAsPurposeItems.map((item) => (
                <SelectItem key={item.value} value={item.value}>
                  <span className="font-bold">{item.title}</span>
                  <br />
                  <span className="font-normal leading-snug text-muted-foreground">
                    {item.description}
                  </span>
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="grid w-full gap-1.5">
          <Label htmlFor="purpose_description">Description of Purpose</Label>
          <Textarea
            id="purpose_description"
            onChange={(e) => setPurposeDescription(e.target.value)}
            placeholder="Incident #144 Cleanup: Fixing corrupted entities for customer."
            value={purposeDescription}
          />
        </div>
        <DialogFooter className="items-center justify-between flex w-full">
          <Button
            disabled={!selectedPurpose}
            loading={isLoading}
            onClick={handleLoginAsUser}
            type="submit"
            variant={"destructive"}
          >
            Confirm
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
