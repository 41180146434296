import { useCallback, useEffect, useState } from 'react'
import { useEffectOnce } from 'react-use'

export const useCollapsibleSidebar = () => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false)
  const [sidebarHidden, setSidebarHidden] = useState(false)
  const [manualCollapse, setManualCollapse] = useState(false)

  const handleResize = useCallback(() => {
    const isMobile = window.innerWidth < 1024
    const isTablet = window.innerWidth <= 768

    if (!manualCollapse) {
      setSidebarCollapsed(isMobile)
    }
    setSidebarHidden(isMobile ? isTablet : false)
  }, [manualCollapse])

  const toggleSidebarCollapse = (value: boolean) => {
    setManualCollapse(value)
    setSidebarCollapsed(value)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [sidebarCollapsed, handleResize])

  useEffectOnce(() => {
    handleResize()
  })

  return {
    sidebarCollapsed,
    sidebarHidden,
    manualCollapse,
    toggleSidebarCollapse
  }
}
