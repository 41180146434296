import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions
} from '@tanstack/react-query'

import {
  getOrganizationClient,
  OrganizationComponents
} from '@/api/organization-client'
import { ErrorResponse, IOrganization } from '@/models'

export enum ORGANIZATION_QUERY_IDS {
  PREFIX = 'ORGANIZATIONS',
  GET_ORGANIZATION = 'ORGANIZATIONS_GET_ORGANIZATION',
  GET_ORGANIZATIONS = 'ORGANIZATIONS_GET_ORGANIZATIONS',
  GET_DATA_POINTS = 'ORGANIZATIONS_GET_DATA_POINTS',
  GET_ORGANIZATION_TAGS = 'ORGANIZATIONS_GET_ORGANIZATION_TAGS',
  GET_ORGANIZATION_SETTINGS = 'ORGANIZATIONS_GET_ORGANIZATION_SETTINGS',
  GET_SETTINGS_BY_KEY = 'ORGANIZATIONS_GET_SETTINGS_BY_KEY',
  GET_ORGANIZATIONS_TO_CLEANUP = 'ORGANIZATIONS_GET_ORGANIZATIONS_TO_CLEANUP'
}

const client = getOrganizationClient()

export const useQueryGetOrganization: (
  { orgId }: { orgId: string },
  options?: Partial<UseQueryOptions<IOrganization>>
) => ReturnType<typeof useQuery<IOrganization>> = ({ orgId }, options) => {
  return useQuery<IOrganization>({
    queryKey: [ORGANIZATION_QUERY_IDS.GET_ORGANIZATION, orgId],
    queryFn: () =>
      client
        .getOrganization({
          org_id: orgId
        })
        .then((res) => res.data as IOrganization),
    refetchOnWindowFocus: false,
    ...options
  })
}

export const useQueryGetOrganizations: (
  opts?: UseQueryOptions<IOrganization[]>
) => ReturnType<typeof useQuery<IOrganization[]>> = (opts) => {
  return useQuery<IOrganization[]>({
    queryKey: [ORGANIZATION_QUERY_IDS.GET_ORGANIZATIONS],
    queryFn: () =>
      client
        .getOrganizations({ limit: '1500' })
        .then(
          (res) =>
            (res.data.organizations?.filter((org) => org && org.id) ??
              []) as IOrganization[]
        ),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...opts
  })
}

export const useQueryGetOrganizationsToCleanup: (
  opts?: UseQueryOptions<OrganizationComponents.Schemas.OrganizationToCleanup[]>
) => ReturnType<
  typeof useQuery<OrganizationComponents.Schemas.OrganizationToCleanup[]>
> = (opts) => {
  return useQuery<OrganizationComponents.Schemas.OrganizationToCleanup[]>({
    queryKey: [ORGANIZATION_QUERY_IDS.GET_ORGANIZATIONS_TO_CLEANUP],
    queryFn: () => client.getOrganizationsToCleanup().then((res) => res.data),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...opts
  })
}

export const useQueryGetOrganizationDataPoints: (
  opts?: UseQueryOptions<Awaited<ReturnType<typeof client.getDataPoints>>>
) => ReturnType<
  typeof useQuery<Awaited<ReturnType<typeof client.getDataPoints>>>
> = (opts) => {
  return useQuery<Awaited<ReturnType<typeof client.getDataPoints>>>({
    queryKey: [ORGANIZATION_QUERY_IDS.GET_DATA_POINTS],
    queryFn: () => client.getDataPoints(),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
    ...opts
  })
}

export const useQueryGetOrganizationTags: (
  opts?: UseQueryOptions<Awaited<ReturnType<typeof client.getOrganizationTags>>>
) => ReturnType<
  typeof useQuery<Awaited<ReturnType<typeof client.getOrganizationTags>>>
> = (opts) => {
  return useQuery<Awaited<ReturnType<typeof client.getOrganizationTags>>>({
    queryKey: [ORGANIZATION_QUERY_IDS.GET_ORGANIZATION_TAGS],
    queryFn: () => client.getOrganizationTags(),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
    ...opts
  })
}

export const useQueryGetOrganizationSettings: (
  orgId: string,
  opts?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof client.getSettings>>>
  >
) => ReturnType<
  typeof useQuery<Awaited<ReturnType<typeof client.getSettings>>>
> = (orgId, opts) => {
  return useQuery<Awaited<ReturnType<typeof client.getSettings>>>({
    queryKey: [ORGANIZATION_QUERY_IDS.GET_ORGANIZATION_SETTINGS, orgId],
    queryFn: () =>
      client.getSettings(
        {
          org_id: orgId
        },
        null,
        { headers: { 'x-ivy-org-id': orgId } }
      ),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
    ...opts
  })
}

export const useQueryGetSettingsByKey: (
  setting_key: string,
  opts?: UseQueryOptions<Awaited<ReturnType<typeof client.getSettingsByKey>>>
) => ReturnType<
  typeof useQuery<Awaited<ReturnType<typeof client.getSettingsByKey>>>
> = (setting_key, opts) => {
  return useQuery<Awaited<ReturnType<typeof client.getSettingsByKey>>>({
    queryKey: [ORGANIZATION_QUERY_IDS.GET_SETTINGS_BY_KEY, setting_key],
    queryFn: () =>
      client.getSettingsByKey({
        key: setting_key
      }),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
    ...opts
  })
}

export const useMutationUpdateOrganization: () => ReturnType<
  typeof useMutation<
    Awaited<ReturnType<typeof client.updateOrganization>>,
    ErrorResponse,
    Partial<IOrganization>,
    unknown
  >
> = () => {
  return useMutation<
    Awaited<ReturnType<typeof client.updateOrganization>>,
    ErrorResponse,
    Partial<IOrganization>,
    unknown
  >({
    mutationFn: (organization: Partial<IOrganization>) => {
      return client.updateOrganization(organization.id, organization)
    }
  })
}

export const useMutationUpdateOrganizationFeatures: (
  orgId: string
) => ReturnType<
  typeof useMutation<
    Awaited<ReturnType<typeof client.putSettingsValue>>,
    ErrorResponse,
    {
      key: string
      value: { [key: string]: boolean }
    },
    unknown
  >
> = (orgId) => {
  const queryClient = useQueryClient()

  return useMutation<
    Awaited<ReturnType<typeof client.putSettingsValue>>,
    ErrorResponse,
    {
      key: string
      value: { [key: string]: boolean }
    },
    unknown
  >({
    mutationFn: (setting: {
      key: string
      value: { [key: string]: boolean }
    }) => {
      return client.putSettingsValue(
        { org_id: orgId, key: setting.key },
        setting.value,
        { headers: { 'x-ivy-org-id': orgId } }
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ORGANIZATION_QUERY_IDS.GET_ORGANIZATION_SETTINGS, orgId]
      })
    }
  })
}

export const useMutationCreateOrganization: () => ReturnType<
  typeof useMutation<
    Awaited<ReturnType<typeof client.createOrganization>>,
    { response: { data: { error: string } } },
    OrganizationComponents.Schemas.CreateOrganizationRequest,
    unknown
  >
> = () => {
  const queryClient = useQueryClient()

  return useMutation<
    Awaited<ReturnType<typeof client.createOrganization>>,
    { response: { data: { error: string } } },
    OrganizationComponents.Schemas.CreateOrganizationRequest,
    unknown
  >({
    mutationFn: (
      createOrganizationRequest: OrganizationComponents.Schemas.CreateOrganizationRequest
    ) => {
      return client.createOrganization(null, createOrganizationRequest)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ORGANIZATION_QUERY_IDS.GET_ORGANIZATIONS]
      })
    }
  })
}
