import { getClient } from '@epilot/pricing-tier-client'
import { useMemo } from 'react'

import { config } from '../configs/api.config'

import { configureClient } from './api-utils'

let client: ReturnType<typeof getClient>

export const getPricingTierClient = () => {
  if (!client) {
    client = getClient()

    return configureClient(client, { baseURL: config.PRICING_TIER })
  }

  return client
}

export const usePricingTierClient = () =>
  useMemo(() => getPricingTierClient(), [])

export type { Components as PricingTierComponents } from '@epilot/pricing-tier-client'
