import { DataTable } from '@/components/ui/data-table'
import { usePartnerTable } from '@/hooks/usePartnerTable'

const PartnerList = () => {
  const { columns, data, isLoading } = usePartnerTable()

  return (
    <>
      <DataTable
        columns={columns}
        data={data}
        isLoading={isLoading}
        tableConfigName="partner"
      />
    </>
  )
}

export default PartnerList
