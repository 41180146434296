import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query'

import {
  ErrorResponse,
  IMarketplaceBlueprint,
  IMarketplaceBlueprintResource,
  IMarketplaceBlueprintResourceType
} from '../models'

import {
  getMarketplaceCMSClient,
  MarketplaceCMSComponents,
  MarketplaceCMSPaths
} from '@/api/marketplace-cms-client'

const marketplaceCMSClient = getMarketplaceCMSClient()

const MARKETPLACE_CMS_QUERY_IDS = {
  GET_MARKETPLACE_BLUEPRINTS: 'GET_MARKETPLACE_BLUEPRINTS',
  GET_MARKETPLACE_BLUEPRINT: 'GET_MARKETPLACE_BLUEPRINT',
  GET_MARKETPLACE_BLUEPRINT_RESOURCES: 'GET_MARKETPLACE_BLUEPRINT_RESOURCES',
  GET_MARKETPLACE_BLUEPRINT_RESOURCE: 'GET_MARKETPLACE_BLUEPRINT_RESOURCE',
  GET_MARKETPLACE_BLUEPRINT_RESOURCE_TYPES:
    'GET_MARKETPLACE_BLUEPRINT_RESOURCE_TYPES',
  GET_MARKETPLACE_BLUEPRINT_RESOURCE_TYPE:
    'GET_MARKETPLACE_BLUEPRINT_RESOURCE_TYPE'
}

export const useQueryGetMarketplaceBlueprints: (
  opts?: Omit<
    UseQueryOptions<MarketplaceCMSPaths.GetMarketplaceBlueprints.Responses.$200>,
    'queryKey'
  >
) => ReturnType<
  typeof useQuery<MarketplaceCMSPaths.GetMarketplaceBlueprints.Responses.$200>
> = (opts) => {
  return useQuery<MarketplaceCMSPaths.GetMarketplaceBlueprints.Responses.$200>({
    queryKey: [MARKETPLACE_CMS_QUERY_IDS.GET_MARKETPLACE_BLUEPRINTS],
    queryFn: () =>
      marketplaceCMSClient
        .getMarketplaceBlueprints({ limit: '1000' })
        .then((r) => r.data),
    refetchOnWindowFocus: true,
    ...opts
  })
}

export const useQueryGetMarketplaceBlueprint: (
  blueprintId: string,
  opts?: Omit<
    UseQueryOptions<MarketplaceCMSPaths.GetMarketplaceBlueprint.Responses.$200>,
    'queryKey'
  >
) => ReturnType<
  typeof useQuery<MarketplaceCMSPaths.GetMarketplaceBlueprint.Responses.$200>
> = (blueprintId, opts) => {
  return useQuery<MarketplaceCMSPaths.GetMarketplaceBlueprint.Responses.$200>({
    queryKey: [
      MARKETPLACE_CMS_QUERY_IDS.GET_MARKETPLACE_BLUEPRINT,
      blueprintId
    ],
    queryFn: () =>
      marketplaceCMSClient
        .getMarketplaceBlueprint({ id: blueprintId })
        .then((r) => r.data),
    refetchOnWindowFocus: true,
    ...opts
  })
}

export const useMutationCreateMarketplaceBlueprint: () => ReturnType<
  typeof useMutation<
    MarketplaceCMSComponents.Schemas.MarketplaceBlueprint,
    ErrorResponse,
    MarketplaceCMSComponents.Schemas.CreateMarketplaceBlueprintBody,
    unknown
  >
> = () => {
  return useMutation<
    MarketplaceCMSComponents.Schemas.MarketplaceBlueprint,
    ErrorResponse,
    MarketplaceCMSComponents.Schemas.CreateMarketplaceBlueprintBody,
    unknown
  >({
    mutationFn: async (blueprint) => {
      const r = await marketplaceCMSClient.createMarketplaceBlueprint(
        null,
        blueprint
      )

      return r.data
    }
  })
}

export const useMutationUpdateMarketplaceBlueprint: () => ReturnType<
  typeof useMutation<
    MarketplaceCMSPaths.UpdateMarketplaceBlueprint.RequestBody,
    ErrorResponse,
    IMarketplaceBlueprint,
    unknown
  >
> = () => {
  return useMutation<
    MarketplaceCMSPaths.UpdateMarketplaceBlueprint.RequestBody,
    ErrorResponse,
    IMarketplaceBlueprint,
    unknown
  >({
    mutationFn: async (blueprint: IMarketplaceBlueprint) => {
      const r = await marketplaceCMSClient.updateMarketplaceBlueprint(
        { id: blueprint.id as string },
        blueprint
      )

      return r.data
    }
  })
}

export const useMutationDeleteMarketplaceBlueprint: () => ReturnType<
  typeof useMutation<void, ErrorResponse, string, unknown>
> = () => {
  return useMutation<void, ErrorResponse, string, unknown>({
    mutationFn: async (blueprintId: string) => {
      await marketplaceCMSClient.deleteMarketplaceBlueprint(blueprintId)
    }
  })
}

export const useQueryGetMarketplaceBlueprintResources: (
  opts?: Omit<
    UseQueryOptions<MarketplaceCMSPaths.GetMarketplaceBlueprintResources.Responses.$200>,
    'queryKey'
  >
) => ReturnType<
  typeof useQuery<MarketplaceCMSPaths.GetMarketplaceBlueprintResources.Responses.$200>
> = (opts) => {
  return useQuery<MarketplaceCMSPaths.GetMarketplaceBlueprintResources.Responses.$200>(
    {
      queryKey: [MARKETPLACE_CMS_QUERY_IDS.GET_MARKETPLACE_BLUEPRINT_RESOURCES],
      queryFn: () =>
        marketplaceCMSClient
          .getMarketplaceBlueprintResources({ limit: '1000' })
          .then((r) => r.data),
      refetchOnWindowFocus: true,
      ...opts
    }
  )
}

export const useQueryGetMarketplaceBlueprintResource: (
  resourceId: string,
  opts?: Omit<
    UseQueryOptions<MarketplaceCMSPaths.GetMarketplaceBlueprintResource.Responses.$200>,
    'queryKey'
  >
) => ReturnType<
  typeof useQuery<MarketplaceCMSPaths.GetMarketplaceBlueprintResource.Responses.$200>
> = (resourceId, opts) => {
  return useQuery<MarketplaceCMSPaths.GetMarketplaceBlueprintResource.Responses.$200>(
    {
      queryKey: [
        MARKETPLACE_CMS_QUERY_IDS.GET_MARKETPLACE_BLUEPRINT_RESOURCE,
        resourceId
      ],
      queryFn: () =>
        marketplaceCMSClient
          .getMarketplaceBlueprintResource({ id: resourceId })
          .then((r) => r.data),
      refetchOnWindowFocus: true,
      ...opts
    }
  )
}

export const useQueryGetMarketplaceBlueprintResourceTypes: (
  opts?: Omit<
    UseQueryOptions<MarketplaceCMSPaths.GetMarketplaceBlueprintResourceTypes.Responses.$200>,
    'queryKey'
  >
) => ReturnType<
  typeof useQuery<MarketplaceCMSPaths.GetMarketplaceBlueprintResourceTypes.Responses.$200>
> = (opts) => {
  return useQuery<MarketplaceCMSPaths.GetMarketplaceBlueprintResourceTypes.Responses.$200>(
    {
      queryKey: [
        MARKETPLACE_CMS_QUERY_IDS.GET_MARKETPLACE_BLUEPRINT_RESOURCE_TYPES
      ],
      queryFn: () =>
        marketplaceCMSClient
          .getMarketplaceBlueprintResourceTypes({ limit: '1000' })
          .then((r) => r.data),
      refetchOnWindowFocus: true,
      ...opts
    }
  )
}

export const useMutationCreateMarketplaceBlueprintResource: () => ReturnType<
  typeof useMutation<
    MarketplaceCMSComponents.Schemas.MarketplaceBlueprintResource,
    ErrorResponse,
    MarketplaceCMSComponents.Schemas.CreateMarketplaceBlueprintResourceBody,
    unknown
  >
> = () => {
  return useMutation<
    MarketplaceCMSComponents.Schemas.MarketplaceBlueprintResource,
    ErrorResponse,
    MarketplaceCMSComponents.Schemas.CreateMarketplaceBlueprintResourceBody,
    unknown
  >({
    mutationFn: async (resource) => {
      const r = await marketplaceCMSClient.createMarketplaceBlueprintResource(
        null,
        resource
      )

      return r.data
    }
  })
}

export const useMutationUpdateMarketplaceBlueprintResource: () => ReturnType<
  typeof useMutation<
    MarketplaceCMSPaths.UpdateMarketplaceBlueprintResource.RequestBody,
    ErrorResponse,
    IMarketplaceBlueprintResource,
    unknown
  >
> = () => {
  return useMutation<
    MarketplaceCMSPaths.UpdateMarketplaceBlueprintResource.RequestBody,
    ErrorResponse,
    IMarketplaceBlueprintResource,
    unknown
  >({
    mutationFn: async (resource: IMarketplaceBlueprintResource) => {
      const r = await marketplaceCMSClient.updateMarketplaceBlueprintResource(
        { id: resource.id as string },
        resource
      )

      return r.data
    }
  })
}

export const useMutationDeleteMarketplaceBlueprintResource: () => ReturnType<
  typeof useMutation<void, ErrorResponse, string, unknown>
> = () => {
  return useMutation<void, ErrorResponse, string, unknown>({
    mutationFn: async (resourceId: string) => {
      await marketplaceCMSClient.deleteMarketplaceBlueprintResource(resourceId)
    }
  })
}

export const useQueryGetMarketplaceBlueprintResourceType: (
  resourceTypeId: string,
  opts?: Omit<
    UseQueryOptions<MarketplaceCMSPaths.GetMarketplaceBlueprintResourceType.Responses.$200>,
    'queryKey'
  >
) => ReturnType<
  typeof useQuery<MarketplaceCMSPaths.GetMarketplaceBlueprintResourceType.Responses.$200>
> = (resourceTypeId, opts) => {
  return useQuery<MarketplaceCMSPaths.GetMarketplaceBlueprintResourceType.Responses.$200>(
    {
      queryKey: [
        MARKETPLACE_CMS_QUERY_IDS.GET_MARKETPLACE_BLUEPRINT_RESOURCE_TYPE,
        resourceTypeId
      ],
      queryFn: () =>
        marketplaceCMSClient
          .getMarketplaceBlueprintResourceType({ id: resourceTypeId })
          .then((r) => r.data),
      refetchOnWindowFocus: true,
      ...opts
    }
  )
}

export const useMutationCreateMarketplaceBlueprintResourceType: () => ReturnType<
  typeof useMutation<
    MarketplaceCMSComponents.Schemas.MarketplaceBlueprintResourceType,
    ErrorResponse,
    MarketplaceCMSComponents.Schemas.CreateMarketplaceBlueprintResourceTypeBody,
    unknown
  >
> = () => {
  return useMutation<
    MarketplaceCMSComponents.Schemas.MarketplaceBlueprintResourceType,
    ErrorResponse,
    MarketplaceCMSComponents.Schemas.CreateMarketplaceBlueprintResourceTypeBody,
    unknown
  >({
    mutationFn: async (resourceType) => {
      const r =
        await marketplaceCMSClient.createMarketplaceBlueprintResourceType(
          null,
          resourceType
        )

      return r.data
    }
  })
}

export const useMutationUpdateMarketplaceBlueprintResourceType: () => ReturnType<
  typeof useMutation<
    MarketplaceCMSPaths.UpdateMarketplaceBlueprintResourceType.RequestBody,
    ErrorResponse,
    IMarketplaceBlueprintResourceType,
    unknown
  >
> = () => {
  return useMutation<
    MarketplaceCMSPaths.UpdateMarketplaceBlueprintResourceType.RequestBody,
    ErrorResponse,
    IMarketplaceBlueprintResourceType,
    unknown
  >({
    mutationFn: async (resourceType: IMarketplaceBlueprintResourceType) => {
      const r =
        await marketplaceCMSClient.updateMarketplaceBlueprintResourceType(
          resourceType.id as string,
          resourceType
        )

      return r.data
    }
  })
}

export const useMutationDeleteMarketplaceBlueprintResourceType: () => ReturnType<
  typeof useMutation<void, ErrorResponse, string, unknown>
> = () => {
  return useMutation<void, ErrorResponse, string, unknown>({
    mutationFn: async (resourceTypeId: string) => {
      await marketplaceCMSClient.deleteMarketplaceBlueprintResourceType(
        resourceTypeId
      )
    }
  })
}
