import { getClient } from '@epilot/internal-user-client'
import { useMemo } from 'react'

import { config } from '../configs/api.config'

import { configureClient } from './api-utils'

let client: ReturnType<typeof getClient>

export const getUserClient = () => {
  if (!client) {
    client = getClient()

    configureClient(client, {
      baseURL: config.USER
    })
  }

  return client
}

export const useUserClient = () => useMemo(() => getUserClient(), [])

export type {
  Components as UserComponents,
  Paths as UserPaths
} from '@epilot/internal-user-client'
