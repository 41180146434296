import { Command as CommandPrimitive } from 'cmdk'
import { X } from 'lucide-react'
import * as React from 'react'

import { Badge } from '@/components/ui/badge'
import { Command, CommandGroup, CommandItem } from '@/components/ui/command'
import { Icons } from '@/components/ui/icons'

type SelectItem = string | Record<string, any>

interface MultiSelectProps {
  items: SelectItem[]
  selectedItems: SelectItem[]
  onNewItem?: (label: string) => SelectItem
  onSelect: (item: SelectItem) => void
  onUnselect: (item: SelectItem) => void
  valueKey?: string
  labelKey?: string
  placeholder?: string
  className?: string
  isLoading?: boolean
  allowNewValues?: boolean
  disabled?: boolean
}

export function MultiSelectAutoComplete({
  items,
  selectedItems,
  onNewItem,
  onSelect,
  onUnselect,
  valueKey = 'value',
  labelKey = 'label',
  placeholder = 'Select...',
  className = '',
  disabled = false,
  isLoading = false,
  allowNewValues = false
}: MultiSelectProps) {
  const inputRef = React.useRef<HTMLInputElement>(null)
  const [open, setOpen] = React.useState(false)
  const [inputValue, setInputValue] = React.useState('')

  const getItemLabel = (item: SelectItem) => {
    if (typeof item === 'string') {
      return item
    }

    return item?.[labelKey]
  }

  const getItemValue = (item: SelectItem) => {
    if (typeof item === 'string') {
      return item
    }

    return item?.[valueKey]
  }

  const handleUnselect = (item: SelectItem) => {
    onUnselect(item)
  }

  const selectables = React.useMemo(
    () =>
      items.filter(
        (item) =>
          !selectedItems.find(
            (selected) => getItemValue(selected) === getItemValue(item)
          )
      ),
    [items, selectedItems]
  )

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const input = inputRef.current

    if (input) {
      if (e.key === 'Delete' || e.key === 'Backspace') {
        if (input.value === '') {
          // Remove the last selected item
          handleUnselect(selectedItems.at(-1)!)
        }
      }
      // This is not a default behaviour of the <input /> field
      if (e.key === 'Escape') {
        input.blur()
      }
    }
  }

  const isAddItemEnabled = React.useMemo(() => {
    return (
      !selectedItems.some(
        (item) =>
          getItemLabel(item)?.toLowerCase() ===
          inputValue?.trim()?.toLowerCase()
      ) && !selectables.some((item) => getItemLabel(item) === inputValue.trim())
    )
  }, [selectedItems, selectables, inputValue])

  return (
    <Command
      className={`overflow-visible bg-transparent ${className}`}
      onKeyDown={handleKeyDown}
    >
      <div className="px-1 py-2 h-full text-sm border border-border rounded-md group ring-offset-background focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2">
        <div className="flex flex-wrap gap-1 items-center">
          {selectedItems.map((item, index) => (
            <Badge key={index} variant="secondary">
              {getItemLabel(item)}
              <button
                disabled={disabled}
                className="ml-1 rounded-full outline-none ring-offset-background focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                onClick={() => handleUnselect(item)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleUnselect(item)
                  }
                }}
                onMouseDown={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                }}
              >
                <X className="w-3 h-3 text-muted-foreground hover:text-foreground" />
              </button>
            </Badge>
          ))}

          <CommandPrimitive.Input
            disabled={disabled}
            className="flex-1 ml-2 bg-transparent outline-none placeholder:text-placeholder disabled:cursor-not-allowed disabled:opacity-50"
            onBlur={() => setOpen(false)}
            onFocus={() => setOpen(true)}
            onValueChange={setInputValue}
            placeholder={selectedItems.length === 0 ? placeholder : undefined}
            ref={inputRef}
            value={inputValue}
          />
          {isLoading && (
            <CommandPrimitive.Loading>
              <Icons.spinner className="w-4 h-4 m-0" />
            </CommandPrimitive.Loading>
          )}
        </div>
      </div>
      {open && selectables.length > 0 ? (
        <div className="relative">
          <div className="absolute top-2 z-10 w-full border border-border border-primary rounded-md shadow-md outline-none bg-popover text-popover-foreground animate-in">
            <CommandGroup className="max-h-[350px] overflow-auto">
              {selectables.map((item, index) => (
                <CommandItem
                  className="cursor-pointer"
                  key={index}
                  onMouseDown={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                  }}
                  onSelect={() => {
                    onSelect(item)
                    setInputValue('')
                  }}
                >
                  {getItemLabel(item)}
                </CommandItem>
              ))}
              {allowNewValues && inputValue.trim() && (
                <CommandItem
                  className="cursor-pointer"
                  disabled={!isAddItemEnabled}
                  onMouseDown={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                  }}
                  onSelect={() => {
                    if (!onNewItem) return
                    const newItem = onNewItem(inputValue.trim())

                    onSelect(newItem)
                    setInputValue('')
                  }}
                >
                  Add "{inputValue.trim()}"
                </CommandItem>
              )}
            </CommandGroup>
          </div>
        </div>
      ) : null}
    </Command>
  )
}
