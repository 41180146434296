import { createContext, useContext } from 'react'

import { useQueryGetOrganizationTags, useQueryGetUserTags } from '@/services'

export interface TagContext {
  organizationTags: string[]
  userTags: string[]
  isOrganizationTagsLoading: boolean
  isUserTagsLoading: boolean
}

const TagsContext = createContext<Partial<TagContext>>({})

export const TagsContextProvider = (props: { children: JSX.Element }) => {
  const orgTagsQueryResp = useQueryGetOrganizationTags()
  const userTagsQueryResp = useQueryGetUserTags()

  return (
    <TagsContext.Provider
      value={{
        organizationTags: orgTagsQueryResp?.data?.data ?? [],
        userTags: userTagsQueryResp?.data?.data ?? [],
        isOrganizationTagsLoading: orgTagsQueryResp.isFetching,
        isUserTagsLoading: userTagsQueryResp.isFetching
      }}
    >
      {props.children}
    </TagsContext.Provider>
  )
}

export const useTags = () => useContext(TagsContext)
