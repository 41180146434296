import { ChevronDown, ChevronUp } from 'lucide-react'
import { useState } from 'react'

import { Button } from './ui/button'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger
} from './ui/collapsible'
import { Icons } from './ui/icons'
import { Input } from './ui/input'
import { Label } from './ui/label'

type OrganizationDataPointsProps = {
  loading: boolean
  dataPoint: {
    actual_users: number
    max_users_last_month: number
    maxBillableUsersLastMonth: number
    additionalPaidUsers: number
    actual_customer: number
    max_customer: number
  }
  defaultOpen?: boolean
}

const OrganizationDataPoints = ({
  loading,
  dataPoint,
  defaultOpen = true
}: OrganizationDataPointsProps) => {
  const [open, setOpen] = useState(defaultOpen)

  return (
    <Collapsible
      className="px-4 py-2 space-y-2 border border-border rounded-md"
      onOpenChange={(e) => !loading && setOpen(e)}
      open={open}
    >
      <CollapsibleTrigger asChild>
        <div className="flex items-center justify-between cursor-pointer">
          <h4 className="text-sm font-semibold text-primary">Data Points</h4>
          <Button disabled={loading} className="p-0 w-9" size="sm" type="button" variant="ghost">
            {loading ? (
              <Icons.spinner className="w-4 h-4 mr-0" />
            ) : open ? (
              <ChevronUp className="w-4 h-4" />
            ) : (
              <ChevronDown className="w-4 h-4" />
            )}
            <span className="sr-only">Toggle</span>
          </Button>
        </div>
      </CollapsibleTrigger>
      <CollapsibleContent className="space-y-2">
        <div className="max-h-[90vh] overflow-y-auto">
          <div className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="actual_users">Actual Users</Label>
              <Input
                disabled
                id="actual_users"
                value={dataPoint?.actual_users}
              />
            </div>
            <div className="space-y-2">
              <Label>Max Users Last Month</Label>
              <Input disabled value={dataPoint?.max_users_last_month} />
            </div>
            <div className="space-y-2">
              <Label>Max Billable Users Last Month</Label>
              <Input disabled value={dataPoint.maxBillableUsersLastMonth} />
            </div>
            <div className="space-y-2">
              <Label>Additional Paid Users</Label>
              <Input disabled value={dataPoint.additionalPaidUsers} />
            </div>
            <div className="space-y-2">
              <Label>Actual Customer Data Point</Label>
              <Input disabled value={dataPoint?.actual_customer} />
            </div>
            <div className="space-y-2">
              <Label>Max Customer Data Point Last Month</Label>
              <Input disabled value={dataPoint?.max_customer} />
            </div>
          </div>
        </div>
      </CollapsibleContent>
    </Collapsible>
  )
}

export default OrganizationDataPoints
