import {
  BarChartBig,
  Blocks,
  Building2,
  DraftingCompass,
  MessagesSquare,
  Users2,
  Wallet
} from 'lucide-react'
import { Navigate, Outlet } from 'react-router-dom'

import { Layout } from './Layout'
import NotFound from './NotFound'

import AuthenticationPage from '@/modules/authentication/page'
import GenAIFeedbackList from '@/modules/genai-feedbacks/page'
import InternalBI from '@/modules/internal-bi/page'
import { BlueprintResourceDetail } from '@/modules/marketplace/blueprint-resources/details'
import BlueprintResourcesMarketplaceList from '@/modules/marketplace/blueprint-resources/page'
import { BlueprintDetail } from '@/modules/marketplace/blueprints/details'
import BlueprintsMarketplaceList from '@/modules/marketplace/blueprints/page'
import { OrganizationForm } from '@/modules/organization/create-organization'
import OrganizationDetail from '@/modules/organization/details'
import OrganizationList from '@/modules/organization/page'
import { PricingTierForm } from '@/modules/pricing-tier/create-pricing-tier'
import PricingTierDetail from '@/modules/pricing-tier/details'
import PricingTierList from '@/modules/pricing-tier/page'
import { UserDetail } from '@/modules/user/details'
import UserList from '@/modules/user/page'
import { Permissions } from '@/utils/permission-manager'

export type Route = {
  path: string
  element: JSX.Element
  icon?: JSX.Element
  label?: string
  showInMenu?: boolean
  children?: Route[]
  permission?: Permissions
}

export enum RoutePaths {
  MARKETPLACE = '/marketplace',
  MARKETPLACE_BLUEPRINTS = '/marketplace/blueprints',
  MARKETPLACE_BLUEPRINTS_DETAIL = '/marketplace/blueprints/:blueprintId',
  MARKETPLACE_BLUEPRINTS_CREATE = '/marketplace/blueprints/create',
  MARKETPLACE_BLUEPRINTS_RESOURCES = '/marketplace/resources',
  MARKETPLACE_BLUEPRINTS_RESOURCES_DETAIL = '/marketplace/resources/:resourceId',
  MARKETPLACE_BLUEPRINTS_RESOURCES_CREATE = '/marketplace/resources/create',
  GENAI_FEEDBACKS = '/genai-feedbacks',
  INTERNAL_BI = '/internal-bi',
  ORGANIZATIONS = '/organizations',
  ORGANIZATION_DETAIL = '/organizations/:orgId',
  ORGANIZATION_CREATE = '/organizations/create',
  PRICING_TIERS = '/pricing-tiers',
  PRICING_TIER_DETAIL = '/pricing-tiers/:pricingTierId',
  PRICING_TIER_CREATE = '/pricing-tiers/create',
  USERS = '/users',
  USER_DETAIL = '/users/:userId',
  LOGIN = '/login',
  HOME = '/'
}

export const routes: Route[] = [
  {
    path: RoutePaths.HOME,
    element: <Layout />,
    children: [
      {
        path: '/',
        element: <Navigate to={RoutePaths.ORGANIZATIONS} />
      },
      {
        path: RoutePaths.ORGANIZATIONS,
        element: <OrganizationList />,
        icon: <Building2 className="text-primary" />,
        label: 'Organizations',
        showInMenu: true
      },
      {
        path: RoutePaths.ORGANIZATION_CREATE,
        element: <OrganizationForm />
      },
      {
        path: RoutePaths.ORGANIZATION_DETAIL,
        element: <OrganizationDetail />
      },
      {
        path: RoutePaths.USERS,
        element: <UserList />,
        icon: <Users2 className="text-primary" />,
        label: 'Users',
        showInMenu: true
      },
      {
        path: RoutePaths.GENAI_FEEDBACKS,
        element: <GenAIFeedbackList />,
        icon: <MessagesSquare className="text-primary" />,
        label: 'GenAI Feedbacks',
        showInMenu: true,
        permission: Permissions.VIEW_GENAI_FEEDBACKS
      },
      {
        path: RoutePaths.USER_DETAIL,
        element: <UserDetail />
      },
      {
        path: RoutePaths.PRICING_TIERS,
        element: <PricingTierList />,
        label: 'Pricing Tiers',
        icon: <Wallet className="text-primary" />,
        showInMenu: true
      },
      {
        path: RoutePaths.PRICING_TIER_CREATE,
        element: <PricingTierForm />
      },
      {
        path: RoutePaths.PRICING_TIER_DETAIL,
        element: <PricingTierDetail />
      },
      {
        path: RoutePaths.INTERNAL_BI,
        element: <InternalBI />,
        label: 'Internal BI',
        icon: <BarChartBig className="text-primary" />,
        showInMenu: true
      },
      {
        path: RoutePaths.MARKETPLACE,
        element: <Outlet />,
        label: 'Marketplace',
        icon: <DraftingCompass className="text-primary" />,
        showInMenu: true,
        children: [
          {
            path: '/marketplace',
            element: <Navigate to={RoutePaths.MARKETPLACE_BLUEPRINTS} />
          },
          {
            path: RoutePaths.MARKETPLACE_BLUEPRINTS,
            element: <BlueprintsMarketplaceList />,
            label: 'Blueprints',
            icon: <DraftingCompass className="text-primary" />,
            showInMenu: true
          },
          {
            path: RoutePaths.MARKETPLACE_BLUEPRINTS_CREATE,
            element: <BlueprintDetail />
          },
          {
            path: RoutePaths.MARKETPLACE_BLUEPRINTS_DETAIL,
            element: <BlueprintDetail />
          },
          {
            path: RoutePaths.MARKETPLACE_BLUEPRINTS_RESOURCES,
            element: <BlueprintResourcesMarketplaceList />,
            label: 'Blueprint Resources',
            icon: <Blocks className="text-primary" />,
            showInMenu: true
          },
          {
            path: RoutePaths.MARKETPLACE_BLUEPRINTS_RESOURCES_CREATE,
            element: <BlueprintResourceDetail />
          },
          {
            path: RoutePaths.MARKETPLACE_BLUEPRINTS_RESOURCES_DETAIL,
            element: <BlueprintResourceDetail />
          }
        ]
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  },
  {
    path: RoutePaths.LOGIN,
    element: <AuthenticationPage />
  }
]

export const getMenuItems = () => {
  return (
    routes
      .find((x) => x.path === RoutePaths.HOME)
      ?.children?.filter((x) => x.showInMenu) ?? []
  )
}

export const getAllRoutes = () => {
  const allRoutes: Route[] = []

  routes.forEach((route) => {
    if (route.children) {
      route.children.forEach((child) => {
        allRoutes.push(child)
      })
    } else {
      allRoutes.push(route)
    }
  })

  return allRoutes
}
