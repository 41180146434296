import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions
} from '@tanstack/react-query'

import {
  getPricingTierClient,
  PricingTierComponents
} from '@/api/pricing-tier-client'
import { ErrorResponse } from '@/models'

const pricingTierClient = getPricingTierClient()

const PRICING_TIER_QUERY_IDS = {
  GET_PRICING_TIERS: 'GET_PRICING_TIERS',
  GET_ORGANIZATION_PRICING_TIER: 'GET_ORGANIZATION_PRICING_TIER',
  GET_PRICING_TIER_BY_ID: 'GET_PRICING_TIER_BY_ID',
  GET_PRICING_TIER_BY_ORGANIZATION_ID: 'GET_PRICING_TIER_BY_ORGANIZATION_ID',
  UPDATE_PRICING_TIER_OF_ORGANIZATION: 'UPDATE_PRICING_TIER_OF_ORGANIZATION',
  GET_ASSIGNED_PRICING_TIER_OF_ORGANIZATION:
    'GET_ASSIGNED_PRICING_TIER_OF_ORGANIZATION',
  GET_ASSIGNED_PRICING_TIER_OF_ORGANIZATIONS:
    'GET_ASSIGNED_PRICING_TIER_OF_ORGANIZATIONS',
  GET_ALL_ASSIGNMENTS: 'GET_ALL_ASSIGNMENTS'
}

export const useQueryGetPricingTiers: (
  opts?: UseQueryOptions<PricingTierComponents.Schemas.PricingTier[]>
) => ReturnType<
  typeof useQuery<PricingTierComponents.Schemas.PricingTier[]>
> = (opts) => {
  return useQuery<PricingTierComponents.Schemas.PricingTier[]>({
    queryKey: [PRICING_TIER_QUERY_IDS.GET_PRICING_TIERS],
    queryFn: () =>
      pricingTierClient
        .getPricingTiers({ limit: '1000' })
        .then(
          (res) =>
            res?.data.pricing_tiers?.filter((tier) => tier && tier.id) ?? []
        ),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
    ...opts
  })
}

export const useMutationDeletePricingTier: () => ReturnType<
  typeof useMutation<
    Awaited<ReturnType<typeof pricingTierClient.deletePricingTier>>,
    ErrorResponse,
    string,
    unknown
  >
> = () => {
  const queryClient = useQueryClient()

  return useMutation<
    Awaited<ReturnType<typeof pricingTierClient.deletePricingTier>>,
    ErrorResponse,
    string,
    unknown
  >({
    mutationFn: (pricingTierId: string) =>
      pricingTierClient.deletePricingTier({ pricing_tier_id: pricingTierId }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [PRICING_TIER_QUERY_IDS.GET_PRICING_TIERS]
      })
    }
  })
}

export const useQueryGetPricingTierById: (
  pricingTierId: string,
  opts?: Partial<UseQueryOptions<PricingTierComponents.Schemas.PricingTier>>
) => ReturnType<typeof useQuery<PricingTierComponents.Schemas.PricingTier>> = (
  pricingTierId,
  opts
) => {
  return useQuery<PricingTierComponents.Schemas.PricingTier>({
    queryFn: () =>
      pricingTierClient
        .getPricingTier({ pricing_tier_id: pricingTierId })
        .then((res) => res.data),
    refetchOnWindowFocus: false,
    retry: false,
    ...opts,
    queryKey: [PRICING_TIER_QUERY_IDS.GET_PRICING_TIER_BY_ID, pricingTierId]
  })
}

export const useQueryGetPricingTierByOrganizationId: (
  pricingTierId: string,
  orgId: string,
  opts?: Partial<UseQueryOptions<PricingTierComponents.Schemas.PricingTier>>
) => ReturnType<typeof useQuery<PricingTierComponents.Schemas.PricingTier>> = (
  pricingTierId,
  orgId,
  opts
) => {
  return useQuery<PricingTierComponents.Schemas.PricingTier>({
    queryKey: [
      PRICING_TIER_QUERY_IDS.GET_PRICING_TIER_BY_ORGANIZATION_ID,
      pricingTierId,
      orgId
    ],
    queryFn: () =>
      pricingTierClient
        .getPricingTier({ pricing_tier_id: pricingTierId, org_id: orgId })
        .then((res) => res.data),
    refetchOnWindowFocus: false,
    retry: false,
    ...opts
  })
}

export const useMutationUpdatePricingTier = (
  orgId?: string
): ReturnType<
  typeof useMutation<
    Awaited<ReturnType<typeof pricingTierClient.updatePricingTier>>,
    ErrorResponse,
    PricingTierComponents.Schemas.PricingTier,
    unknown
  >
> => {
  const queryClient = useQueryClient()

  return useMutation<
    Awaited<ReturnType<typeof pricingTierClient.updatePricingTier>>,
    ErrorResponse,
    PricingTierComponents.Schemas.PricingTier,
    unknown
  >({
    mutationFn: (pricingTier: PricingTierComponents.Schemas.PricingTier) => {
      return pricingTierClient.updatePricingTier(
        {
          pricing_tier_id: pricingTier.id!,
          org_id: orgId
        },
        pricingTier
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          PRICING_TIER_QUERY_IDS.GET_PRICING_TIER_BY_ID,
          PRICING_TIER_QUERY_IDS.GET_PRICING_TIER_BY_ORGANIZATION_ID
        ]
      })
    }
  })
}

export const useMutationCreatePricingTier: () => ReturnType<
  typeof useMutation<
    Awaited<ReturnType<typeof pricingTierClient>>,
    ErrorResponse,
    PricingTierComponents.Schemas.PricingTier,
    unknown
  >
> = () => {
  const queryClient = useQueryClient()

  return useMutation<
    Awaited<ReturnType<typeof pricingTierClient>>,
    ErrorResponse,
    PricingTierComponents.Schemas.PricingTier,
    unknown
  >({
    mutationFn: (pricingTier: PricingTierComponents.Schemas.PricingTier) => {
      return pricingTierClient.createPricingTier(null, pricingTier)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [PRICING_TIER_QUERY_IDS.GET_PRICING_TIERS]
      })
    }
  })
}

export const useMutationAssignPricingTier: () => ReturnType<
  typeof useMutation<
    Awaited<ReturnType<typeof pricingTierClient.assignPricingTier>>,
    ErrorResponse,
    PricingTierComponents.Schemas.Assignment,
    unknown
  >
> = () => {
  return useMutation<
    Awaited<ReturnType<typeof pricingTierClient.assignPricingTier>>,
    ErrorResponse,
    PricingTierComponents.Schemas.Assignment,
    unknown
  >({
    mutationFn: (assignment: PricingTierComponents.Schemas.Assignment) => {
      return pricingTierClient.assignPricingTier(
        { org_id: assignment.organization_id! },
        assignment
      )
    }
  })
}

export const useQueryGetAssignedPricingTierForOrganization: (
  orgId: string,
  opts?: UseQueryOptions<
    Awaited<
      ReturnType<typeof pricingTierClient.getAssignedPricingTierForOrganization>
    >
  >
) => ReturnType<
  typeof useQuery<
    Awaited<
      ReturnType<typeof pricingTierClient.getAssignedPricingTierForOrganization>
    >
  >
> = (orgId, opts) => {
  return useQuery<
    Awaited<
      ReturnType<typeof pricingTierClient.getAssignedPricingTierForOrganization>
    >
  >({
    queryKey: [
      PRICING_TIER_QUERY_IDS.GET_ASSIGNED_PRICING_TIER_OF_ORGANIZATION,
      orgId
    ],
    queryFn: () =>
      pricingTierClient.getAssignedPricingTierForOrganization({
        org_id: orgId
      }),
    refetchOnWindowFocus: false,
    retry: false,
    ...opts
  })
}

export const useQueryGetAssignedPricingTiersForOrganizations: (
  organizationIds: string[],
  options?: UseQueryOptions<
    Awaited<
      ReturnType<typeof pricingTierClient.getAssignedPricingTierForOrganization>
    >[]
  >
) => ReturnType<
  typeof useQuery<
    Awaited<
      ReturnType<typeof pricingTierClient.getAssignedPricingTierForOrganization>
    >[]
  >
> = (organizationIds, options) => {
  return useQuery<
    Awaited<
      ReturnType<typeof pricingTierClient.getAssignedPricingTierForOrganization>
    >[]
  >({
    queryKey: [
      PRICING_TIER_QUERY_IDS.GET_ASSIGNED_PRICING_TIER_OF_ORGANIZATIONS,
      organizationIds
    ],
    queryFn: () =>
      Promise.all(
        organizationIds.map((orgId) =>
          pricingTierClient.getAssignedPricingTierForOrganization({
            org_id: orgId
          })
        )
      ),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: organizationIds.length > 0,
    ...options
  })
}

export const useQueryGetPricingTierAssignments: (
  opts?: UseQueryOptions<PricingTierComponents.Schemas.Assignment[]>
) => ReturnType<typeof useQuery<PricingTierComponents.Schemas.Assignment[]>> = (
  opts
) => {
  return useQuery<PricingTierComponents.Schemas.Assignment[]>({
    queryKey: [PRICING_TIER_QUERY_IDS.GET_ALL_ASSIGNMENTS],
    queryFn: () =>
      pricingTierClient
        .getAssignments()
        .then(
          (res) =>
            res?.data.assignments?.filter(
              (assignment) => assignment && assignment.pricing_tier_id
            ) ?? []
        ),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
    ...opts
  })
}
