import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { PricingTierSettings } from './columns'

import PricingTierAdvancedSettings from '@/components/pricing-tier-settings'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { toast } from '@/components/ui/use-toast'
import { PricingTierSettingsType } from '@/models'
import { useAuth } from '@/providers/AuthContextProvider'
import { RoutePaths } from '@/routes/routes'
import { useMutationCreatePricingTier } from '@/services'

const PricingTierDefaultValues = Object.fromEntries(
  PricingTierSettings.map((field) => [field.key, field.default]).filter(
    (x) => x[0] !== 'name'
  )
)

export function PricingTierForm() {
  const { userData } = useAuth()
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [pricingTierSettings, setPricingTierSettings] =
    useState<PricingTierSettingsType>(PricingTierDefaultValues)
  const [pricingTierName, setPricingTierName] = useState('')
  const navigate = useNavigate()

  const createPricingTier = useMutationCreatePricingTier()
  const handleCreatePricingTier = () => {
    setIsButtonLoading(true)
    createPricingTier.mutate(
      {
        name: pricingTierName,
        settings: pricingTierSettings,
        created_by: userData?.attributes?.email,
        is_pure_360: true
      },
      {
        onSuccess: () => {
          setIsButtonLoading(false)
          navigate(RoutePaths.PRICING_TIERS)
        },
        onError: (err) => {
          setIsButtonLoading(false)
          toast({
            title: 'Error',
            description: `Error while creating pricing tier. ${err?.['message']}`
          })
        }
      }
    )
  }

  return (
    <div className="space-y-4">
      <div className="space-y-2">
        <Label htmlFor="name">ID</Label>
        <Input
          id="name"
          onChange={(e) => setPricingTierName(e.target.value)}
          placeholder="Expensive one..."
          value={pricingTierName}
        />
      </div>
      <PricingTierAdvancedSettings
        onChange={(e, key) => {
          setPricingTierSettings({
            ...pricingTierSettings,
            [key]: e
          })
        }}
        options={PricingTierSettings.filter((x) => x.key !== 'name').map(
          (x) => ({
            ...x,
            value: pricingTierSettings[x.key]
          })
        )}
      />
      <Button
        loading={isButtonLoading}
        onClick={() => handleCreatePricingTier()}
      >
        Create Pricing Tier
      </Button>
    </div>
  )
}
