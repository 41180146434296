import { isEqual } from 'lodash'

import JSONEditor from './json-editor'

import { Button } from '@/components/ui/button'
import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle
} from '@/components/ui/sheet'

type SheetJSONEditorProps = {
  onUpdate?: (data: unknown) => Promise<void>
  isUpdating?: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  open: boolean
  originalData: unknown
  dataState: unknown
  setDataState: React.Dispatch<React.SetStateAction<unknown>>
  disabled?: boolean
}

export const SheetJSONEditor = ({
  onUpdate,
  dataState,
  open,
  originalData,
  setDataState,
  setOpen,
  isUpdating = false,
  disabled = false
}: SheetJSONEditorProps) => (
  <Sheet onOpenChange={(o) => setOpen(o)} open={open}>
    <SheetContent className="flex flex-col">
      <SheetHeader>
        <SheetTitle className="pb-1 text-primary border-b border-border">
          {(originalData as any)?.name}
        </SheetTitle>
      </SheetHeader>
      <div className="rounded-md mx-2 p-2 overflow-y-auto relative">
        <JSONEditor
          originalData={originalData}
          setDataState={setDataState}
        />
      </div>
      {onUpdate && (
        <SheetFooter>
          <Button
            disabled={isEqual(originalData, dataState) || disabled}
            loading={isUpdating}
            onClick={() => onUpdate(dataState)}
          >
            Save changes
          </Button>
        </SheetFooter>
      )}
    </SheetContent>
  </Sheet>
)
