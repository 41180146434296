import { DotsHorizontalIcon } from '@radix-ui/react-icons'
import { Row } from '@tanstack/react-table'
import { Copy, Trash } from 'lucide-react'
import React from 'react'

import JSONEditor from '../json-editor'

import { Button } from './button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from './dialog'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from './dropdown-menu'
import { Icons } from './icons'
import { toast } from './use-toast'

interface DataTableRowActionsProps<TData> {
  row: Row<TData>
  deleteProps?: { handler: () => void; disabled: boolean }
  items?: {
    label: string
    icon: React.ReactNode
    onClick: (
      setLoading?: React.Dispatch<React.SetStateAction<boolean>>
    ) => void
    disabled?: boolean
  }[]
  showCopyId?: boolean
}

export function DataTableRowActions<TData>({
  row,
  deleteProps,
  items,
  showCopyId = true
}: DataTableRowActionsProps<TData>) {
  const [open, setOpen] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)

  const handleDelete = async () => {
    setIsLoading(true)
    try {
      deleteProps?.handler?.()
      setOpen(false)
    } catch (error: unknown) {
      toast({
        title: 'Error',
        description: `Error while deleting. ${error}`
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Dialog onOpenChange={(open) => setOpen(open)} open={open}>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
            disabled={isLoading}
            variant="ghost"
          >
            {isLoading ? (
              <Icons.spinner className="w-4 h-4 mr-0" />
            ) : (
              <DotsHorizontalIcon className="w-4 h-4" />
            )}
            <span className="sr-only">Open menu</span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuLabel>Actions</DropdownMenuLabel>
          {showCopyId && <DropdownMenuItem
            onClick={() => {
              try {
                navigator.clipboard.writeText((row.original as any).id)
                toast({
                  title: 'Copied',
                  description: 'ID copied to clipboard'
                })
              } catch (error) {
                toast({
                  title: 'Error',
                  description: 'Could not copy ID to clipboard'
                })
              }
            }}
          >
            <Copy className="w-4 h-4 mr-2" /> Copy ID
          </DropdownMenuItem>}
          {((items?.length || deleteProps?.handler) && showCopyId) && <DropdownMenuSeparator />}
          {deleteProps?.handler && (
            <>
              <DropdownMenuItem
                disabled={deleteProps.disabled}
                onClick={() => setOpen(true)}
              >
                <Trash className="w-4 h-4 mr-2" /> Delete
              </DropdownMenuItem>
            </>
          )}
          {items?.map((item, index) => (
            <DropdownMenuItem
              disabled={item.disabled}
              key={index}
              onClick={() => item.onClick?.(setIsLoading)}
            >
              {item.icon}
              {item.label}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="text-primary">
            Are you sure absolutely sure?
          </DialogTitle>
          <DialogDescription>
            This action cannot be undone. Are you sure you want to permanently
            delete this from our servers?
          </DialogDescription>
          <DialogDescription>
            Below is the JSON preview of the object you are about to delete.
          </DialogDescription>
        </DialogHeader>
        <div className="rounded-md max-h-[500px] mx-2 p-2 overflow-y-auto relative">
          <JSONEditor originalData={row.original} />
        </div>
        <DialogFooter>
          <Button
            loading={isLoading}
            onClick={async () => await handleDelete()}
            type="submit"
            variant={'destructive'}
          >
            Confirm
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
