import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { MultiSelectAutoComplete } from '@/components/ui/multi-select-autocomplete'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/components/ui/select'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { Textarea } from '@/components/ui/textarea'
import { toast } from '@/components/ui/use-toast'
import {
  categories,
  Category,
  ICreateMarketplaceBlueprint,
  IMarketplaceBlueprint,
  IMarketplaceBlueprintResource,
  mainCategories
} from '@/models'
import { RoutePaths } from '@/routes/routes'
import {
  useMutationCreateMarketplaceBlueprint,
  useMutationUpdateMarketplaceBlueprint,
  useQueryGetMarketplaceBlueprint,
  useQueryGetMarketplaceBlueprintResources
} from '@/services/marketplace-cms.service'

export function BlueprintDetail() {
  const { blueprintId } = useParams<{ blueprintId: string }>()
  const navigate = useNavigate()

  const { data: blueprint, isLoading } = useQueryGetMarketplaceBlueprint(
    blueprintId as string,
    {
      enabled: !!blueprintId
    }
  )
  const { data: resources, isLoading: isLoadingResources } =
    useQueryGetMarketplaceBlueprintResources()
  const [formData, setFormData] = useState<Partial<IMarketplaceBlueprint>>({
    name: '',
    description: '',
    resources: []
  })
  const [isMutating, setIsMutating] = useState(false)

  const createBlueprintMutation = useMutationCreateMarketplaceBlueprint()
  const updateBlueprintMutation = useMutationUpdateMarketplaceBlueprint()

  useEffect(() => {
    if (blueprint) {
      setFormData((prevData) => ({
        ...prevData,
        ...blueprint
      }))
    }
  }, [blueprint])

  const handleResourceSelect = (resource: IMarketplaceBlueprintResource) => {
    setFormData((prevData) => ({
      ...prevData,
      resources: [...(prevData.resources || []), resource.id]
    }))
  }

  const handleResourceUnselect = (resource: IMarketplaceBlueprintResource) => {
    setFormData((prevData) => ({
      ...prevData,
      resources: prevData.resources?.filter((id) => id !== resource.id)
    }))
  }

  const handleUpdateBlueprint = async () => {
    setIsMutating(true)

    updateBlueprintMutation.mutate(
      {
        ...formData,
        id: blueprintId
      } as IMarketplaceBlueprint,
      {
        onSuccess: () => {
          toast({
            title: 'Blueprint updated successfully'
          })
        },
        onError: (err) => {
          toast({
            title: 'Error',
            description: err?.message || 'An error occurred'
          })
        },
        onSettled: () => {
          setIsMutating(false)
        }
      }
    )
  }

  const handleCreateBlueprint = async () => {
    setIsMutating(true)

    createBlueprintMutation.mutate(
      {
        ...formData
      } as ICreateMarketplaceBlueprint,
      {
        onSuccess: (data) => {
          toast({
            title: 'Blueprint created successfully'
          })

          navigate(`${RoutePaths.MARKETPLACE_BLUEPRINTS}/${data.id}`)
        },
        onError: (err) => {
          toast({
            title: 'Error',
            description: err?.message || 'An error occurred'
          })
        },
        onSettled: () => {
          setIsMutating(false)
        }
      }
    )
  }

  return (
    <Tabs className="w-full" defaultValue="settings">
      <TabsList className="grid w-full grid-cols-1">
        <TabsTrigger loading={isLoading} value="settings">
          Settings
        </TabsTrigger>
      </TabsList>
      <TabsContent value="settings">
        <div className="space-y-4">
          <div className="flex space-x-12">
            <div className="w-1/2 space-y-4">
              {blueprintId && (
                <div className="space-y-2">
                  <Label htmlFor="id">ID</Label>
                  <Input disabled id="id" value={blueprintId} />
                </div>
              )}
              <div className="space-y-2">
                <Label htmlFor="name">Name</Label>
                <Input
                  id="name"
                  onChange={(e) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      name: e.target.value
                    }))
                  }}
                  value={formData?.name ?? ''}
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="subtext">Subtext</Label>
                <Textarea
                  id="subtext"
                  onChange={(e) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      subtext: e.target.value
                    }))
                  }}
                  value={formData?.subtext ?? ''}
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="details_page_title">Details Page Title</Label>
                <Input
                  id="details_page_title"
                  onChange={(e) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      details_page_title: e.target.value
                    }))
                  }}
                  type="text"
                  value={formData?.details_page_title ?? ''}
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="details_page_description">
                  Details Page Description
                </Label>
                <Textarea
                  id="details_page_description"
                  onChange={(e) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      details_page_description: e.target.value
                    }))
                  }}
                  value={formData?.details_page_description ?? ''}
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="main_category">Main Category</Label>
                <Select
                  name="main_category"
                  onValueChange={(e) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      main_category: e
                    }))
                  }}
                  value={formData?.main_category ?? ''}
                >
                  <SelectTrigger
                    className={classNames({
                      'text-primary': formData?.main_category,
                      'text-placeholder': !formData?.main_category
                    })}
                  >
                    <SelectValue placeholder="Select a main category" />
                  </SelectTrigger>
                  <SelectContent>
                    {mainCategories?.map((mainCategory) => (
                      <SelectItem key={mainCategory.id} value={mainCategory.id}>
                        {mainCategory.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <div className="space-y-2">
                <Label>Categories</Label>
                <MultiSelectAutoComplete
                  items={categories.filter(
                    (c) => c.mainCategory === formData.main_category
                  )}
                  labelKey="name"
                  onSelect={(item) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      categories: [
                        ...(prevData.categories || []),
                        (item as Category).id
                      ]
                    }))
                  }
                  onUnselect={(item) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      categories: prevData.categories?.filter(
                        (id) => id !== (item as Category).id
                      )
                    }))
                  }
                  placeholder="Select categories for this blueprint"
                  selectedItems={
                    (formData.categories
                      ?.map((id) => categories.find((c) => c.id === id))
                      .filter(Boolean) || []) as Category[]
                  }
                  valueKey="id"
                />
              </div>
              {blueprintId && (
                <>
                  <div className="space-y-2">
                    <Label htmlFor="created_at">Created At</Label>
                    <Input
                      disabled
                      id="created_at"
                      value={
                        blueprint?.created_at
                          ? new Date(blueprint?.created_at)?.toLocaleString()
                          : ''
                      }
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="updated_at">Updated At</Label>
                    <Input
                      disabled
                      id="updated_at"
                      value={
                        blueprint?.updated_at
                          ? new Date(blueprint?.updated_at)?.toLocaleString()
                          : ''
                      }
                    />
                  </div>
                </>
              )}
            </div>
            <div className="w-1/2 space-y-4">
              <div className="space-y-2">
                <Label htmlFor="order">Order</Label>
                <Input
                  id="order"
                  onChange={(e) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      order: e.target.value ? parseInt(e.target.value) : ''
                    }))
                  }}
                  type="number"
                  value={formData?.order ?? ''}
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="instalment_link">Instalment Link</Label>
                <Input
                  id="instalment_link"
                  onChange={(e) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      instalment_link: e.target.value
                    }))
                  }}
                  type="text"
                  value={formData?.instalment_link ?? ''}
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="available_on">Available On</Label>
                <Input
                  id="available_on"
                  onChange={(e) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      available_on: e.target.value
                    }))
                  }}
                  type="text"
                  value={formData?.available_on ?? ''}
                />
              </div>
              <div className="space-y-2">
                <Label>Resources</Label>
                <MultiSelectAutoComplete
                  disabled={isLoadingResources}
                  isLoading={isLoadingResources}
                  items={resources?.marketplace_blueprint_resources || []}
                  labelKey="name"
                  onSelect={(item) =>
                    handleResourceSelect(item as IMarketplaceBlueprintResource)
                  }
                  onUnselect={(item) =>
                    handleResourceUnselect(
                      item as IMarketplaceBlueprintResource
                    )
                  }
                  placeholder="Select resources for this blueprint"
                  selectedItems={
                    resources?.marketplace_blueprint_resources?.filter((r) =>
                      formData.resources?.includes(r.id as string)
                    ) || []
                  }
                  valueKey="id"
                />
              </div>
              {blueprintId && (
                <>
                  <div className="space-y-2">
                    <Label htmlFor="is_draft">Is Draft</Label>
                    <Input
                      disabled
                      id="is_draft"
                      type="text"
                      value={blueprint?.is_draft ? 'True' : 'False'}
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="is_archived">Is Archived</Label>
                    <Input
                      disabled
                      id="is_archived"
                      type="text"
                      value={blueprint?.is_archived ? 'True' : 'False'}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div>
            <Button
              loading={isMutating}
              onClick={
                blueprintId ? handleUpdateBlueprint : handleCreateBlueprint
              }
              type="submit"
            >
              {blueprintId ? 'Update Blueprint' : 'Create Blueprint'}
            </Button>
          </div>
        </div>
      </TabsContent>
    </Tabs>
  )
}
