import classNames from 'classnames'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

import { RoutePaths, getAllRoutes } from './routes'

import Sidebar from '@/components/ui/sidebar'
import { useCollapsibleSidebar } from '@/hooks/useCollapsibleSidebar'
import { useAuth } from '@/providers/AuthContextProvider'
import { isPermitted } from '@/utils/permission-manager'

export function Layout() {
  const { userData } = useAuth()
  const location = useLocation()
  const {
    sidebarCollapsed,
    sidebarHidden,
    manualCollapse,
    toggleSidebarCollapse
  } = useCollapsibleSidebar()

  const isPermittedToView = () => {
    const routePermission = getAllRoutes().find(
      (route) => route.path === location.pathname
    )?.permission

    return (
      userData &&
      (routePermission ? isPermitted(routePermission, userData) : true)
    )
  }

  return (
    <>
      {!isPermittedToView() ? (
        <Navigate to={RoutePaths.LOGIN} />
      ) : (
        <div
          className={classNames({
            'grid min-h-screen': true,
            'grid-cols-sidebar': !sidebarCollapsed && !sidebarHidden,
            'grid-cols-sidebar-collapsed': sidebarCollapsed && !sidebarHidden,
            'transition-[grid-template-columns] duration-300 ease-in-out': true
          })}
        >
          {!sidebarHidden && (
            <Sidebar
              collapsed={sidebarCollapsed || manualCollapse}
              onToggle={toggleSidebarCollapse}
            />
          )}
          <div className="bg-background max-h-screen p-5 overflow-x-hidden overflow-y-auto">
            <Outlet />
          </div>
        </div>
      )}
    </>
  )
}
