'use client'

import classNames from 'classnames'
import { ChevronDown, ChevronUp } from 'lucide-react'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import { Icons } from './ui/icons'

import { Button } from '@/components/ui/button'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger
} from '@/components/ui/collapsible'
import { usePricingTiers } from '@/providers'
import { RoutePaths } from '@/routes/routes'
import { useQueryGetOrganizations } from '@/services'

export function PricingTierInUseOrganizations({
  pricingTierId,
  defaultOpen = true
}: {
  pricingTierId: string
  defaultOpen?: boolean
}) {
  const { pricingTierAssignments, isLoading } = usePricingTiers()
  const { data: organizations, isFetching: isFetchingOrgs } =
    useQueryGetOrganizations()

  const [open, setOpen] = useState(defaultOpen)

  return (
    <Collapsible
      className="px-4 py-2 space-y-2 border border-border rounded-md"
      onOpenChange={(e) => setOpen(e)}
      open={open}
    >
      <CollapsibleTrigger asChild>
        <div className="flex items-center justify-between cursor-pointer">
          <h4 className="text-sm font-semibold text-primary">
            In Use Organizations
          </h4>
          <Button className="p-0 w-9" size="sm" type="button" variant="ghost">
            {isLoading || isFetchingOrgs ? (
              <Icons.spinner className="w-4 h-4 mr-0" />
            ) : open ? (
              <ChevronUp className="w-4 h-4" />
            ) : (
              <ChevronDown className="w-4 h-4" />
            )}
            <span className="sr-only">Toggle</span>
          </Button>
        </div>
      </CollapsibleTrigger>
      <CollapsibleContent className="space-y-2">
        <div className="max-h-[90vh] overflow-y-auto">
          <div className="space-y-4">
            {pricingTierAssignments
              ?.filter(
                (assignment) => assignment?.pricing_tier_id == pricingTierId
              )
              .map((assignment, index) => (
                <div
                  className={classNames({
                    'rounded-md border border-border px-3 py-0 text-sm':
                      !isLoading && !isFetchingOrgs
                  })}
                  key={index}
                >
                  {isLoading || isFetchingOrgs ? (
                    <div className="h-4 py-4 bg-muted rounded-lg animate-pulse" />
                  ) : (
                    <Button className="p-0" variant={'link'}>
                      <Link
                        to={`${RoutePaths.ORGANIZATIONS}/${assignment?.organization_id}`}
                      >
                        {
                          organizations?.find(
                            (org) => org?.id == assignment?.organization_id
                          )?.name
                        }
                      </Link>
                    </Button>
                  )}
                </div>
              ))}
          </div>
        </div>
      </CollapsibleContent>
    </Collapsible>
  )
}
