import { getClient } from '@epilot/blueprint-client'
import { useMemo } from 'react'

import { config } from '../configs/api.config'

import { configureClient } from './api-utils'

let client: ReturnType<typeof getClient>

export const getBlueprintClient = () => {
  if (!client) {
    client = getClient()

    configureClient(client, {
      baseURL: config.BLUEPRINT
    })
  }

  return client
}

export const useBlueprintClient = () => useMemo(() => getBlueprintClient(), [])

export type {
  Components as BlueprintComponents,
  Paths as BlueprintPaths
} from '@epilot/blueprint-client'
