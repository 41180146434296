import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { getEntityClient } from '@/api/entity-client'

const client = getEntityClient()

enum ENTITY_QUERY_IDS {
  GET_ACTIVE_PARTNERS_BY_ORG_ID = 'GET_ACTIVE_PARTNERS_BY_ORG_ID',
  GET_ALL_ACTIVE_PARTNERS = 'GET_ALL_ACTIVE_PARTNERS',
  GET_ALL_PARTNERSHIP_BY_ORG_IDS = 'GET_ALL_PARTNERSHIP_BY_ORG_IDS',
  GET_ALL_USERS_BY_ORG_IDS = 'GET_ALL_USERS_BY_ORG_IDS',
  GET_ELASTIC_CLUSTER_FOR_ORG = 'GET_ELASTIC_CLUSTER_FOR_ORG'
}

export const useQueryUsersByOrgId: (
  orgId: string,
  portal: string,
  opts: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof client.searchEntities>>>
  >
) => ReturnType<
  typeof useQuery<Awaited<ReturnType<typeof client.searchEntities>>>
> = (orgId, portal, opts) => {
  return useQuery<Awaited<ReturnType<typeof client.searchEntities>>>({
    queryFn: () =>
      client.searchEntities(
        null,
        {
          q: `_schema:portal_user AND origin:${portal}`,
          size: 10000
        },
        { headers: { 'x-ivy-org-id': orgId } }
      ),
    refetchOnWindowFocus: false,
    ...opts,
    queryKey: [ENTITY_QUERY_IDS.GET_ALL_USERS_BY_ORG_IDS, orgId, portal]
  })
}

export const useQueryGetActivePartnerByOrgId: (
  orgId: string,
  opts?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof client.searchEntities>>>
  >
) => ReturnType<
  typeof useQuery<Awaited<ReturnType<typeof client.searchEntities>>>
> = (orgId, opts) => {
  return useQuery<Awaited<ReturnType<typeof client.searchEntities>>>({
    queryKey: [ENTITY_QUERY_IDS.GET_ACTIVE_PARTNERS_BY_ORG_ID, orgId],
    queryFn: () =>
      client.searchEntities(
        null,
        {
          q: '_schema:partner AND status:Partnership',
          size: 1000,
          fields: ['_id', '_title', 'partner_org_id']
        },
        { headers: { 'x-ivy-org-id': orgId } }
      ),
    refetchOnWindowFocus: false,
    ...opts
  })
}

export const useQueryGetAllActivePartners: (
  orgIds: string[],
  options?: UseQueryOptions<Awaited<ReturnType<typeof client.searchEntities>>[]>
) => ReturnType<
  typeof useQuery<Awaited<ReturnType<typeof client.searchEntities>>[]>
> = (orgIds, options) => {
  return useQuery<Awaited<ReturnType<typeof client.searchEntities>>[]>({
    queryKey: [ENTITY_QUERY_IDS.GET_ALL_PARTNERSHIP_BY_ORG_IDS, orgIds],
    queryFn: () =>
      Promise.all(
        orgIds.map((orgId) =>
          client.searchEntities(
            null,
            {
              q: '_schema:partner AND status:Partnership',
              size: 1000,
              fields: ['_id', '_title', 'partner_org_id', '_org']
            },
            { headers: { 'x-ivy-org-id': orgId } }
          )
        )
      ),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: orgIds?.length > 0,
    ...options
  })
}

export const useQueryGetOrgElasticCluster: (
  orgId: string,
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof client.getElasticClusterForOrg>>
  >
) => ReturnType<
  typeof useQuery<Awaited<ReturnType<typeof client.getElasticClusterForOrg>>>
> = (orgId, options) => {
  return useQuery<Awaited<ReturnType<typeof client.getElasticClusterForOrg>>>({
    queryKey: [ENTITY_QUERY_IDS.GET_ELASTIC_CLUSTER_FOR_ORG, orgId],
    queryFn: () =>
      client.getElasticClusterForOrg(orgId, {
        headers: { 'x-ivy-org-id': orgId }
      }),
    refetchOnWindowFocus: false,
    ...options
  })
}
