import { createContext, useContext, useEffect, useState } from 'react'

import { IDataPoint } from '@/models'
import {
  useQueryGetOrganizationDataPoints,
  useQueryGetUserDataPoints
} from '@/services'

export interface DataPointsContext {
  dataPoints: IDataPoint[]
  isLoading: boolean
}

const DataPointsContext = createContext<DataPointsContext>({
  dataPoints: [],
  isLoading: false
})

export const DataPointsContextProvider = (props: { children: JSX.Element }) => {
  // get data for datapoints for both org and user api
  const orgDataPointsQueryResp = useQueryGetOrganizationDataPoints()
  const userDataPointsQueryResp = useQueryGetUserDataPoints()

  const [dataPoints, setDataPoints] = useState<IDataPoint[]>([])

  // when data is fetched, merge userDataPoints to orgDataPoints and export as dataPoints
  useEffect(() => {
    if (
      userDataPointsQueryResp?.data?.data &&
      orgDataPointsQueryResp?.data?.data
    ) {
      // filter out the items which has no id
      const userDataPoints = userDataPointsQueryResp.data.data.filter(
        (elem) => elem.id
      )
      // filter out the items which has no id
      const orgDataPoints = orgDataPointsQueryResp.data.data.filter(
        (elem) => elem.id
      )

      const tempDataPoints: IDataPoint[] = []

      userDataPoints.forEach((userDataPoint) => {
        const orgDataPoint = orgDataPoints.find(
          (x) => x.id === userDataPoint.id
        )

        const actual_users = userDataPoint?.actual_users ?? 0
        const max_users_last_month = userDataPoint?.max_users_last_month ?? 0
        const non_billable_users_last_month =
          userDataPoint?.non_billable_users_last_month ?? 0
        const max_customer = orgDataPoint?.max_customer ?? 0
        const actual_customer = orgDataPoint?.actual_customer ?? 0

        tempDataPoints.push({
          actual_users,
          max_users_last_month,
          non_billable_users_last_month,
          max_customer,
          actual_customer,
          org_id: userDataPoint.id!
        })
      })

      setDataPoints(tempDataPoints)
    }
  }, [userDataPointsQueryResp?.data?.data, orgDataPointsQueryResp?.data?.data])

  return (
    <DataPointsContext.Provider
      value={{
        isLoading:
          orgDataPointsQueryResp.isFetching ||
          userDataPointsQueryResp.isFetching,
        dataPoints
      }}
    >
      {props.children}
    </DataPointsContext.Provider>
  )
}

export const useDataPoints = () => useContext(DataPointsContext)
