import { useEffect, useState } from 'react'

import { PricingTierComponents } from '@/api'
import { getPricingTierTableColumns } from '@/modules/pricing-tier/columns'
import { usePricingTiers } from '@/providers'
import { useMutationDeletePricingTier } from '@/services'

export const usePricingTierTable = () => {
  const [pricingTiersWithAssignments, setPricingTiersWithAssignments] =
    useState<PricingTierComponents.Schemas.PricingTier[]>([])
  const { pricingTiers, pricingTierAssignments, isLoading } = usePricingTiers()

  useEffect(() => {
    if (!isLoading) {
      const dataWithPricingTiers = pricingTiers?.map((pricingTier) => {
        return {
          ...pricingTier,
          in_use: pricingTierAssignments?.filter(
            (p) => p?.pricing_tier_id == pricingTier?.id
          ).length
        }
      })

      setPricingTiersWithAssignments(dataWithPricingTiers ?? [])
    }
  }, [isLoading, pricingTierAssignments, pricingTiers])

  const deletePricingTier = useMutationDeletePricingTier()

  const handleConfirmDeletePricingTier = (pricingTierId: string) => {
    deletePricingTier.mutate(pricingTierId, {
      onSuccess: () => {
        return
      },
      onError: (err) => {
        throw err?.['message']
      }
    })
  }

  return {
    data: pricingTiersWithAssignments,
    isLoading: isLoading,
    columns: getPricingTierTableColumns(handleConfirmDeletePricingTier)
  }
}
