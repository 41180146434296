import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { DataTable } from '@/components/ui/data-table'
import { usePricingTierTable } from '@/hooks/usePricingTierTable'
import { useDataPoints } from '@/providers'
import { RoutePaths } from '@/routes/routes'

const DEFAULT_COLUMNS_TO_HIDE = {
  id: false
}

const PricingTierList = () => {
  const { isLoading, data, columns } = usePricingTierTable()
  const navigate = useNavigate()
  const [isReadyExport, setIsReadyExport] = useState(false)
  const { isLoading: isDataPointsLoading } = useDataPoints()

  useEffect(() => {
    setIsReadyExport(!isDataPointsLoading && !isLoading)
  }, [isDataPointsLoading, isLoading])

  return (
    <>
      <DataTable
        allowCreate={true}
        columns={columns}
        data={data}
        defaultColumnVisibility={DEFAULT_COLUMNS_TO_HIDE}
        isLoading={isLoading}
        onRowClick={(row) => {
          navigate(`${RoutePaths.PRICING_TIERS}/${row.original.id}`)
        }}
        tableConfigName="pricing-tier"
        toolbarProps={{
          exportButtonProps: {
            loading: !isReadyExport
          }
        }}
      />
    </>
  )
}

export default PricingTierList
