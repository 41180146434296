import { startCase } from "lodash";
import { ChevronDown, ChevronUp } from "lucide-react";
import { useState } from "react";

import { Button } from "./ui/button";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "./ui/collapsible";
import { Icons } from "./ui/icons";
import { Label } from "./ui/label";

import { Switch } from "@/components/ui/switch";
import { useAuth } from "@/providers";
import { isPermitted, Permissions } from "@/utils/permission-manager";

export const OrganizationFeatures = {
  canary: {
    enabled: false,
  },
  release_candidate: {
    enabled: false,
  },
  "360_features": {
    enabled: false,
  },
  export_v1: {
    enabled: false,
  },
  double_opt_in: {
    enabled: false,
  },
  end_customer_portal: {
    enabled: false,
  },
  contracts_preview_setting: {
    enabled: false,
  },
  installer_portal: {
    enabled: false,
  },
  metering_settings: {
    enabled: false,
  },
  commodity_billing: {
    enabled: false,
  },
  partnering: {
    enabled: false,
  },
  automation_entity_mapping: {
    enabled: false,
  },
  summary_apply_purposes: {
    enabled: false,
  },
  purposes_on_top: {
    enabled: false,
  },
  journey_builder_custom_block_enabled: {
    enabled: false,
  },
  journey_builder_advanced_settings: {
    enabled: false,
  },
  mfa_enabled: {
    enabled: false,
    enforce_mfa: {
      enabled: true,
    },
  },
  sso: {
    enabled: false,
  },
  custom_entities: {
    enabled: false,
  },
  notracking: {
    enabled: false,
  },
  sfx: {
    enabled: false,
  },
};

type OrganizationFeatureSettingsProps = {
  settings: {
    [key: string]: {
      enabled: boolean;
    };
  };
  loading?: boolean;
  defaultOpen?: boolean;
  onChange?: (setting: {
    key: string;
    value: { [key: string]: boolean };
  }) => void;
  disabled?: boolean;
};

const OrganizationFeatureSettings = ({
  settings,
  loading,
  defaultOpen = true,
  onChange,
  disabled = false,
}: OrganizationFeatureSettingsProps) => {
  const { userData } = useAuth();
  const [open, setOpen] = useState(defaultOpen);

  return (
    <Collapsible
      className="px-4 py-2 space-y-2 border border-border rounded-md"
      onOpenChange={(e) => setOpen(e)}
      open={open}
    >
      <CollapsibleTrigger asChild>
        <div className="flex items-center justify-between cursor-pointer">
          <h4 className="text-sm font-semibold text-primary">
            Active Features
          </h4>
          <Button className="p-0 w-9" size="sm" type="button" variant="ghost">
            {loading ? (
              <Icons.spinner className="w-4 h-4 mr-0" />
            ) : open ? (
              <ChevronUp className="w-4 h-4" />
            ) : (
              <ChevronDown className="w-4 h-4" />
            )}
            <span className="sr-only">Toggle</span>
          </Button>
        </div>
      </CollapsibleTrigger>
      <CollapsibleContent className="space-y-2">
        <div className="max-h-[90vh] overflow-y-auto">
          <div className="space-y-4">
            {Object.entries(settings).map(([setting, value]) => {
              return (
                <div className="p-2 border border-border rounded-lg">
                  <div
                    className="space-y-2 flex flex-row items-center justify-between"
                    key={setting}
                  >
                    <Label htmlFor={setting}>{startCase(setting)}</Label>
                    <Switch
                      loading={loading}
                      checked={value?.enabled ?? false}
                      onCheckedChange={(e) => {
                        onChange?.({
                          key: setting,
                          value: {
                            enabled: e,
                          },
                        });
                      }}
                      disabled={
                        !isPermitted(Permissions.EDIT_ORG_SETTINGS, userData) ||
                        disabled
                      }
                      id={setting}
                      style={{ marginTop: 0 }}
                    />
                  </div>
                  {setting === "mfa_enabled" && value.enabled && (
                    <div className="space-y-2 flex flex-row items-center justify-between mt-4">
                      <Label className="m-0 p-0" htmlFor="enforce_mfa">
                        {startCase("enforce_mfa")}
                      </Label>
                      <Switch
                        loading={loading}
                        id="enforce_mfa"
                        onCheckedChange={(e) => {
                          onChange?.({
                            key: setting,
                            value: {
                              enabled: value.enabled,
                              enforce_mfa: e,
                            },
                          });
                        }}
                        checked={(value as any)?.enforce_mfa ?? false}
                        disabled={
                          !isPermitted(
                            Permissions.EDIT_ORG_SETTINGS,
                            userData
                          ) || disabled
                        }
                        style={{ marginTop: 0 }}
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </CollapsibleContent>
    </Collapsible>
  );
};

export default OrganizationFeatureSettings;
