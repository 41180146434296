import {
  ColumnDef,
  OnChangeFn,
  PaginationState,
  Row,
  RowModel,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table'
import classNames from 'classnames'
import * as React from 'react'

import { DataTableFacetedFilter } from './data-table-faceted-filter'
import { DataTablePagination } from './data-table-pagination'
import { DataTableToolbar } from './data-table-toolbar'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from '@/components/ui/table'
import useTableConfig, { StorageType } from '@/hooks/useTableConfig'

import './table.css'

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
  toolbarProps?: Omit<
    React.ComponentProps<typeof DataTableToolbar>,
    'table' | 'initialInput'
  >
  isLoading?: boolean
  onPaginationChange?: (rows: RowModel<TData>) => void
  allowCreate?: boolean
  onRowClick?: (row: Row<TData>) => void
  defaultColumnVisibility?: VisibilityState
  defaultSorting?: SortingState
  defaultColumnFilters?: []
  defaultGlobalFilter?: string
  setDefaultGlobalFilter?: (globalFilter: string) => void
  tableConfigName: string
  defaultPagination?: { pageSize: number; pageIndex: number }
  manualPagination?: {
    pagination: PaginationState;
    setPagination: OnChangeFn<PaginationState>
    pageCount?: number
  }
}

export function DataTable<TData, TValue>({
  columns,
  data,
  isLoading,
  toolbarProps,
  onPaginationChange,
  allowCreate,
  onRowClick,
  defaultColumnVisibility = {},
  defaultSorting = [
    {
      desc: true,
      id: 'id'
    }
  ],
  defaultPagination = { pageIndex: 0, pageSize: 10 },
  defaultColumnFilters = [],
  defaultGlobalFilter = '',
  setDefaultGlobalFilter,
  tableConfigName,
  manualPagination,
}: DataTableProps<TData, TValue>) {
  const [rowSelection, setRowSelection] = React.useState({})
  const tableData = React.useMemo(
    () => (isLoading ? Array(10).fill({}) : data),
    [isLoading, data]
  )
  const tableColumns = React.useMemo(
    () =>
      isLoading
        ? columns.map((column) => ({
            ...column,
            cell: () => (
              <div className="h-4 bg-muted rounded-lg animate-pulse" />
            )
          }))
        : columns,
    [isLoading, columns]
  )

  const { state: columnVisibility, setState: setColumnVisibility } =
    useTableConfig(tableConfigName, defaultColumnVisibility, 'columnVisibility')

  const { state: sorting, setState: setSorting } = useTableConfig(
    tableConfigName,
    defaultSorting,
    'sorting'
  )

  const { state: pagination, setState: _setPagination } = useTableConfig(
    tableConfigName,
    manualPagination?.pagination ?? defaultPagination,
    'pagination'
  )


  const setPagination = (_pagination: PaginationState) => {
    _setPagination(_pagination)
    manualPagination?.setPagination(_pagination)
  }

  const { state: columnFilters, setState: setColumnFilters } = useTableConfig(
    tableConfigName,
    defaultColumnFilters,
    'columnFilters',
    StorageType.SESSION_STORAGE
  )

  const { state: globalFilter, setState: _setGlobalFilter } = useTableConfig(
    tableConfigName,
    defaultGlobalFilter,
    'globalFilter',
    StorageType.SESSION_STORAGE
  )

  const setGlobalFilter = (_globalFilter: string) => {
    _setGlobalFilter(_globalFilter)
    setDefaultGlobalFilter?.(_globalFilter)
  }

  const table = useReactTable({
    data: tableData,
    columns: tableColumns,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters,
      pagination: manualPagination?.pagination ?? pagination,
      globalFilter,
    },
    columnResizeMode: 'onChange',
    enableRowSelection: true,
    manualPagination: !!manualPagination,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting as any,
    onColumnFiltersChange: setColumnFilters as any,
    onColumnVisibilityChange: setColumnVisibility as any,
    onPaginationChange: setPagination as any,
    onGlobalFilterChange: setGlobalFilter as any,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    pageCount: manualPagination?.pageCount,
  })

  React.useEffect(() => {
    if (onPaginationChange) {
      onPaginationChange(table.getPaginationRowModel())
    }
  }, [table.getPaginationRowModel()])

  return (
    <div className="space-y-4">
      <DataTableToolbar
        {...toolbarProps}
        allowCreate={allowCreate}
        initialInput={globalFilter ?? ''}
        table={table}
      />
      <div className="border border-border rounded-md">
        <Table style={{ width: '100%' }}>
          <TableHeader className="z-[1] sticky top-0 bg-background backdrop-blur supports-[backdrop-filter]:bg-background/60 shadow-md">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      colSpan={header.colSpan}
                      key={header.id}
                      style={{ width: header.getSize(), position: 'relative' }}
                    >
                      <div className="flex">
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                        {header.column.getCanFilter() ? (
                          <div>
                            <DataTableFacetedFilter
                              column={header.column}
                              key={header.id}
                            />
                          </div>
                        ) : null}
                      </div>
                      {header.column.getCanResize() && (
                        <div
                          {...{
                            onMouseDown: header.getResizeHandler(),
                            onTouchStart: header.getResizeHandler(),
                            className: `resizer ${
                              header.column.getIsResizing() ? 'isResizing' : ''
                            }`
                          }}
                        >
                          <div className="h-full flex items-center">
                            <div
                              className={`arrowLeft ${
                                header.column.getIsResizing()
                                  ? 'isResizing'
                                  : ''
                              }`}
                            />
                            <div
                              className={`arrowRight ${
                                header.column.getIsResizing()
                                  ? 'isResizing'
                                  : ''
                              }`}
                            />
                          </div>
                        </div>
                      )}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  className="cursor-pointer"
                  data-state={row.getIsSelected() && 'selected'}
                  key={row.id}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell
                      className={classNames({
                        'text-primary whitespace-nowrap': true,
                        'sticky right-0 bg-background backdrop-blur supports-[backdrop-filter]:bg-background/10':
                          cell.column.id === 'actions'
                      })}
                      key={cell.id}
                      onClick={() => {
                        if (
                          onRowClick &&
                          cell.column.id !== 'select' &&
                          cell.column.id !== 'actions'
                        ) {
                          onRowClick(row)
                        }
                      }}
                      style={{
                        width: cell.column.getSize(),
                        maxWidth: cell.column.columnDef.maxSize
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  className="h-24 text-center text-primary"
                  colSpan={columns.length}
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <DataTablePagination table={table} />
    </div>
  )
}
