type UsageMetricsProps = {
  orgId: string
}

const UsageMetrics = (props: UsageMetricsProps) => {
  const { orgId } = props

  return (
    <div style={{ height: 'calc(100vh - 5rem)' }}>
      <iframe
        allowFullScreen={true}
        height={'100%'}
        src={`https://app.powerbi.com/reportEmbed?reportId=a15f97e7-56c7-41f6-866e-5c85fff9ecaa&autoAuth=true&ctid=33d4f3e5-3df2-421e-b92e-a63cfa680a88&filter=customer_usage_metrics_view/org_id eq '${orgId}'`}
        title="usage_metrics"
        width={'100%'}
      />
    </div>
  )
}

export default UsageMetrics
