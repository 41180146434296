import { Auth } from 'aws-amplify'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { LocalStorage } from 'ttl-localstorage'

import { ADMIN_PORTAL_TOKEN_LS_KEY, getToken } from '@/api'
import { Icons } from '@/components/ui/icons'

interface UserData {
  attributes: { [key: string]: string }
}

interface AuthContextType {
  userData: UserData | null
  setUserData: React.Dispatch<React.SetStateAction<UserData | null>>
  logout: () => Promise<void>
}

const initialState: AuthContextType = {
  userData: null,
  setUserData: () => null,
  logout: async () => Promise.resolve()
}

const AuthContext = createContext<AuthContextType>(initialState)

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [userData, setUserData] = useState<UserData | null>(null)
  const [loading, setLoading] = useState(true)

  const logout = useCallback(async () => {
    try {
      LocalStorage.removeKey(ADMIN_PORTAL_TOKEN_LS_KEY)
      sessionStorage.clear()
      setUserData(null)
      await Auth.signOut()
    } catch (error) {
      console.error('Logout failed:', error)
    }
  }, [])

  const checkAuth = useCallback(async () => {
    try {
      if (!userData) {
        const user = await Auth.currentAuthenticatedUser()

        if (user) {
          await getToken()
          setUserData(user)
        }
      }
    } catch (error) {
      console.error('Auth check failed:', error)
      LocalStorage.removeKey(ADMIN_PORTAL_TOKEN_LS_KEY)
    } finally {
      setLoading(false)
    }
  }, [userData])

  useEffect(() => {
    checkAuth()
  }, [checkAuth])

  const value: AuthContextType = {
    userData,
    setUserData,
    logout
  }

  if (loading) {
    return <LoadingSpinner />
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext)

  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider')
  }

  return context
}

const LoadingSpinner = () => (
  <div className="bg-background w-screen h-screen flex items-center justify-center">
    <Icons.spinner className="w-8 h-8 text-primary animate-spin" />
  </div>
)
