import { useEffect, useState } from 'react'

const TABLE_CONFIG_STORAGE_KEY = 'tableConfig'

export enum StorageType {
  LOCAL_STORAGE = 'localStorage',
  SESSION_STORAGE = 'sessionStorage'
}

const useTableConfig = <T>(
  tableName: string,
  initialValue: T | undefined,
  settingKey: string,
  storageType: StorageType = StorageType.LOCAL_STORAGE
) => {
  const [state, setState] = useState<T | undefined>(initialValue)
  const storage =
    storageType === StorageType.LOCAL_STORAGE ? localStorage : sessionStorage

  const loadTableConfig = () => {
    const tableConfig = storage.getItem(
      `${TABLE_CONFIG_STORAGE_KEY}-${tableName}`
    )

    if (tableConfig) {
      const parsedTableConfig = JSON.parse(tableConfig)

      if (parsedTableConfig[settingKey]) {
        setState(parsedTableConfig[settingKey])
      } else {
        setState(initialValue)
      }
    }
  }

  const saveTableConfig = () => {
    const tableConfig = storage.getItem(
      `${TABLE_CONFIG_STORAGE_KEY}-${tableName}`
    )

    if (tableConfig) {
      const parsedTableConfig = JSON.parse(tableConfig)

      storage.setItem(
        `tableConfig-${tableName}`,
        JSON.stringify({ ...parsedTableConfig, [settingKey]: state })
      )
    } else {
      storage.setItem(
        `tableConfig-${tableName}`,
        JSON.stringify({ [settingKey]: state })
      )
    }
  }

  useEffect(() => {
    loadTableConfig()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    saveTableConfig()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  return {
    state,
    setState
  }
}

export default useTableConfig
