import { Components } from '@epilot/organization-client-internal'
import { isEqual } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useUpdateEffect } from 'react-use'

import { PricingTierComponents } from '@/api/pricing-tier-client'
import { IDataPoint, IOrganization, PartnershipDataPoint } from '@/models'
import { OrganizationTableColumns } from '@/modules/organization/columns'
import { useDataPoints, usePartnership, usePricingTiers } from '@/providers'
import { useQueryGetOrganizations, useQueryGetSettingsByKey } from '@/services'

export const useOrganizationTable = () => {
  const [organizations, setOrganizations] = useState<IOrganization[]>([])
  const { data, isFetching, isFetched } = useQueryGetOrganizations()

  const { isLoading: isLoadingCanaryStatuses, data: canaryStatuses } =
    useQueryGetSettingsByKey('canary')

  const {
    isLoading: isLoadingReleaseCandidateStatus,
    data: releaseCandidateStatuses
  } = useQueryGetSettingsByKey('release_candidate')
  const { dataPoints, isLoading: isDataPointsLoading } = useDataPoints()

  const {
    pricingTiers,
    isLoading: isPricingTiersLoading,
    pricingTierAssignments
  } = usePricingTiers()

  const {
    isLoading: isPartnershipLoading,
    partnershipDataPoints,
    setPartnershipOrgIdsToFetch
  } = usePartnership()

  useEffect(() => {
    if (isFetched && data) {
      const tempData = data.map((x) => ({
        ...x,
        is_unlicensed_org: x.is_unlicensed_org ? 'Signup' : 'Admin'
      }))

      setOrganizations(tempData as any)
    }
  }, [data, isFetched])

  const updateDataPoints = useCallback(
    (organizations: IOrganization[], dataPoints: IDataPoint[]) => {
      const newOrganizations = organizations.map((org) => {
        const dataPoint = dataPoints.find((x) => x.org_id === parseInt(org.id!))

        let additional_paid_users = 0
        let max_billable_users_last_month = 0
        const actual_users = dataPoint?.actual_users ?? 0
        const max_users_last_month = dataPoint?.max_users_last_month ?? 0
        const actual_customer = dataPoint?.actual_customer ?? 0
        const max_customer = dataPoint?.max_customer ?? 0

        if (org.type !== 'Partner' && dataPoint) {
          max_billable_users_last_month =
            (dataPoint?.max_users_last_month ?? 0) -
            (dataPoint?.non_billable_users_last_month ?? 0)
          if (
            org.free_user_limit &&
            +org?.free_user_limit > 0 &&
            max_billable_users_last_month > +org?.free_user_limit
          ) {
            additional_paid_users =
              max_billable_users_last_month - +org.free_user_limit
          }
        }

        return {
          ...org,
          additional_paid_users,
          max_billable_users_last_month,
          actual_users,
          max_users_last_month,
          actual_customer,
          max_customer
        }
      })

      if (!isEqual(organizations, newOrganizations))
        setOrganizations(newOrganizations)
    },
    []
  )

  const updatePartnershipDataPoints = useCallback(
    (organizations: IOrganization[], partnershipDP: PartnershipDataPoint[]) => {
      if (partnershipDP) {
        const newOrganizations = organizations.map((org) => {
          const maxPartnerUsersLastMonth = partnershipDP.find(
            (x) => x.org_id.toString() === org.id
          )?.max_partner_users

          return {
            ...org,
            max_partner_users: maxPartnerUsersLastMonth ?? 0
          }
        })

        if (!isEqual(organizations, newOrganizations)) {
          setOrganizations(newOrganizations)
        }
      }
    },
    []
  )

  const updatePricingTiers = useCallback(
    (
      organizations: IOrganization[],
      pricingTierAssignments: PricingTierComponents.Schemas.Assignment[],
      pricingTiers: PricingTierComponents.Schemas.PricingTier[]
    ) => {
      if (pricingTiers && pricingTierAssignments) {
        const newOrganizations = organizations.map((org) => {
          const pricingTierId = pricingTierAssignments?.find(
            (p) => p.organization_id == org?.id
          )?.pricing_tier_id

          const newPricingTierName = pricingTiers?.find(
            (p) => p?.id === pricingTierId
          )?.name

          return {
            ...org,
            new_pricing_tier: newPricingTierName
          }
        })

        if (!isEqual(organizations, newOrganizations)) {
          setOrganizations(newOrganizations)
        }
      }
    },
    []
  )

  const updateCanaryStatuses = useCallback(
    (
      organizations: IOrganization[],
      canaryStatuses: Components.Schemas.InternalSettingsResponse
    ) => {
      if (canaryStatuses) {
        const newOrganizations = organizations.map((org) => {
          const canaryStatus = canaryStatuses?.find((p) => p.org_id == org?.id)
            ?.value as { enabled: boolean }

          return {
            ...org,
            canary_status: canaryStatus ? true : false
          }
        })

        if (!isEqual(organizations, newOrganizations)) {
          setOrganizations(newOrganizations)
        }
      }
    },
    []
  )

  const updateReleaseCandidateStatuses = useCallback(
    (
      organizations: IOrganization[],
      releaseCandidateStatuses: Components.Schemas.InternalSettingsResponse
    ) => {
      if (releaseCandidateStatuses) {
        const newOrganizations = organizations.map((org) => {
          const releaseCandidateStatus = releaseCandidateStatuses?.find(
            (p) => p.org_id == org?.id
          )?.value as { enabled: boolean }

          return {
            ...org,
            release_candidate_status: releaseCandidateStatus ? true : false
          }
        })

        if (!isEqual(organizations, newOrganizations)) {
          setOrganizations(newOrganizations)
        }
      }
    },
    []
  )

  useUpdateEffect(() => {
    if (isDataPointsLoading || !organizations.length || !dataPoints.length)
      return

    updateDataPoints(organizations, dataPoints)
  }, [organizations, dataPoints, isDataPointsLoading, updateDataPoints])

  useUpdateEffect(() => {
    if (
      isPartnershipLoading ||
      !organizations.length ||
      !partnershipDataPoints.length
    )
      return

    updatePartnershipDataPoints(organizations, partnershipDataPoints)
  }, [
    isPartnershipLoading,
    organizations,
    partnershipDataPoints,
    updatePartnershipDataPoints
  ])

  useUpdateEffect(() => {
    if (
      isPricingTiersLoading ||
      !organizations.length ||
      !pricingTiers?.length ||
      !pricingTierAssignments?.length
    )
      return

    updatePricingTiers(organizations, pricingTierAssignments, pricingTiers)
  }, [organizations, isPricingTiersLoading, pricingTiers, updatePricingTiers])

  useUpdateEffect(() => {
    if (
      isLoadingReleaseCandidateStatus ||
      !organizations.length ||
      !releaseCandidateStatuses?.data.length
    )
      return

    updateReleaseCandidateStatuses(organizations, releaseCandidateStatuses.data)
  }, [organizations, isLoadingCanaryStatuses, canaryStatuses])

  useUpdateEffect(() => {
    if (
      isLoadingCanaryStatuses ||
      !organizations.length ||
      !canaryStatuses?.data.length
    )
      return

    updateCanaryStatuses(organizations, canaryStatuses.data)
  }, [organizations, isLoadingCanaryStatuses, canaryStatuses])

  return {
    data: organizations,
    columns: OrganizationTableColumns,
    isLoading: isFetching,
    isPricingTiersLoading,
    isDataPointsLoading,
    isPartnershipLoading,
    setPartnershipOrgIdsToFetch
  }
}
