import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import { PermissionComponents } from '@/api'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { MultiSelectAutoComplete } from '@/components/ui/multi-select-autocomplete'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/components/ui/select'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { toast } from '@/components/ui/use-toast'
import { useQueryParams } from '@/hooks'
import { useRoleTable } from '@/hooks/useRoleTable'
import { useTags, useAuth } from '@/providers'
import {
  useMutationUpdateAssignedRoleForUser,
  useMutationUpdateUser,
  useQueryGetAssignedRolesForUser,
  useQueryGetOrganization,
  useQueryGetUserById,
  useMutationUpdateUserEmail,
  useMutationDeleteUser
} from '@/services'
import { Permissions, isPermitted } from '@/utils/permission-manager'

type ReleaseChannel = 'canary' | 'stable' | 'rc' | null

export function UserDetail() {
  const { userId } = useParams<{ userId: string }>()
  const { orgId } = useQueryParams<{ orgId: string }>()
  const { userData } = useAuth()

  const { data: user, isLoading } = useQueryGetUserById(userId!, orgId, {
    enabled: !!userId && !!orgId
  })
  const [email, setEmail] = useState('')
  const changeEmailMutation = useMutationUpdateUserEmail(orgId)
  const editEmailPermitted = isPermitted(Permissions.EDIT_USER_EMAIL, userData)

  useEffect(() => {
    if (user?.email) {
      setEmail(user.email)
    }
  }, [user?.email])

  const { data: organization } = useQueryGetOrganization(
    { orgId },
    {
      enabled: Boolean(user?.organization_id)
    }
  )
  const { isFetching: isFetchingRoles, data: userRoles } =
    useQueryGetAssignedRolesForUser(
      { orgId, userId: userId! },
      {
        enabled: !!orgId && !!userId
      }
    )
  const { isLoading: isAllRolesLoading, data: allRoles } = useRoleTable(orgId)
  const { userTags: allTags, isUserTagsLoading: isAllTagsLoading } = useTags()

  const [selectedRoles, setSelectedRoles] = useState<
    PermissionComponents.Schemas.Role[]
  >([])
  const [selectedTags, setSelectedTags] = useState<string[]>(user?.tags ?? [])
  const [selectedReleaseChannel, setSelectedReleaseChannel] =
    useState<ReleaseChannel>(user?.override_release_channel ?? null)
  const [isUpdatingUser, setIsUpdatingUser] = useState(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const updateUserMutationResp = useMutationUpdateUser(orgId)
  const updateAssignedRoleForUser = useMutationUpdateAssignedRoleForUser(
    userId!,
    orgId
  )

  const deleteUserMutation = useMutationDeleteUser(orgId)

  const navigate = useNavigate()

  const handleAddNewUserTag = (item: string) => item

  const handleUserTagsSelect = (item: string) => {
    setSelectedTags((prev) => [...prev, item])
  }

  const handleUserTagsUnselect = (item: string) => {
    setSelectedTags((prevItems) =>
      prevItems.filter((selectedItem) => selectedItem !== item)
    )
  }

  const handleUserRolesSelect = (item: PermissionComponents.Schemas.Role) => {
    setSelectedRoles((prev) => [...prev, item])
  }

  const handleUserRolesUnselect = (item: PermissionComponents.Schemas.Role) => {
    setSelectedRoles((prevItems) =>
      prevItems.filter((selectedItem) => selectedItem.id !== item.id)
    )
  }

  useEffect(() => {
    if (user) {
      setSelectedTags(user?.tags ?? [])
      setSelectedReleaseChannel(user?.override_release_channel ?? null)
    }
  }, [user])

  useEffect(() => {
    if (!isAllRolesLoading && !isFetchingRoles && userRoles) {
      setSelectedRoles(
        userRoles.map(
          (role) =>
            allRoles.find(
              (r) => r.id === role
            ) as PermissionComponents.Schemas.Role
        )
      )
    }
  }, [userRoles, allRoles, isAllRolesLoading, isFetchingRoles])

  const handleUpdateUser = () => {
    setIsUpdatingUser(true)
    if (!user?.image_uri) {
      // API not allow null value
      delete user?.image_uri
    }
    const updatedRoles = selectedRoles.map((role) => role?.id)

    updateAssignedRoleForUser.mutate(updatedRoles ?? [])
    updateUserMutationResp.mutate(
      {
        ...user,
        tags: selectedTags,
        override_release_channel: selectedReleaseChannel
      },
      {
        onSuccess: () => {
          setIsUpdatingUser(false)
          toast({
            title: 'User updated successfully'
          })
        },
        onError: (err) => {
          setIsUpdatingUser(false)
          toast({
            title: 'Error',
            description: err?.['message']
          })
        }
      }
    )

    if (email !== user?.email) {
      changeEmailMutation.mutate(
        { oldEmail: user?.email!, newEmail: email },
        {
          onSuccess: () => {
            toast({
              title: 'Email updated successfully'
            })
          },
          onError: (err) => {
            toast({
              title: 'Error updating email',
              description: err?.['message']
            })
          }
        }
      )
    }
  }

  const handleDeleteUser = () => {
    if (!userId) return

    deleteUserMutation.mutate(userId, {
      onSuccess: () => {
        toast({
          title: 'User deleted successfully'
        })
        navigate('/users')
      },
      onError: (err) => {
        toast({
          title: 'Error deleting user',
          description: err?.['message']
        })
      }
    })
  }

  return (
    <>
      <Tabs className="w-full" defaultValue="settings">
        <TabsList className="grid w-full grid-cols-1">
          <TabsTrigger loading={isLoading} value="settings">
            Settings
          </TabsTrigger>
        </TabsList>
        <TabsContent value="settings">
          <div className="space-y-4">
            <div className="flex space-x-12">
              <div className="w-1/2 space-y-4">
                <div className="space-y-2">
                  <Label htmlFor="id">ID</Label>
                  <Input disabled id="id" value={user?.id} />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="display_name">Name</Label>
                  <Input
                    disabled
                    id="display_name"
                    value={user?.display_name}
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="email">Email</Label>
                  <Input
                    disabled={!editEmailPermitted}
                    id="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="status">Status</Label>
                  <Input disabled id="status" value={user?.status} />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="organizationId">Organization ID</Label>
                  <Input
                    disabled
                    id="organizationId"
                    value={organization?.id}
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="organizationName">Organization Name</Label>
                  <Input
                    disabled
                    id="organizationName"
                    value={organization?.name ?? ''}
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="customer_number">Customer Number</Label>
                  <Input
                    disabled
                    id="customer_number"
                    value={organization?.customer_number ?? ''}
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="created_at">Created At</Label>
                  <Input
                    disabled
                    id="created_at"
                    value={
                      new Date(user?.created_at as string)?.toLocaleString() ??
                      ''
                    }
                  />
                </div>
              </div>
              <div className="w-1/2 space-y-4">
                <div className="space-y-2">
                  <Label>Tags</Label>
                  <MultiSelectAutoComplete
                    allowNewValues
                    isLoading={isAllTagsLoading}
                    items={allTags || []}
                    onNewItem={(newItem) => handleAddNewUserTag(newItem)}
                    onSelect={(item) => handleUserTagsSelect(item as string)}
                    onUnselect={(item) =>
                      handleUserTagsUnselect(item as string)
                    }
                    placeholder="Choose the tags to assign to the user"
                    selectedItems={selectedTags}
                  />
                </div>
                <div className="space-y-2">
                  <Label>Roles</Label>
                  <MultiSelectAutoComplete
                    isLoading={isAllRolesLoading || isFetchingRoles}
                    items={allRoles || []}
                    labelKey="name"
                    onSelect={(item) =>
                      handleUserRolesSelect(
                        item as PermissionComponents.Schemas.Role
                      )
                    }
                    onUnselect={(item) =>
                      handleUserRolesUnselect(
                        item as PermissionComponents.Schemas.Role
                      )
                    }
                    placeholder="Choose the roles to assign to the user"
                    selectedItems={selectedRoles}
                    valueKey="id"
                  />
                </div>
                <div className="space-y-2">
                  <Label>Override Release Channel</Label>
                  <Select
                    onValueChange={(e) => {
                      setSelectedReleaseChannel(
                        e === 'default' ? null : (e as ReleaseChannel)
                      )
                    }}
                    value={selectedReleaseChannel ?? 'default'}
                  >
                    <SelectTrigger
                      className={classNames({
                        'text-primary': selectedReleaseChannel,
                        'text-placeholder': !selectedReleaseChannel
                      })}
                    >
                      <SelectValue placeholder="Select a release channel" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value={'default'}>
                        Organization Default
                      </SelectItem>
                      <SelectItem value={'canary'}>Canary</SelectItem>
                      <SelectItem value={'stable'}>Stable</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
              </div>
            </div>
            <div className="w-full flex gap-4">
              <Button
                loading={isUpdatingUser}
                onClick={() => handleUpdateUser()}
                type="submit"
              >
                Update User
              </Button>
              {isPermitted(Permissions.DELETE_USER, userData) && (
                <Button
                  disabled={deleteUserMutation.isPending}
                  onClick={() => setShowDeleteDialog(true)}
                  variant="destructive"
                >
                  Delete User
                </Button>
              )}
            </div>
          </div>
        </TabsContent>
      </Tabs>

      <Dialog onOpenChange={setShowDeleteDialog} open={showDeleteDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Are you absolutely sure?</DialogTitle>
            <DialogDescription>
              This action cannot be undone. This will permanently delete the
              user.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button
              onClick={() => setShowDeleteDialog(false)}
              variant="outline"
            >
              Cancel
            </Button>
            <Button
              loading={deleteUserMutation?.isPending}
              onClick={handleDeleteUser}
              variant="destructive"
            >
              Delete
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  )
}
