// get children from props export setJSON, onToggle

import React, { useState } from 'react'

type UseJSONEditor = {
  originalData: unknown
  setOriginalData: React.Dispatch<React.SetStateAction<unknown>>
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  dataState: unknown
  setDataState: React.Dispatch<React.SetStateAction<unknown>>
}

export const useJSONEditor = (): UseJSONEditor => {
  const [originalData, setOriginalData] = useState<unknown>()
  const [open, setOpen] = useState(false)
  const [dataState, setDataState] = useState<unknown>()

  return {
    originalData,
    setOriginalData,
    open,
    setOpen,
    dataState,
    setDataState
  }
}
