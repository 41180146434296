import { ChevronDownIcon } from 'lucide-react'
import { Link } from 'react-router-dom'

import { EntityComponents } from '@/api'
import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import { DataTableColumnHeader } from '@/components/ui/data-table-column-header'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu'
import { TableColumn } from '@/models'
import { RoutePaths } from '@/routes/routes'
import { unsensitiveFilter } from '@/utils'

export const PartnerTableColumns: TableColumn<EntityComponents.Schemas.EntityItem>[] =
  [
    {
      id: 'select',
      header: ({ table }) => (
        <div className="flex items-center space-x-2">
          <Checkbox
            aria-label="Select all in the page"
            checked={table.getIsAllPageRowsSelected()}
            onCheckedChange={(value) =>
              table.toggleAllPageRowsSelected(!!value)
            }
          />
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <div className="cursor-pointer">
                <ChevronDownIcon className="h-4 w-4" />
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56 flex items-center">
              <Checkbox
                aria-label="Select all"
                checked={table.getIsAllRowsSelected()}
                className="mr-2"
                id="select-all"
                onCheckedChange={(value) =>
                  table.toggleAllRowsSelected(!!value)
                }
              />
              <span>Select all items</span>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      ),
      cell: ({ row }) => (
        <Checkbox
          aria-label="Select row"
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
        />
      ),
      enableSorting: false,
      enableHiding: false,
      enableResizing: false,
      size: 40,
      enableGlobalFilter: false,
      enableColumnFilter: false
    },
    {
      id: 'partner_org_id',
      displayName: 'partner_org_id',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="ID" />
      ),
      cell: ({ row }) => <span>{row.original.partner_org_id}</span>,
      enableResizing: false,
      size: 60,
      filterFn: unsensitiveFilter,
      sortingFn: (a, b) => {
        const idA = a.original.id
        const idB = b.original.id

        if (!idA && !idB) {
          return 0
        } else if (!idA) {
          return 1
        } else if (!idB) {
          return -1
        }

        return parseInt(idA) - parseInt(idB)
      }
    },
    {
      id: '_title',
      accessorKey: '_title',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Name" />
      ),
      cell: ({ row }) => (
        <Button className="m-0 p-0" variant={'link'}>
          <Link
            target="_blank"
            to={`${RoutePaths.ORGANIZATIONS}/${row.original.partner_org_id}`}
          >
            {row.original._title}
          </Link>
        </Button>
      ),
      displayName: '_title',
      size: 300,
      enableResizing: true,
      sortUndefined: 1,
      filterFn: unsensitiveFilter
    },
    {
      id: 'actual_users',
      accessorKey: 'actual_users',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Actual Users" />
      ),
      displayName: 'actual_users',
      sortUndefined: 1,
      filterFn: unsensitiveFilter
    },
    {
      id: 'max_users',
      accessorKey: 'max_users',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Max. Users Last Month" />
      ),
      displayName: 'max_users',
      enableResizing: true,
      sortUndefined: 1,
      filterFn: unsensitiveFilter
    }
  ]
