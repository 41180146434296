type JourneyAnalyticsProps = {
  orgId: string
}

const JourneyAnalytics = (props: JourneyAnalyticsProps) => {
  const { orgId } = props

  return (
    <div style={{ height: 'calc(100vh - 5rem)' }}>
      <iframe
        allowFullScreen={true}
        height={'100%'}
        src={`https://app.powerbi.com/reportEmbed?reportId=6e748e50-dfc4-43b2-9d9f-904365903d78&autoAuth=true&ctid=33d4f3e5-3df2-421e-b92e-a63cfa680a88&filter=journey_sessions_prod/org_id eq '${orgId}'`}
        title="journey_analytics"
        width={'100%'}
      />
    </div>
  )
}

export default JourneyAnalytics
