import {
  CheckCircledIcon,
  CrossCircledIcon,
  StopwatchIcon
} from '@radix-ui/react-icons'

export const Status = [
  {
    value: 'Active',
    label: 'Active',
    icon: CheckCircledIcon
  },
  {
    value: 'Pending',
    label: 'Pending',
    icon: StopwatchIcon
  },
  {
    value: 'Deactivated',
    label: 'Deactivated',
    icon: CrossCircledIcon
  },
  {
    value: 'Inactive',
    label: 'Inactive',
    icon: CrossCircledIcon
  }
]
