import { Cross2Icon } from "@radix-ui/react-icons";
import { Table } from "@tanstack/react-table";
import React, { useEffect } from "react";
import { CSVLink } from "react-csv";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { DataTableViewOptions } from "./data-table-view-options";
import { toast } from "./use-toast";

import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu';
import { Input } from "@/components/ui/input";
import { useQueryClient } from "@tanstack/react-query";
import { getBlueprintClient } from "../../api/blueprint-client";
import { Blueprint, BLUEPRINT_QUERY_IDS, useQueryGetAvailableBlueprints } from "../../services/blueprint.service";

interface DataTableToolbarProps<TData> {
  table: Table<TData>;
  filterPlaceholder?: string;
  exportButtonProps?: React.ComponentProps<typeof Button> & {
    loading?: boolean;
  };
  showViewButton?: boolean;
  allowCreate?: boolean;
  allowInstall?: boolean;
  initialInput: string;
}

export function DataTableToolbar<TData>({
  table,
  exportButtonProps,
  allowCreate,
  allowInstall,
  initialInput,
  filterPlaceholder = "Search...",
  showViewButton = true,
}: DataTableToolbarProps<TData>) {
  const location = useLocation();
  const navigate = useNavigate();
  const [input, setInput] = React.useState(initialInput);
  const isFiltered = table.getState().columnFilters.length > 0 || input;
  const csvLinkRef = React.useRef<any>(null);

  useEffect(() => {
    setInput(initialInput);
  }, [initialInput]);

  const handleExportCSV = () => {
    table.getFilteredSelectedRowModel().rows.length > 0
      ? csvLinkRef.current?.link.click()
      : toast({
        title: "No data to export",
        description: "Please select at least one row to export.",
        variant: "destructive",
      });
  };

  const orgId = useParams().orgId || "";
  const availableBlueprintsQuery = useQueryGetAvailableBlueprints(orgId);

  const queryClient = useQueryClient()

  const triggerBlueprintInstall = async (blueprint: Blueprint) => {
    const blueprintClient = getBlueprintClient();

    await blueprintClient.adminInstallBlueprint({
      id: blueprint.id,
    }, null, { headers: { 'x-ivy-org-id': orgId } })

    toast({
      title: 'Blueprint installed',
      description: `Triggered blueprint installation for blueprint ${blueprint.slug}`
    })

    queryClient.invalidateQueries({ queryKey: [BLUEPRINT_QUERY_IDS.GET_INSTALLED_BLUEPRINTS] })
  }

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center flex-1 space-x-2">
        <Input
          className="h-8 w-[150px] lg:w-[250px]"
          onChange={(event) => {
            setInput(event.target.value);
            table.setGlobalFilter(event.target.value);
          }}
          placeholder={filterPlaceholder}
          value={input}
        />
        {isFiltered && (
          <Button
            className="h-8 px-2 lg:px-3"
            onClick={() => {
              table.resetGlobalFilter();
              table.resetColumnFilters();
              setInput("");
            }}
            variant="ghost"
          >
            Reset
            <Cross2Icon className="w-4 h-4 ml-2" />
          </Button>
        )}
      </div>
      <div className="flex items-center space-x-2">
        <CSVLink
          className="hidden"
          data={table.getFilteredSelectedRowModel().rows.map((row) => {
            let tmp = {};

            row.getVisibleCells().forEach((cell) => {
              const column = cell.column;

              if (column.id !== "select" && column.id !== "actions") {
                tmp = {
                  ...tmp,
                  [column.id]: cell.getValue(),
                };
              }
            });

            return tmp;
          })}
          filename={`${location.pathname.split("/")[1]
            }-${new Date().toISOString()}.csv`}
          ref={csvLinkRef}
          target="_blank"
        />
        {allowCreate && (
          <Button
            className="h-8"
            onClick={() => navigate(`${location.pathname}/create`)}
            variant={"outline"}
          >
            Create
          </Button>
        )}
        <Button
          className="h-8"
          onClick={handleExportCSV}
          variant={"outline"}
          {...exportButtonProps}
        >
          Export
        </Button>
        {allowInstall && (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                className="h-8"
                onClick={() => null}
                variant={"outline"}
              >
                Install Blueprint
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {availableBlueprintsQuery.data?.map((blueprint) => (
                <DropdownMenuItem onClick={() => triggerBlueprintInstall(blueprint)}>
                  {blueprint.name} ({blueprint.slug})
                </DropdownMenuItem>))}
            </DropdownMenuContent>
          </DropdownMenu>
        )}
        {showViewButton && <DataTableViewOptions table={table} />}
      </div>
    </div>
  );
}
