import { startCase } from 'lodash'
import { ChevronDownIcon } from 'lucide-react'

import { PermissionComponents } from '@/api'
import { Checkbox } from '@/components/ui/checkbox'
import { DataTableColumnHeader } from '@/components/ui/data-table-column-header'
import { DataTableRowActions } from '@/components/ui/data-table-row-actions'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu'
import { TableColumn } from '@/models'
import { unsensitiveFilter } from '@/utils'

export const RoleAndPermissionTableColumns: TableColumn<PermissionComponents.Schemas.Role>[] =
  [
    {
      id: 'select',
      header: ({ table }) => (
        <div className="flex items-center space-x-2">
          <Checkbox
            aria-label="Select all in the page"
            checked={table.getIsAllPageRowsSelected()}
            onCheckedChange={(value) =>
              table.toggleAllPageRowsSelected(!!value)
            }
          />
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <div className="cursor-pointer">
                <ChevronDownIcon className="h-4 w-4" />
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56 flex items-center">
              <Checkbox
                aria-label="Select all"
                checked={table.getIsAllRowsSelected()}
                className="mr-2"
                id="select-all"
                onCheckedChange={(value) =>
                  table.toggleAllRowsSelected(!!value)
                }
              />
              <span>Select all items</span>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      ),
      cell: ({ row }) => (
        <Checkbox
          aria-label="Select row"
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
        />
      ),
      enableSorting: false,
      enableHiding: false,
      enableResizing: false,
      size: 40,
      enableGlobalFilter: false,
      enableColumnFilter: false
    },
    {
      id: 'id',
      accessorKey: 'id',
      displayName: 'ID',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="ID" />
      ),
      enableResizing: false,
      size: 60,
      filterFn: unsensitiveFilter
    },
    {
      id: 'Name',
      accessorKey: 'name',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Name" />
      ),
      displayName: 'Name',
      size: 300,
      enableResizing: true,
      sortUndefined: 1,
      filterFn: unsensitiveFilter
    },
    {
      id: 'type',
      accessorKey: 'type',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Type" />
      ),
      cell: ({ row }) => {
        return startCase(row.getValue('type'))
      },
      displayName: 'Type',
      sortUndefined: 1,
      filterFn: unsensitiveFilter
    },
    {
      id: 'actions',
      cell: ({ row }) => {
        return <DataTableRowActions row={row} />
      },
      enableSorting: false,
      enableHiding: false,
      enableResizing: false,
      size: 30
    }
  ]
