import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions
} from '@tanstack/react-query'
import { chain } from 'lodash'

import { getPermissionsClient } from '@/api'
import { PermissionComponents } from '@/api/permissions-client'
import { IUser } from '@/models'

export enum PERMISSIONS_QUERY_IDS {
  LIST_ALL_ROLES = 'LIST_ALL_ROLES',
  LIST_ALL_ROLES_BY_ORG_IDS = 'LIST_ALL_ROLES_BY_ORG_IDS',
  GET_ASSIGNED_ROLES_FOR_USER = 'GET_ASSIGNED_ROLES_FOR_USER',
  GET_ASSIGNED_ROLES_FOR_USERS = 'GET_ASSIGNED_ROLES_FOR_USERS',
  LIST_ALL_ASSIGNMENTS = 'LIST_ALL_ASSIGNMENTS',
  GET_ALL_ASSIGNMENTS_ALL_ORGS = 'GET_ALL_ASSIGNMENTS_ALL_ORGS',
  GET_ALL_ROLES_ALL_ORGS = 'GET_ALL_ROLES_ALL_ORGS',
  UPDATE_ASSIGNED_ROLES_FOR_USER = 'UPDATE_ASSIGNED_ROLES_FOR_USER'
}

const client = getPermissionsClient()

export const useQueryGetAllAssignmentsAllOrgs: (
  opts?: UseQueryOptions<Awaited<ReturnType<typeof client.getAllAssignments>>>
) => ReturnType<
  typeof useQuery<Awaited<ReturnType<typeof client.getAllAssignments>>>
> = (opts) => {
  return useQuery<Awaited<ReturnType<typeof client.getAllAssignments>>>({
    queryFn: () => client.getAllAssignments(),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...opts,
    queryKey: [PERMISSIONS_QUERY_IDS.GET_ALL_ASSIGNMENTS_ALL_ORGS]
  })
}

export const useQueryGetAllRolesAllOrgs: (
  opts?: UseQueryOptions<PermissionComponents.Schemas.OrgRoles[]>
) => ReturnType<typeof useQuery<PermissionComponents.Schemas.OrgRoles[]>> = (
  opts
) => {
  const fetchAllRoles = async () => {
    const limit = '1000'
    const ungroupedRoles: PermissionComponents.Schemas.Role[] = []
    let lastEvaluatedKey: { pk?: string; sk?: string } | undefined

    do {
      const { roles: fetchedRoles, lastEvaluatedKey: _lastEvaluatedKey } =
        await fetchRoles(limit, JSON.stringify(lastEvaluatedKey))

      fetchedRoles?.forEach((orgRole) => {
        ungroupedRoles.push(...(orgRole.roles ?? []))
      })

      lastEvaluatedKey = _lastEvaluatedKey

      if (!fetchedRoles?.length) {
        break
      }
    } while (lastEvaluatedKey)

    const orgRoles: PermissionComponents.Schemas.OrgRoles[] = chain(
      ungroupedRoles
    )
      .groupBy('organization_id')
      .map((value: PermissionComponents.Schemas.Role[], key: string) => ({
        organizationId: key,
        roles: [...value]
      }))
      .value()

    return orgRoles
  }

  const fetchRoles = async (limit: string, startKey: string) => {
    return client
      .getAllRoles({ limit, ...(startKey && { start_key: startKey }) })
      .then((res) => res.data)
  }

  return useQuery<PermissionComponents.Schemas.OrgRoles[]>({
    queryKey: [PERMISSIONS_QUERY_IDS.GET_ALL_ROLES_ALL_ORGS],
    queryFn: fetchAllRoles,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: false,
    ...opts
  })
}

export const useQueryGetAllAssignments: (
  orgId: string,
  opts?: UseQueryOptions<Awaited<ReturnType<typeof client.listAllAssignments>>>
) => ReturnType<
  typeof useQuery<Awaited<ReturnType<typeof client.listAllAssignments>>>
> = (orgId, opts) => {
  return useQuery<Awaited<ReturnType<typeof client.listAllAssignments>>>({
    queryKey: [PERMISSIONS_QUERY_IDS.LIST_ALL_ASSIGNMENTS, orgId],
    queryFn: () =>
      client.listAllAssignments({}, {}, { headers: { 'x-ivy-org-id': orgId } }),
    refetchOnWindowFocus: true,
    ...opts
  })
}

export const useQueryGetAllRoles: (
  { orgId }: { orgId: string },
  options?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof client.listAllRoles>>>
  >
) => ReturnType<
  typeof useQuery<Awaited<ReturnType<typeof client.listAllRoles>>>
> = ({ orgId }, options) => {
  return useQuery<Awaited<ReturnType<typeof client.listAllRoles>>>({
    queryKey: [PERMISSIONS_QUERY_IDS.LIST_ALL_ROLES, orgId],
    queryFn: () =>
      client.listAllRoles({}, {}, { headers: { 'x-ivy-org-id': orgId } }),
    refetchOnWindowFocus: false,
    retry: false,
    ...options
  })
}

export const useQueryGetAssignedRolesForUser: (
  { userId, orgId }: { userId: string; orgId: string },
  options?: Partial<UseQueryOptions<PermissionComponents.Schemas.Assignments>>
) => ReturnType<typeof useQuery<PermissionComponents.Schemas.Assignments>> = (
  { userId, orgId },
  options
) => {
  return useQuery<PermissionComponents.Schemas.Assignments>({
    queryKey: [
      PERMISSIONS_QUERY_IDS.GET_ASSIGNED_ROLES_FOR_USER,
      userId,
      orgId
    ],
    queryFn: () =>
      client
        .getAssignedRolesForUser(
          { userId },
          {},
          { headers: { 'x-ivy-org-id': orgId } }
        )
        .then((res) => res.data),
    refetchOnWindowFocus: false,
    retry: false,
    ...options
  })
}

export const useQueryGetAssignedRolesForUsers: (
  users: IUser[],
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof client.getAssignedRolesForUser>>[]
  >
) => ReturnType<
  typeof useQuery<Awaited<ReturnType<typeof client.getAssignedRolesForUser>>[]>
> = (users, options) => {
  return useQuery<Awaited<ReturnType<typeof client.getAssignedRolesForUser>>[]>(
    {
      queryKey: [PERMISSIONS_QUERY_IDS.GET_ASSIGNED_ROLES_FOR_USERS, users],
      queryFn: () =>
        Promise.all(
          users.map((user) =>
            client.getAssignedRolesForUser(
              { userId: user.id! },
              {},
              { headers: { 'x-ivy-org-id': user.organization_id! } }
            )
          )
        ),
      refetchOnWindowFocus: false,
      retry: false,
      enabled: users.length > 0,
      ...options
    }
  )
}

export const useQueryGetAllRolesByOrgIds: (
  orgIds: string[],
  options?: UseQueryOptions<Awaited<ReturnType<typeof client.listAllRoles>>[]>
) => ReturnType<
  typeof useQuery<Awaited<ReturnType<typeof client.listAllRoles>>[]>
> = (orgIds, options) => {
  return useQuery<Awaited<ReturnType<typeof client.listAllRoles>>[]>({
    queryKey: [PERMISSIONS_QUERY_IDS.LIST_ALL_ROLES_BY_ORG_IDS, orgIds],
    queryFn: () =>
      Promise.all(
        orgIds.map((orgId) =>
          client.listAllRoles({}, {}, { headers: { 'x-ivy-org-id': orgId } })
        )
      ),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: orgIds?.length > 0,
    ...options
  })
}

export const useMutationUpdateAssignedRoleForUser: (
  userId: string,
  orgId: string
) => ReturnType<
  typeof useMutation<
    Awaited<ReturnType<typeof client.assignRoles>>,
    unknown,
    PermissionComponents.Schemas.Assignments,
    unknown
  >
> = (userId, orgId) => {
  const queryClient = useQueryClient()

  return useMutation<
    Awaited<ReturnType<typeof client.assignRoles>>,
    unknown,
    PermissionComponents.Schemas.Assignments,
    unknown
  >({
    mutationFn: (assignments: PermissionComponents.Schemas.Assignments) =>
      client.assignRoles({ userId }, assignments, {
        headers: { 'x-ivy-org-id': orgId }
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [PERMISSIONS_QUERY_IDS.GET_ASSIGNED_ROLES_FOR_USER]
      })
    }
  })
}

export const useMutationUpdateRole: (
  roleId: string,
  orgId: string
) => ReturnType<
  typeof useMutation<
    Awaited<ReturnType<typeof client.putRole>>,
    unknown,
    PermissionComponents.Schemas.RolePayload,
    unknown
  >
> = (roleId, orgId) => {
  const queryClient = useQueryClient()

  return useMutation<
    Awaited<ReturnType<typeof client.putRole>>,
    unknown,
    PermissionComponents.Schemas.RolePayload,
    unknown
  >({
    mutationFn: (payload: PermissionComponents.Schemas.RolePayload) =>
      client.putRole({ roleId }, payload, {
        headers: { 'x-ivy-org-id': orgId }
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [PERMISSIONS_QUERY_IDS.LIST_ALL_ROLES]
      })
    }
  })
}
