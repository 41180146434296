import { difference, uniq } from 'lodash'
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react'
import { useUpdateEffect } from 'react-use'

import { useDataPoints } from './DataPointContextProvider'

import { PartnerEntity, PartnershipDataPoint } from '@/models'
import { useQueryGetAllActivePartners } from '@/services'

export interface PartnershipContext {
  isLoading: boolean
  setPartnershipOrgIdsToFetch: React.Dispatch<React.SetStateAction<string[]>>
  partnershipDataPoints: PartnershipDataPoint[]
}

const PartnershipContext = createContext<PartnershipContext>({
  isLoading: false,
  setPartnershipOrgIdsToFetch: () => [],
  partnershipDataPoints: []
})

export const PartnershipContextProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [partnershipDataPoints, setPartnershipDataPoints] = useState<
    PartnershipDataPoint[]
  >([])
  const [partnershipOrgIdsToFetch, setPartnershipOrgIdsToFetch] = useState<
    string[]
  >([])
  const [cachedPartnershipOrgIds, setCachedPartnershipOrgIds] = useState<
    string[]
  >([])
  const { dataPoints } = useDataPoints()

  const orgIdsToFetch = useMemo(
    () => uniq(difference(partnershipOrgIdsToFetch, cachedPartnershipOrgIds)),
    [cachedPartnershipOrgIds, partnershipOrgIdsToFetch]
  )

  const calculateDataPointForOrg = useCallback(
    (results: PartnerEntity[], orgId: string) => {
      const partnershipDataPoint: PartnershipDataPoint = {
        active_partners: results?.length || 0,
        actual_partner_users: 0,
        max_partner_users: 0,
        org_id: parseInt(orgId)
      }

      results.map((partnership) => {
        const dataPoint = dataPoints.find(
          (x) => x.org_id === parseInt(partnership.partner_org_id)
        )

        if (partnership.partner_org_id && dataPoint) {
          partnershipDataPoint.actual_partner_users +=
            dataPoint.actual_users ?? 0
          partnershipDataPoint.max_partner_users +=
            dataPoint.max_users_last_month ?? 0
        }
      })

      return partnershipDataPoint
    },
    [dataPoints]
  )

  const getAllPartnershipsByOrgIdsQuery =
    useQueryGetAllActivePartners(orgIdsToFetch)

  useUpdateEffect(() => {
    if (
      getAllPartnershipsByOrgIdsQuery.data &&
      !getAllPartnershipsByOrgIdsQuery.isLoading
    ) {
      const tempPartnershipDataPoints = [...partnershipDataPoints]

      for (const response of getAllPartnershipsByOrgIdsQuery.data) {
        const results = response?.data?.results

        if (results?.length) {
          const orgId = results[0]._org
          const partnershipDataPoint = calculateDataPointForOrg(
            results as unknown as PartnerEntity[],
            orgId
          )

          tempPartnershipDataPoints.push(partnershipDataPoint)
        }
      }

      setPartnershipDataPoints(tempPartnershipDataPoints)
      setCachedPartnershipOrgIds((prevState) =>
        uniq([
          ...prevState,
          ...difference(partnershipOrgIdsToFetch, cachedPartnershipOrgIds)
        ])
      )
    }
  }, [
    cachedPartnershipOrgIds,
    calculateDataPointForOrg,
    getAllPartnershipsByOrgIdsQuery,
    partnershipDataPoints,
    partnershipOrgIdsToFetch
  ])

  return (
    <PartnershipContext.Provider
      value={{
        isLoading: getAllPartnershipsByOrgIdsQuery.isLoading,
        setPartnershipOrgIdsToFetch,
        partnershipDataPoints
      }}
    >
      {children}
    </PartnershipContext.Provider>
  )
}

export const usePartnership = () => useContext(PartnershipContext)
