import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { PricingTierSettings } from './columns'

import { PricingTierInUseOrganizations } from '@/components/pricing-tier-in-use-organizations'
import { PricingTierRootRolePreview } from '@/components/pricing-tier-rool-role-preview'
import PricingTierAdvancedSettings from '@/components/pricing-tier-settings'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { toast } from '@/components/ui/use-toast'
import { usePricingTiers } from '@/providers'
import { useAuth } from '@/providers/AuthContextProvider'
import {
  useMutationUpdatePricingTier,
  useQueryGetPricingTierById
} from '@/services'

const PricingTierDetail = () => {
  const { pricingTierId } = useParams<{ pricingTierId: string }>()
  const { data: pricingTier, isLoading } = useQueryGetPricingTierById(
    pricingTierId ?? '',
    {
      enabled: Boolean(pricingTierId)
    }
  )
  const { pricingTierAssignments } = usePricingTiers()

  const { userData } = useAuth()

  const [pricingTierSettings, setPricingTierSettings] = useState<{
    [key: string]: { enabled: boolean }
  }>({})
  const [pricingTierName, setPricingTierName] = useState('')
  const [isButtonLoading, setIsButtonLoading] = useState(false)

  const updatePricingTier = useMutationUpdatePricingTier()

  const getPricingTierInUse = () => {
    return (
      pricingTierAssignments?.filter(
        (p) => p?.pricing_tier_id == pricingTier?.id
      )?.length || 0
    )
  }

  const handleUpdatePricingTier = () => {
    setIsButtonLoading(true)
    updatePricingTier.mutate(
      {
        ...pricingTier,
        name: pricingTierName,
        settings: pricingTierSettings,
        updated_by: userData?.attributes?.email
      },
      {
        onSuccess: () => {
          setIsButtonLoading(false)
          toast({
            title: 'Success',
            description: 'Pricing tier updated successfully.'
          })
        },
        onError: (err) => {
          setIsButtonLoading(false)
          toast({
            title: 'Error',
            description: `Error while updating pricing tier. ${err?.['message']}`
          })
        }
      }
    )
  }

  useEffect(() => {
    if (pricingTier) {
      setPricingTierName(pricingTier?.name ?? '')
      setPricingTierSettings(pricingTier?.settings ?? {})
    }
  }, [pricingTier])

  return (
    <>
      <Tabs className="w-full" defaultValue="settings">
        <TabsList className="grid w-full grid-cols-2">
          <TabsTrigger loading={isLoading} value="settings">
            Settings
          </TabsTrigger>
          <TabsTrigger loading={isLoading} value="usage">
            Usage
          </TabsTrigger>
        </TabsList>
        <TabsContent value="settings">
          <div className="space-y-4">
            <div className="flex flex-row justify-between space-x-12">
              <div className="w-full space-y-4">
                <div className="space-y-2">
                  <Label htmlFor="id">ID</Label>
                  <Input disabled id="id" value={pricingTier?.id || ''} />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="name">ID</Label>
                  <Input
                    id="name"
                    onChange={(e) => setPricingTierName(e.target.value)}
                    placeholder="Expensive one..."
                    value={pricingTierName}
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="created_date">Created Date</Label>
                  <Input
                    disabled
                    id="created_date"
                    value={
                      new Date(
                        pricingTier?.created_date as string
                      )?.toLocaleString() ?? ''
                    }
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="updated_date">Updated Date</Label>
                  <Input
                    disabled
                    id="updated_date"
                    value={
                      new Date(
                        pricingTier?.updated_date as string
                      )?.toLocaleString() ?? ''
                    }
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="created_by">Created By</Label>
                  <Input
                    disabled
                    id="created_by"
                    value={pricingTier?.created_by || ''}
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="updated_by">Updated By</Label>
                  <Input
                    disabled
                    id="updated_by"
                    value={pricingTier?.updated_by || ''}
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="in_use">In Use</Label>
                  <Input disabled id="in_use" value={getPricingTierInUse()} />
                </div>
                <div className="flex flex-row gap-x-5">
                  <div className="w-full">
                    <PricingTierAdvancedSettings
                      onChange={(e, key) => {
                        setPricingTierSettings({
                          ...pricingTierSettings,
                          [key]: e
                        })
                      }}
                      options={PricingTierSettings.filter(
                        (x) => x.key !== 'name'
                      ).map((x) => ({
                        ...x,
                        value: pricingTierSettings[x.key]
                      }))}
                    />
                  </div>
                  <div className="w-full">
                    <PricingTierRootRolePreview
                      pricingTier={{
                        ...pricingTier!,
                        settings: pricingTierSettings,
                        name: pricingTierName
                      }}
                    />
                  </div>
                </div>

                <Button
                  loading={isButtonLoading}
                  onClick={() => handleUpdatePricingTier()}
                >
                  Update Pricing Tier
                </Button>
              </div>
            </div>
          </div>
        </TabsContent>
        <TabsContent className="text-center" value="usage">
          {getPricingTierInUse() > 0 ? (
            <PricingTierInUseOrganizations pricingTierId={pricingTierId!} />
          ) : (
            <h3 className="text-sm text-primary">
              No organizations are using this pricing tier.
            </h3>
          )}
        </TabsContent>
      </Tabs>
    </>
  )
}

export default PricingTierDetail
