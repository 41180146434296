import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'

import { getUserClient, UserPaths } from '@/api/user-client'
import { IUser } from '@/models'
import { UserTableColumns } from '@/modules/user/columns'
import { useRoleAndPermission } from '@/providers'
import { useQueryGetOrganizations, USER_QUERY_IDS } from '@/services'

export const useUserTable = ({
  orgId,
  limit,
  page,
  search
}: {
  orgId?: string
  limit?: number
  page?: number
  search?: string
}) => {
  const [users, setUsers] = useState<IUser[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const userClient = getUserClient()

  const { data, isFetched } = useQuery<UserPaths.GetUsersV2.Responses.$200>({
    queryKey: [USER_QUERY_IDS.GET_USERS, orgId, limit, page, search],
    queryFn: async () =>
      userClient
        .getUsersV2({
          org_id: orgId,
          limit: limit,
          page: page,
          search
        })
        .then((res) => res.data),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false
  })

  const { data: orgsQueryResp, isFetched: isOrgsFetched } =
    useQueryGetOrganizations()

  const {
    allAssignmentsAllOrgs,
    allRolesAllOrgs,
    isReadyExport,
    loadingGetAllRolesOrgIds,
    loadingGetAssignedRoleUsers
  } = useRoleAndPermission()

  useEffect(() => {
    if (
      isFetched &&
      isOrgsFetched &&
      !loadingGetAllRolesOrgIds &&
      !loadingGetAllRolesOrgIds
    ) {
      const tempUsers =
        data?.results?.map((user) => {
          const orgAssignments = allAssignmentsAllOrgs?.find(
            (assignment) => assignment.organizationId === user.organization_id
          )?.assignments

          const userAssignments = orgAssignments?.find(
            (assignment) => assignment.userId === user.id
          )

          const orgRoles = allRolesAllOrgs?.find(
            (role) => role.organizationId === user.organization_id
          )?.roles

          const userRoles = orgRoles?.filter((role) =>
            userAssignments?.roles?.includes(role.id)
          )

          return {
            ...user,
            organization:
              orgsQueryResp?.find((org) => org?.id === user?.organization_id) ??
              null,
            roles: userRoles
          }
        }) || []

      if (orgId) {
        const orgUsers =
          tempUsers?.filter((user) => user.organization_id === orgId) || []

        setUsers(orgUsers as IUser[])
        setIsLoading(false)

        return
      }

      setUsers(tempUsers as IUser[])
      setIsLoading(false)
    }
  }, [
    data,
    isFetched,
    isOrgsFetched,
    orgId,
    orgsQueryResp,
    loadingGetAllRolesOrgIds,
    loadingGetAssignedRoleUsers,
    allAssignmentsAllOrgs,
    allRolesAllOrgs
  ])

  return {
    data: users,
    totalCount: data?.total_count,
    page: data?.page,
    totalPages: data?.total_pages,
    isLoading: isLoading,
    isRolesLoading: loadingGetAllRolesOrgIds || loadingGetAssignedRoleUsers,
    columns: UserTableColumns,
    isReadyExport
  }
}
