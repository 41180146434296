import { getClient } from '@epilot/marketplace-cms-client-internal'
import { useMemo } from 'react'

import { config } from '../configs/api.config'

import { configureClient } from './api-utils'

let client: ReturnType<typeof getClient>

export const getMarketplaceCMSClient = () => {
  if (!client) {
    client = getClient()

    configureClient(client, {
      baseURL: config.MARKETPLACE_CMS
    })
  }

  return client
}

export const useMarketplaceCMSClient = () =>
  useMemo(() => getMarketplaceCMSClient(), [])

export type {
  Components as MarketplaceCMSComponents,
  Paths as MarketplaceCMSPaths
} from '@epilot/marketplace-cms-client-internal'
