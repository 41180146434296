import { Role } from '@epilot/permissions/types'
import { useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useState } from 'react'

import { getPermissionsClient } from '@/api'
import { SheetJSONEditor } from '@/components/sheet-json-editor'
import { DataTable } from '@/components/ui/data-table'
import { toast } from '@/components/ui/use-toast'
import { useJSONEditor } from '@/hooks/useJSONEditor'
import { useRoleTable } from '@/hooks/useRoleTable'
import { PERMISSIONS_QUERY_IDS } from '@/services'

type RoleListProps = {
  organizationId?: string
  disabled?: boolean
}

const RoleList = ({ organizationId, disabled = false }: RoleListProps) => {
  const { columns, data, isLoading } = useRoleTable(organizationId)
  const [isUpdating, setIsUpdating] = useState(false)
  const jsonEditorProps = useJSONEditor()

  const permissionsClient = getPermissionsClient()
  const queryClient = useQueryClient()

  const handleUpdateRole = async (dataState: Role | unknown) => {
    if (dataState && organizationId) {
      try {
        setIsUpdating(true)
        await permissionsClient.putRole(
          { roleId: (dataState as Role)?.id },
          dataState as Role,
          {
            headers: { 'x-ivy-org-id': organizationId }
          }
        )
        jsonEditorProps.setOriginalData(dataState)
        toast({
          title: 'Role updated',
          description: `Role "${(dataState as Role)?.name}" has been updated.`
        })
      } catch (error) {
        const errorMessage =
          axios.isAxiosError(error) && error.response?.data?.error
            ? error.response.data?.error
            : error

        toast({
          title: 'Error',
          description: `Error while updating role. ${errorMessage}`
        })
      } finally {
        setIsUpdating(false)
        queryClient.invalidateQueries({
          queryKey: [PERMISSIONS_QUERY_IDS.LIST_ALL_ROLES]
        })
      }
    }
  }

  return (
    <>
      <DataTable
        columns={columns}
        data={data}
        isLoading={isLoading}
        onRowClick={(row) => {
          jsonEditorProps.setOriginalData(row.original)
          jsonEditorProps.setOpen(true)
        }}
        tableConfigName="role"
      />
      <SheetJSONEditor
        {...jsonEditorProps}
        disabled={disabled}
        isUpdating={isUpdating}
        onUpdate={handleUpdateRole}
      />
    </>
  )
}

export default RoleList
