import { DataTable } from '@/components/ui/data-table'
import { useInstalledBlueprintsTable } from '@/hooks/useInstalledBlueprintsTable'

type BlueprintListProps = {
  organizationId?: string
  disabled?: boolean
}

const BlueprintsList = ({ organizationId }: BlueprintListProps) => {
  const { columns, data, isLoading } =
    useInstalledBlueprintsTable(organizationId)

  return (
    <>
      <DataTable
        columns={columns}
        data={data}
        isLoading={isLoading}
        tableConfigName="Blueprint"
        toolbarProps={{
          allowInstall: true
        }}
      />
    </>
  )
}

export default BlueprintsList
