import { Row } from '@tanstack/react-table'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { DataTable } from '@/components/ui/data-table'
import { useOrganizationTable } from '@/hooks'
import { IOrganization } from '@/models'
import { RoutePaths } from '@/routes/routes'

const DEFAULT_COLUMNS_TO_HIDE = {
  symbol: false,
  customer_number: false,
  new_pricing_tier: false,
  actual_users: false,
  max_users_last_month: false,
  non_billable_users_last_month: false,
  additional_paid_users: false,
  actual_customer: false,
  max_customer: false,
  email: false
}

const OrganizationList = () => {
  const {
    columns,
    data,
    isLoading: isOrganizationsLoading,
    isDataPointsLoading,
    isPricingTiersLoading,
    isPartnershipLoading,
    setPartnershipOrgIdsToFetch
  } = useOrganizationTable()

  const navigate = useNavigate()

  const isReadyExport = useMemo(() => {
    return (
      !isDataPointsLoading &&
      !isPricingTiersLoading &&
      !isOrganizationsLoading &&
      !isPartnershipLoading
    )
  }, [
    isDataPointsLoading,
    isOrganizationsLoading,
    isPartnershipLoading,
    isPricingTiersLoading
  ])

  return (
    <>
      <DataTable
        allowCreate
        columns={columns.map((column) => {
          if (column.id === 'new_pricing_tier') {
            return {
              ...column,
              cell: isPricingTiersLoading
                ? ({ row: _row }: { row: Row<IOrganization> }) => (
                    <div className="h-4 bg-muted rounded-lg animate-pulse" />
                  )
                : ({ row }: { row: Row<IOrganization> }) => (
                    <span>{row.getValue('new_pricing_tier')}</span>
                  )
            }
          } else if (
            [
              'max_users_last_month',
              'additional_paid_users',
              'max_billable_users_last_month',
              'actual_users',
              'actual_customer',
              'max_customer'
            ].includes(column.id)
          ) {
            return {
              ...column,
              cell: isDataPointsLoading
                ? ({ row: _row }: { row: Row<IOrganization> }) => (
                    <div className="h-4 bg-muted rounded-lg animate-pulse" />
                  )
                : ({ row }: { row: Row<IOrganization> }) => (
                    <span>{row.getValue(column.id)}</span>
                  )
            }
          } else if (column.id === 'max_partner_users') {
            return {
              ...column,
              cell: isPartnershipLoading
                ? ({ row: _row }: { row: Row<IOrganization> }) => (
                    <div className="h-4 bg-muted rounded-lg animate-pulse" />
                  )
                : ({ row }: { row: Row<IOrganization> }) => {
                    return <span>{row.getValue(column.id)}</span>
                  }
            }
          }

          return column
        })}
        data={data}
        defaultColumnVisibility={DEFAULT_COLUMNS_TO_HIDE}
        isLoading={isOrganizationsLoading}
        onPaginationChange={({ rows }) => {
          setPartnershipOrgIdsToFetch(rows.map((row) => row.original.id!))
        }}
        onRowClick={(row) => {
          navigate(`${RoutePaths.ORGANIZATIONS}/${row.original.id}`)
        }}
        tableConfigName="organization"
        toolbarProps={{
          exportButtonProps: {
            loading: !isReadyExport
          }
        }}
      />
    </>
  )
}

export default OrganizationList
