import { HeartIcon, ThumbsDownIcon, ThumbsUpIcon } from 'lucide-react'

export const Ratings = [
  {
    value: 'LOVE',
    label: 'Love',
    icon: HeartIcon
  },
  {
    value: 'LIKE',
    label: 'Like',
    icon: ThumbsUpIcon
  },
  {
    value: 'DISLIKE',
    label: 'Dislike',
    icon: ThumbsDownIcon
  }
]
