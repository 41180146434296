import { zodResolver } from '@hookform/resolvers/zod'
import classNames from 'classnames'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import * as z from 'zod'

import { OrganizationComponents } from '@/api/organization-client'
import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { MultiSelectAutoComplete } from '@/components/ui/multi-select-autocomplete'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/components/ui/select'
import { toast } from '@/components/ui/use-toast'
import { usePricingTiers, useTags } from '@/providers'
import { RoutePaths } from '@/routes/routes'
import { useMutationCreateOrganization } from '@/services'

const organizationFormSchema = z.object({
  name: z
    .string({
      required_error: 'Please enter an organization name.'
    })
    .min(3, {
      message: 'Organization name must be at least 3 characters.'
    })
    .max(100, {
      message: 'Organization name must not be longer than 100 characters.'
    }),
  email: z.string().email().optional(),
  type: z.string(),
  organization_use: z.string({
    required_error: 'Please select an organization use.'
  }),
  pricing_tier_id: z.string({
    required_error: 'Please select a pricing tier.'
  }),
  company_size: z.string().optional(),
  owner_email: z
    .string({ required_error: 'Please enter owner email.' })
    .email(),
  owner_name: z.string().optional()
})

type OrganizationFormValues = z.infer<typeof organizationFormSchema>

export function OrganizationForm() {
  const [selectedTags, setSelectedTags] = useState<string[]>([])
  const { organizationTags: allTags, isOrganizationTagsLoading } = useTags()
  const [isButtonLoading, setIsButtonLoading] = useState(false)

  const { pricingTiers, isLoading } = usePricingTiers()
  const navigate = useNavigate()
  const defaultValues: Partial<OrganizationFormValues> = {
    type: 'Vendor',
    email: '',
    pricing_tier_id: '',
    name: '',
    owner_email: '',
    owner_name: '',
    organization_use: ''
  }

  const form = useForm<OrganizationFormValues>({
    resolver: zodResolver(organizationFormSchema),
    defaultValues,
    mode: 'onChange'
  })

  const createOrganization = useMutationCreateOrganization()
  const handleCreateOrganization = (data: OrganizationFormValues) => {
    setIsButtonLoading(true)
    createOrganization.mutate(
      {
        organization_detail: {
          name: data.name,
          email_address: data?.email,
          type: data.type,
          pricing_tier_id: data.pricing_tier_id,
          tags: selectedTags,
          company_size: data.company_size,
          organization_use: data.organization_use
        },
        owner_user: {
          full_name: data.owner_name,
          email_address: data.owner_email
        }
      } as OrganizationComponents.Schemas.CreateOrganizationRequest,
      {
        onSuccess: () => {
          setIsButtonLoading(false)
          navigate(RoutePaths.ORGANIZATIONS)
        },
        onError: (err) => {
          setIsButtonLoading(false)
          toast({
            title: 'Error',
            description: `Error while creating organization. ${err?.['response']?.data?.error}`
          })
        }
      }
    )
  }

  return (
    <Form {...form}>
      <form
        className="space-y-4"
        onSubmit={form.handleSubmit(handleCreateOrganization)}
      >
        <div className="grid w-full grid-cols-2 gap-x-12">
          <div className="space-y-4">
            <span className="font-semibold text-primary">
              Organization Information
            </span>
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name</FormLabel>
                  <FormControl>
                    <Input placeholder="epilot GmbH" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="example@company.com"
                      type="email"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="organization_use"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Organization Use</FormLabel>
                  <Select
                    defaultValue={field.value}
                    disabled={isLoading}
                    onValueChange={field.onChange}
                    value={field.value}
                  >
                    <FormControl>
                      <SelectTrigger
                        className={classNames({
                          'text-primary': field.value,
                          'text-placeholder': !field.value
                        })}
                        disabled={isLoading}
                        loading={isLoading}
                      >
                        <SelectValue placeholder="Select" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value={'Production'}>Production</SelectItem>
                      <SelectItem value={'Sandbox'}>Sandbox</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="type"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Type</FormLabel>
                  <Select
                    defaultValue={field.value}
                    disabled
                    onValueChange={field.onChange}
                    value={field.value}
                  >
                    <FormControl>
                      <SelectTrigger
                        className={classNames({
                          'text-primary': field.value,
                          'text-placeholder': !field.value
                        })}
                      >
                        <SelectValue placeholder="Select a type" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value={'Vendor'}>Vendor</SelectItem>
                      <SelectItem value={'Partner'}>Partner</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="pricing_tier_id"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Pricing Tier</FormLabel>
                  <Select
                    defaultValue={field.value}
                    disabled={isLoading}
                    onValueChange={field.onChange}
                  >
                    <FormControl>
                      <SelectTrigger
                        className={classNames({
                          'text-primary': field.value,
                          'text-placeholder': !field.value
                        })}
                        disabled={isLoading}
                        loading={isLoading}
                      >
                        <SelectValue placeholder="Select a pricing tier" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {pricingTiers
                        ?.filter((x) => Boolean(x.id))
                        .map((tier) => (
                          <SelectItem key={tier.id} value={tier.id!}>
                            {tier.name}
                          </SelectItem>
                        ))}
                    </SelectContent>
                  </Select>
                  <FormDescription>
                    You can manage pricing tiers from the{' '}
                    <Button
                      className="p-0"
                      onClick={() => navigate(RoutePaths.PRICING_TIERS)}
                      variant={'link'}
                    >
                      pricing tiers page
                    </Button>
                    .
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="space-y-4">
            <span className="font-semibold  text-primary">
              Owner Information
            </span>
            <FormField
              control={form.control}
              name="owner_email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="example@company.com"
                      type="email"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="owner_name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Full Name</FormLabel>
                  <FormControl>
                    <Input placeholder="John Doe" type="text" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="space-y-4">
            <div className="space-y-2">
              <Label>Tags</Label>
              <MultiSelectAutoComplete
                allowNewValues
                isLoading={isOrganizationTagsLoading}
                items={allTags || []}
                onNewItem={(item) => item}
                onSelect={(item) =>
                  setSelectedTags((prev) => [...prev, item as string])
                }
                onUnselect={(item) =>
                  setSelectedTags((prevItems) =>
                    prevItems.filter(
                      (selectedItem) => selectedItem !== (item as string)
                    )
                  )
                }
                placeholder="Choose the tags to assign to the organization"
                selectedItems={selectedTags}
              />
            </div>
            <FormField
              control={form.control}
              name="company_size"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Company Size</FormLabel>
                  <Select
                    defaultValue={field.value}
                    onValueChange={field.onChange}
                    value={field.value}
                  >
                    <FormControl>
                      <SelectTrigger
                        className={classNames({
                          'text-primary': field.value,
                          'text-placeholder': !field.value
                        })}
                      >
                        <SelectValue placeholder="Select a company size" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value={'Small'}>Small</SelectItem>
                      <SelectItem value={'Medium'}>Medium</SelectItem>
                      <SelectItem value={'Large'}>Large</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>
        <div>
          <Button loading={isButtonLoading} type="submit">
            Create Organization
          </Button>
        </div>
      </form>
    </Form>
  )
}
