import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types'
import { Auth } from 'aws-amplify'
import { useState } from 'react'

import { Button } from '@/components/ui/button'
import { Icons } from '@/components/ui/icons'
import { cn } from '@/lib/utils'

interface UserAuthFormProps extends React.HTMLAttributes<HTMLDivElement> {}

export function UserAuthForm({ className, ...props }: UserAuthFormProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  return (
    <div className={cn('grid gap-6', className)} {...props}>
      <div className="relative">
        <div className="absolute inset-0 flex items-center">
          <span className="w-full border-t" />
        </div>
      </div>
      <Button
        loading={isLoading}
        onClick={() => {
          setIsLoading(true)
          Auth.federatedSignIn({
            provider: 'Epilot' as CognitoHostedUIIdentityProvider
          })
          setIsLoading(false)
        }}
        type="button"
        variant="outline"
      >
        <Icons.microsoft className="w-6 h-6 mr-2" />
        Microsoft
      </Button>
    </div>
  )
}
