import { ChevronDown, ChevronUp } from 'lucide-react'
import { useState } from 'react'

import { Button } from './ui/button'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger
} from './ui/collapsible'
import { Icons } from './ui/icons'
import { Input } from './ui/input'
import { Label } from './ui/label'

type OrganizationPartnershipProps = {
  loading: boolean
  partnershipData: {
    actual_partner_users: number
    max_partner_users: number
    active_partnerships: number
    ecp_users: number
    ip_users: number
  }
  defaultOpen?: boolean
}

const OrganizationPartnership = ({
  loading,
  partnershipData,
  defaultOpen = true
}: OrganizationPartnershipProps) => {
  const [open, setOpen] = useState(defaultOpen)

  return (
    <Collapsible
      className="px-4 py-2 space-y-2 border border-border rounded-md"
      onOpenChange={(e) => !loading && setOpen(e)}
      open={open}
    >
      <CollapsibleTrigger asChild>
        <div className="flex items-center justify-between cursor-pointer">
          <h4 className="text-sm font-semibold text-primary">Partnership</h4>
          <Button disabled={loading} className="p-0 w-9" size="sm" type="button" variant="ghost">
            {loading ? (
              <Icons.spinner className="w-4 h-4 mr-0" />
            ) : open ? (
              <ChevronUp className="w-4 h-4" />
            ) : (
              <ChevronDown className="w-4 h-4" />
            )}
            <span className="sr-only">Toggle</span>
          </Button>
        </div>
      </CollapsibleTrigger>
      <CollapsibleContent className="space-y-2">
        <div className="max-h-[90vh] overflow-y-auto">
          <div className="space-y-4">
            <div className="space-y-2">
              <Label>Actual Partner Users</Label>
              <Input disabled value={partnershipData?.actual_partner_users} />
            </div>
            <div className="space-y-2">
              <Label>Max Partner Users Last Month</Label>
              <Input disabled value={partnershipData?.max_partner_users} />
            </div>
            <div className="space-y-2">
              <Label>Active Partnerships</Label>
              <Input disabled value={partnershipData?.active_partnerships} />
            </div>
            <div className="space-y-2">
              <Label>ECP Users</Label>
              <Input disabled value={partnershipData?.ecp_users} />
            </div>
            <div className="space-y-2">
              <Label>Installer Portal Users</Label>
              <Input disabled value={partnershipData?.ip_users} />
            </div>
          </div>
        </div>
      </CollapsibleContent>
    </Collapsible>
  )
}

export default OrganizationPartnership
