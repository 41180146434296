import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions
} from '@tanstack/react-query'

import { getUserClient } from '@/api/user-client'
import { ErrorResponse, IUser } from '@/models'

const userClient = getUserClient()

export const USER_QUERY_IDS = {
  GET_USER_BY_ID: 'GET_USER_BY_ID',
  GET_USER_TAGS: 'GET_USER_TAGS',
  GET_USERS: 'GET_USERS',
  GET_ORGANIZATION_USER: 'GET_ORGANIZATION_USER',
  GET_USER_DATA_POINTS: 'GET_USER_DATA_POINTS'
}

export const useQueryGetOrgUsers: (
  orgId: string,
  opts?: UseQueryOptions<Awaited<ReturnType<typeof userClient.listUsersV2>>>
) => ReturnType<
  typeof useQuery<Awaited<ReturnType<typeof userClient.listUsersV2>>>
> = (orgId, opts) => {
  return useQuery<Awaited<ReturnType<typeof userClient.listUsersV2>>>({
    queryKey: [USER_QUERY_IDS.GET_ORGANIZATION_USER, orgId],
    queryFn: () =>
      userClient.listUsersV2(
        { limit: 1000 },
        {},
        { headers: { 'x-ivy-org-id': orgId } }
      ),
    refetchOnWindowFocus: true,
    ...opts
  })
}

export const useQueryGetUserById: (
  userId: string,
  orgId: string,
  opts?: Partial<UseQueryOptions<IUser>>
) => ReturnType<typeof useQuery<IUser>> = (userId, orgId, opts) => {
  return useQuery<IUser>({
    queryKey: [USER_QUERY_IDS.GET_USER_BY_ID, userId, orgId],
    queryFn: () =>
      userClient
        .getUserV2({ id: userId }, {}, { headers: { 'x-ivy-org-id': orgId } })
        .then((res) => res.data),
    refetchOnWindowFocus: false,
    retry: false,
    ...opts
  })
}

export const useQueryGetUserTags: (
  opts?: UseQueryOptions<Awaited<ReturnType<typeof userClient.getUserTags>>>
) => ReturnType<
  typeof useQuery<Awaited<ReturnType<typeof userClient.getUserTags>>>
> = (opts) => {
  return useQuery<Awaited<ReturnType<typeof userClient.getUserTags>>>({
    queryKey: [USER_QUERY_IDS.GET_USER_TAGS],
    queryFn: () => userClient.getUserTags(),
    refetchOnWindowFocus: false,
    retry: false,
    ...opts
  })
}

export const useQueryGetUserDataPoints: (
  opts?: UseQueryOptions<Awaited<ReturnType<typeof userClient.getDataPoints>>>
) => ReturnType<
  typeof useQuery<Awaited<ReturnType<typeof userClient.getDataPoints>>>
> = (opts) => {
  return useQuery<Awaited<ReturnType<typeof userClient.getDataPoints>>>({
    queryKey: [USER_QUERY_IDS.GET_USER_DATA_POINTS],
    queryFn: () => userClient.getDataPoints(),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
    ...opts
  })
}

export const useMutationUpdateUser: (
  orgId: string
) => ReturnType<
  typeof useMutation<
    Awaited<ReturnType<typeof userClient.updateUserV2>>,
    ErrorResponse,
    IUser,
    unknown
  >
> = (orgId) => {
  return useMutation<
    Awaited<ReturnType<typeof userClient.updateUserV2>>,
    ErrorResponse,
    IUser,
    unknown
  >({
    mutationFn: (user: IUser) => {
      return userClient.updateUserV2(user.id, user, {
        headers: {
          'x-ivy-org-id': orgId
        }
      })
    }
  })
}

export const useMutationDeleteUser: (
  orgId: string
) => ReturnType<
  typeof useMutation<
    Awaited<ReturnType<typeof userClient.deleteUserV2>>,
    ErrorResponse,
    string,
    unknown
  >
> = (orgId) => {
  const queryClient = useQueryClient()

  return useMutation<
    Awaited<ReturnType<typeof userClient.deleteUserV2>>,
    ErrorResponse,
    string,
    unknown
  >({
    mutationFn: (userId: string) => {
      return userClient.deleteUserV2({ id: userId }, null, {
        headers: {
          'x-ivy-org-id': orgId
        }
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [USER_QUERY_IDS.GET_USERS]
      })
    }
  })
}

export const useMutationUpdateUserEmail: (
  orgId: string
) => ReturnType<
  typeof useMutation<
    Awaited<ReturnType<typeof userClient.changeUserEmail>>,
    ErrorResponse,
    { oldEmail: string; newEmail: string },
    unknown
  >
> = (orgId) => {
  return useMutation<
    Awaited<ReturnType<typeof userClient.changeUserEmail>>,
    ErrorResponse,
    { oldEmail: string; newEmail: string },
    unknown
  >({
    mutationFn: ({
      oldEmail,
      newEmail
    }: {
      oldEmail: string
      newEmail: string
    }) => {
      return userClient.changeUserEmail(
        { username: oldEmail },
        { new_email: newEmail },
        {
          headers: {
            'x-ivy-org-id': orgId
          }
        }
      )
    }
  })
}
