import { API, Auth } from 'aws-amplify'
import { AxiosInstance } from 'axios'
import { LocalStorage } from 'ttl-localstorage'

export const ADMIN_PORTAL_TOKEN_LS_KEY = 'admin-portal-token'
export const ADMIN_PORTAL_TOKEN_REFRESH_TTL = 900 // 15 minutes

interface AxiosClientOpts {
  baseURL?: string
  allowXOrgSharing?: boolean
  xEpilotOrgId?: string
}

let tokenPromise: Promise<string> | null = null

export const getToken = async (): Promise<string> => {
  if (tokenPromise) return tokenPromise

  await Auth.currentAuthenticatedUser()

  const existingToken = await LocalStorage.get(ADMIN_PORTAL_TOKEN_LS_KEY)

  if (existingToken) {
    return existingToken
  }

  tokenPromise = API.get('internal-auth', '/auth', {})
    .then(({ token }) => {
      LocalStorage.put(
        ADMIN_PORTAL_TOKEN_LS_KEY,
        token,
        ADMIN_PORTAL_TOKEN_REFRESH_TTL
      )
      tokenPromise = null

      return token
    })
    .catch((error) => {
      tokenPromise = null
      throw error
    })

  return tokenPromise
}

export const configureClient = <ClientType extends AxiosInstance>(
  client: ClientType,
  opts: AxiosClientOpts = {}
) => {
  if (opts.baseURL) {
    client.defaults.baseURL = opts.baseURL
  }

  // add authorization header
  client.interceptors.request.use(async (config) => {
    let token = await LocalStorage.get(ADMIN_PORTAL_TOKEN_LS_KEY)

    if (!token) {
      // Try to refresh token before failing
      token = await getToken()
    }

    if (!token) {
      throw new Error('UNAUTHORIZED')
    }

    if (config.headers) {
      config.headers.authorization = `Bearer ${token}`
    }

    return config
  })

  return client
}
