import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { getGenAIClient } from '@/api/genai-client'
import { GenAIFeedback } from '@/models/GenAIFeedback'

const client = getGenAIClient()

enum GENAI_QUERY_IDS {
  GET_GENAI_FEEDBACKS = 'GET_GENAI_FEEDBACKS'
}

export const useQueryGetFeedbacks: (
  opts: Partial<UseQueryOptions<GenAIFeedback>>
) => ReturnType<typeof useQuery<GenAIFeedback>> = (opts) => {
  const fetchAllFeedbacks = async () => {
    const size = 1000
    const feedbacks = [] as GenAIFeedback
    let totalCount = 0
    let from = 0

    do {
      const { hits, results } = await fetchFeedbacks({
        from,
        size
      })

      totalCount = hits ? hits : 0

      if (results) {
        feedbacks?.push(...results)
      }
      if (!results?.length) {
        break
      }
      from += size
    } while ((feedbacks?.length ?? 0) < totalCount)

    return feedbacks
  }

  const fetchFeedbacks = async ({
    from,
    size
  }: {
    from: number
    size: number
  }) => {
    return client
      .getFeedbacks({
        from,
        size
      })
      .then((res) => res.data)
  }

  return useQuery<GenAIFeedback>({
    queryKey: [GENAI_QUERY_IDS.GET_GENAI_FEEDBACKS],
    queryFn: fetchAllFeedbacks,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
    ...opts
  })
}
