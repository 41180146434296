import { getClient } from '@epilot/internal-permissions-client'
import { useMemo } from 'react'

import { config } from '../configs/api.config'

import { configureClient } from './api-utils'

let client: ReturnType<typeof getClient>

export const getPermissionsClient = () => {
  if (!client) {
    client = getClient()

    return configureClient(client, {
      baseURL: config.PERMISSIONS
    })
  }

  return client
}

export const usePermissionClient = () =>
  useMemo(() => getPermissionsClient(), [])

export type { Components as PermissionComponents } from '@epilot/internal-permissions-client'
