import UserList from "../modules/user/page";
import { Dialog, DialogContent } from "./ui/dialog";

interface GenAIFeedbacksDialogProps {
  feedback?: {
    orgId: string;
    redirectUrl: URL;
  };
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export const GenAIFeedbacksDialog = ({
  feedback,
  open,
  onOpenChange,
}: GenAIFeedbacksDialogProps) => {

  return (
    <Dialog onOpenChange={onOpenChange} open={open}>
      <DialogContent className="max-w-full">
        <UserList
          loginAsUserDialogProps={{
            redirectUrl: feedback?.redirectUrl,
          }}
          organizationId={feedback?.orgId}
          tableProps={{
            toolbarProps: {
              showViewButton: false,
              exportButtonProps: {
                className: "hidden",
              },
            },
          }}
        />
      </DialogContent>
    </Dialog>
  );
};
