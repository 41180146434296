import { Link } from 'react-router-dom'

import { RoutePaths } from './routes'

const NotFound = () => {
  return (
    <section className="h-screen w-full flex flex-col items-center justify-center bg-background text-primary">
      <h1 className="text-6xl font-bold">404</h1>
      <p className="mx-auto max-w-[700px] md:text-xl mt-4">
        Oops! The page you are looking for cannot be found.
      </p>
      <Link
        className="inline-flex bg-primary h-9 items-center justify-center rounded-md px-4 py-2 text-sm font-medium mt-6 shadow transition-colors hover:bg-hover focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-zinc-950 disabled:pointer-events-none disabled:opacity-50 text-secondary hover:text-primary"
        to={RoutePaths.HOME}
      >
        Return Home
      </Link>
    </section>
  )
}

export default NotFound
