import { useEffect, useState } from 'react'
import { useParams } from 'react-router'

import { PermissionComponents } from '@/api/permissions-client'
import { RoleAndPermissionTableColumns } from '@/modules/role/columns'
import { useQueryGetAllRoles } from '@/services'

export const useRoleTable = (organizationId?: string) => {
  const [roles, setRoles] = useState<PermissionComponents.Schemas.Role[]>([])
  const { orgId } = useParams<{
    orgId: string
  }>()

  const { isLoading, data } = useQueryGetAllRoles({
    orgId: organizationId ?? orgId ?? ''
  })

  useEffect(() => {
    if (data?.data) {
      setRoles(data.data.roles ?? [])
    }
  }, [data?.data])

  return {
    data: roles,
    isLoading: isLoading,
    columns: RoleAndPermissionTableColumns
  }
}
