import { createContext, useContext } from 'react'

import { OrganizationComponents } from '@/api'
import { useQueryGetOrganizationsToCleanup } from '@/services'

export interface OrganizationCleanupContext {
  organizationsToCleanup: OrganizationComponents.Schemas.OrganizationToCleanup[]
  isLoading: boolean
}

const OrganizationCleanupContext = createContext<
  Partial<OrganizationCleanupContext>
>({})

export const OrganizationCleanupContextProvider = (props: {
  children: JSX.Element
}) => {
  const organizationsToCleanupQueryResponse =
    useQueryGetOrganizationsToCleanup()

  return (
    <OrganizationCleanupContext.Provider
      value={{
        organizationsToCleanup: organizationsToCleanupQueryResponse.data ?? [],
        isLoading: organizationsToCleanupQueryResponse.isFetching
      }}
    >
      {props.children}
    </OrganizationCleanupContext.Provider>
  )
}

export const useOrganizationCleanup = () =>
  useContext(OrganizationCleanupContext)
