import { GenAIFeedbackTableColumns } from '@/modules/genai-feedbacks/columns'
import { useQueryGetFeedbacks } from '@/services/genai.service'

export const useGenAIFeedbacksTable = () => {
  const { isLoading, data } = useQueryGetFeedbacks({})

  return {
    data,
    isLoading: isLoading,
    columns: GenAIFeedbackTableColumns
  }
}
