import { EyeOpenIcon } from '@radix-ui/react-icons'
import { Row } from '@tanstack/react-table'
import { Copy } from 'lucide-react'
import { useState } from 'react'

import { GenAIFeedbacksDialog } from '@/components/genai-feedbacks-dialog'
import { DataTable } from '@/components/ui/data-table'
import { DataTableRowActions } from '@/components/ui/data-table-row-actions'
import { toast } from '@/components/ui/use-toast'
import { config } from '@/configs/env.config'
import { useGenAIFeedbacksTable } from '@/hooks/useGenAIFeedbacksTable'
import { GenAIFeedbackElement } from '@/models/GenAIFeedback'

const GenAIFeedbackList = () => {
  const { columns, data, isLoading } = useGenAIFeedbacksTable()
  const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false)
  const [selectedFeedback, setSelectedFeedback] = useState<{
    orgId: string
    redirectUrl: URL
  }>()

  const getThreadRedirectURL = (jobId: string) => {
    const redirectUrl = new URL(
      `${config['360_PORTAL_URL']}/app/entity/opportunity`
    )

    redirectUrl.searchParams.append('drawer', 'messaging')
    redirectUrl.searchParams.append(
      'threadEntityId',
      getThreadIdFromJobId(jobId)
    )

    return redirectUrl
  }

  const getThreadIdFromJobId = (jobId: string) => {
    return jobId.split('#')[1]
  }

  const getDataTableRowActions = (row: Row<GenAIFeedbackElement>) => {
    return [
      {
        icon: <EyeOpenIcon className="w-4 h-4 mr-2" />,
        label: 'Show thread',
        onClick: () => {
          setSelectedFeedback({
            orgId: row.original.org_id!,
            redirectUrl: getThreadRedirectURL(row.original.job_id!)
          })
          setOpenFeedbackDialog(true)
        }
      },
      {
        icon: <Copy className="w-4 h-4 mr-2" />,
        label: 'Copy thread summary link',
        onClick: async () => {
          try {
            navigator.clipboard.writeText(
              getThreadRedirectURL(row.original.job_id!).toString()
            )
            toast({
              title: 'Copied',
              description: 'Thread summary link copied to clipboard'
            })
          } catch (error) {
            toast({
              title: 'Error',
              description: 'Could not copy thread summary link to clipboard'
            })
          }
        }
      }
    ]
  }

  return (
    <>
      <DataTable
        columns={columns.map((column) => {
          if (column.id === 'actions') {
            return {
              ...column,
              cell: ({ row }: { row: Row<GenAIFeedbackElement> }) => (
                <DataTableRowActions
                  items={getDataTableRowActions(row)}
                  key={column.id}
                  row={row}
                  showCopyId={false}
                />
              )
            }
          }

          return column
        })}
        data={data!}
        isLoading={isLoading}
        tableConfigName="genai-feedback"
      />
      <GenAIFeedbacksDialog
        feedback={selectedFeedback}
        onOpenChange={(open) => setOpenFeedbackDialog(open)}
        open={openFeedbackDialog}
      />
    </>
  )
}

export default GenAIFeedbackList
