import { useState } from "react";

import { Button } from "./ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "./ui/dialog";
import { Label } from "./ui/label";

import { getOrganizationClient } from "@/api";
import { IOrganization } from "@/models";
import { Input } from "./ui/input";
import { toast } from "./ui/use-toast";

interface OrganizationDeleteDialogProps {
  organization?: IOrganization;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onDelete: () => void;
}

const organizationClient = getOrganizationClient();

export function OrganizationDeleteDialog({
  organization,
  onOpenChange,
  open,
  onDelete
}: OrganizationDeleteDialogProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [input, setInput] = useState<string>("");

  const handleDeleteOrganization = async () => {
    setIsLoading(true);

    await organizationClient
      .deleteOrganization({ org_id: organization?.id! })
      .then(() => {
        toast({
          title: "Organization marked to be deleted",
          description:
            "All related data with this organization will be deleted in the next 24 hours.",
        });
      })
      .catch((error) => {
        toast({
          title: "Failed to delete organization",
          description: error.message,
        });
      })
      .finally(() => {
        onDelete();
        setIsLoading(false);
        setInput("");
        onOpenChange(false);
      });
  };

  return (
    <Dialog
      onOpenChange={(open) => {
        setInput("");
        onOpenChange(open);
      }}
      open={open}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="text-primary">
            You're about to delete the organization "{organization?.name}"
          </DialogTitle>
          <DialogDescription>
            This action is irreversible. Please be sure you want to delete the
            organization before proceeding.
          </DialogDescription>
        </DialogHeader>

        <div>
          <Label htmlFor="organization_name">
            Please type "{organization?.name}" to proceed
          </Label>
          <Input
            id="organization_name"
            onChange={(e) => setInput(e.target.value)}
            placeholder={organization?.name || ""}
            value={input}
          />
        </div>
        <DialogFooter className="items-center justify-between flex w-full">
          <Button
            disabled={input !== organization?.name}
            loading={isLoading}
            onClick={handleDeleteOrganization}
            type="submit"
            variant={"destructive"}
          >
            Confirm
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
