import { startCase } from 'lodash'

import { config } from '@/configs/env.config'

type SlackMessageParams = {
  userEmail: string
  featureName: string
  payload: unknown
  orgId: string
  orgName: string
}

class SlackService {
  private webhookUrl: string

  constructor(webhookUrl: string) {
    this.webhookUrl = webhookUrl
  }

  public async sendMessage({
    userEmail,
    featureName,
    payload,
    orgId,
    orgName
  }: SlackMessageParams): Promise<void> {
    if (config.STAGE !== 'prod') return

    const message = {
      attachments: [
        {
          fallback: `*${userEmail}* has updated the *${startCase(
            featureName
          )}* feature for organization *${orgId} - ${orgName}*`,
          pretext: `*${userEmail}* has updated the *${startCase(
            featureName
          )}* feature for organization *${orgId} - ${orgName}*`,
          color: '#0CA5E9',
          fields: [
            {
              title: 'Payload',
              value: JSON.stringify(payload),
              short: false
            }
          ]
        }
      ]
    }

    try {
      const response = await fetch(this.webhookUrl, {
        method: 'POST',
        body: JSON.stringify(message)
      })

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`)
      }
    } catch (error) {
      console.error('Failed to send message to slack', error)
    }
  }
}

export default SlackService
