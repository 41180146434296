import { ChevronDownIcon } from 'lucide-react'
import { z } from 'zod'

import { PricingTierComponents } from '@/api'
import { Checkbox } from '@/components/ui/checkbox'
import { DataTableColumnHeader } from '@/components/ui/data-table-column-header'
import { DataTableRowActions } from '@/components/ui/data-table-row-actions'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu'
import { FieldType, TableColumn } from '@/models'
import { unsensitiveFilter } from '@/utils'

export const getPricingTierTableColumns = (
  onDelete: (pricingTierId: string) => void
): TableColumn<PricingTierComponents.Schemas.PricingTier>[] => [
  {
    id: 'select',
    header: ({ table }) => (
      <div className="flex items-center space-x-2">
        <Checkbox
          aria-label="Select all in the page"
          checked={table.getIsAllPageRowsSelected()}
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        />
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <div className="cursor-pointer">
              <ChevronDownIcon className="h-4 w-4" />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-56 flex items-center">
            <Checkbox
              aria-label="Select all"
              checked={table.getIsAllRowsSelected()}
              className="mr-2"
              id="select-all"
              onCheckedChange={(value) => table.toggleAllRowsSelected(!!value)}
            />
            <span>Select all items</span>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    ),
    cell: ({ row }) => (
      <Checkbox
        aria-label="Select row"
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
      />
    ),
    enableSorting: false,
    enableHiding: false,
    enableResizing: false,
    size: 40,
    enableGlobalFilter: false,
    enableColumnFilter: false
  },
  {
    id: 'id',
    accessorKey: 'id',
    displayName: 'ID',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="ID" />
    ),
    enableResizing: false,
    size: 60,
    filterFn: unsensitiveFilter,
    sortingFn: (a, b) => {
      const idA = a.original.id
      const idB = b.original.id

      if (!idA && !idB) {
        return 0
      } else if (!idA) {
        return 1
      } else if (!idB) {
        return -1
      }

      return parseInt(idA) - parseInt(idB)
    }
  },
  {
    id: 'name',
    accessorKey: 'name',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Name" />
    ),
    displayName: 'Name',
    size: 300,
    enableResizing: true,
    sortUndefined: 1,
    filterFn: unsensitiveFilter
  },
  {
    id: 'in_use',
    accessorKey: 'in_use',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="In Use" />
    ),
    displayName: 'In Use',
    sortUndefined: 1,
    filterFn: unsensitiveFilter
  },
  {
    id: 'created_date',
    accessorKey: 'created_date',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Creation Date" />
    ),
    accessorFn: (row) => (row.created_date ? row.created_date : undefined),
    cell: ({ row }) => {
      if (row.original.created_date) {
        const date = new Date(row.original.created_date)

        return date.toLocaleString()
      } else {
        undefined
      }
    },
    sortingFn: (a, b) => {
      if (a.original.created_date && b.original.created_date) {
        const dateA = new Date(a.original.created_date)
        const dateB = new Date(b.original.created_date)

        if (!dateA && !dateB) {
          return 0
        } else if (!dateA) {
          return 1
        } else if (!dateB) {
          return -1
        }

        return dateA.getTime() - dateB.getTime()
      } else {
        return 0
      }
    },
    displayName: 'Creation Date',
    sortUndefined: -1,
    enableGlobalFilter: false,
    enableColumnFilter: false
  },
  {
    id: 'updated_date',
    accessorKey: 'updated_date',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Updated Date" />
    ),
    accessorFn: (row) => (row.updated_date ? row.updated_date : undefined),
    cell: ({ row }) => {
      if (row.original.updated_date) {
        const date = new Date(row.original.updated_date)

        return date.toLocaleString()
      } else {
        undefined
      }
    },
    sortingFn: (a, b) => {
      if (a.original.updated_date && b.original.updated_date) {
        const dateA = new Date(a.original.updated_date)
        const dateB = new Date(b.original.updated_date)

        if (!dateA && !dateB) {
          return 0
        } else if (!dateA) {
          return 1
        } else if (!dateB) {
          return -1
        }

        return dateA.getTime() - dateB.getTime()
      } else {
        return 0
      }
    },
    displayName: 'Updated Date',
    sortUndefined: -1,
    enableGlobalFilter: false,
    enableColumnFilter: false
  },
  {
    id: 'created_by',
    accessorKey: 'created_by',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Created By" />
    ),
    displayName: 'Created By',
    sortUndefined: 1,
    filterFn: unsensitiveFilter
  },
  {
    id: 'updated_by',
    accessorKey: 'updated_by',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Updated By" />
    ),
    displayName: 'Updated By',
    sortUndefined: 1,
    filterFn: unsensitiveFilter
  },
  {
    id: 'actions',
    cell: ({ row }) => {
      return (
        <DataTableRowActions
          deleteProps={{
            handler: () => onDelete(row.original.id!),
            disabled: row.original.in_use > 0
          }}
          row={row}
        />
      )
    },
    enableSorting: false,
    enableHiding: false,
    enableResizing: false,
    size: 30
  }
]

export const PricingTierSettings = [
  {
    key: 'name',
    label: 'Name',
    type: FieldType.TEXT_FIELD,
    fieldType: z
      .string({ required_error: 'Please enter pricing tier name.' })
      .default(''),
    default: ''
  },
  {
    key: 'sso',
    label: 'Single Sign-On',
    group: 'Security',
    type: FieldType.SWITCH,
    fieldType: z.object({ enabled: z.boolean().default(false) }),
    default: { enabled: false }
  },
  {
    key: 'webhook',
    label: 'Webhooks',
    group: '3rd Party Solutions',
    type: FieldType.SWITCH,
    fieldType: z.object({ enabled: z.boolean().default(false) }),
    default: { enabled: false }
  },
  {
    key: 'customer_portal',
    label: 'Customer portal',
    type: FieldType.SWITCH,
    fieldType: z.object({ enabled: z.boolean().default(false) }),
    default: { enabled: false }
  },
  {
    key: 'installer_portal',
    label: 'Installer Portal',
    type: FieldType.SWITCH,
    fieldType: z.object({ enabled: z.boolean().default(false) }),
    default: { enabled: false }
  },
  {
    key: 'metering',
    label: 'Metering',
    type: FieldType.SWITCH,
    fieldType: z.object({ enabled: z.boolean().default(false) }),
    default: { enabled: false }
  },
  {
    key: 'legacy_craftsmen',
    label: 'Craftsmen directory',
    type: FieldType.SWITCH,
    fieldType: z.object({ enabled: z.boolean().default(false) }),
    default: { enabled: false }
  },
  {
    key: 'custom_design',
    label: 'Custom design',
    type: FieldType.SWITCH,
    fieldType: z.object({ enabled: z.boolean().default(false) }),
    default: { enabled: false }
  },
  {
    key: 'product_availability_service',
    label: 'Product availability service',
    type: FieldType.SWITCH,
    fieldType: z.object({ enabled: z.boolean().default(false) }),
    default: { enabled: false }
  },
  {
    key: 'tracking_in_journey',
    label: 'Tracking in Journey',
    type: FieldType.SWITCH,
    fieldType: z.object({ enabled: z.boolean().default(false) }),
    default: { enabled: false }
  },
  {
    key: 'trigger_automation_in_wf',
    label: 'Trigger automation in wf',
    type: FieldType.SWITCH,
    fieldType: z.object({ enabled: z.boolean().default(false) }),
    default: { enabled: false }
  },
  {
    key: 'share_journey',
    label: 'Share Journey',
    type: FieldType.SWITCH,
    fieldType: z.object({ enabled: z.boolean().default(false) }),
    default: { enabled: false }
  },
  {
    key: 'permissions_on_attribute_level_in_ecp',
    label: 'Permissions on attribute level in ECP',
    type: FieldType.SWITCH,
    fieldType: z.object({ enabled: z.boolean().default(false) }),
    default: { enabled: false }
  },
  {
    key: 'attribute_permissions',
    label: 'Attribute Permissions',
    type: FieldType.SWITCH,
    fieldType: z.object({ enabled: z.boolean().default(false) }),
    default: { enabled: false }
  },
  {
    key: 'bulk_messages',
    label: 'Bulk Messages',
    type: FieldType.SWITCH,
    fieldType: z.object({ enabled: z.boolean().default(false) }),
    default: { enabled: false }
  },
  {
    key: 'get_ag',
    label: 'GetAG',
    type: FieldType.SWITCH,
    fieldType: z.object({ enabled: z.boolean().default(false) }),
    default: { enabled: false }
  },
  {
    key: 'automation_conditions',
    label: 'Automation Conditions',
    type: FieldType.SWITCH,
    fieldType: z.object({ enabled: z.boolean().default(false) }),
    default: { enabled: false }
  },
  {
    key: 'free_user',
    label: 'Free user',
    group: 'User',
    type: FieldType.NUMBER_FIELD,
    fieldType: z.object({ quota: z.coerce.number().min(0).default(0) }),
    default: { quota: 0 }
  },
  {
    key: 'groups',
    label: 'Groups',
    type: FieldType.NUMBER_FIELD,
    fieldType: z.object({ quota: z.coerce.number().min(0).default(1) }),
    default: { quota: 1 }
  },
  {
    key: 'custom_email_subdomain',
    label: 'Custom email domain',
    type: FieldType.NUMBER_FIELD,
    fieldType: z.object({ quota: z.coerce.number().min(0).default(0) }),
    default: { quota: 0 }
  },
  {
    key: 'designs',
    label: 'Designs',
    type: FieldType.NUMBER_FIELD,
    fieldType: z.object({ quota: z.coerce.number().min(0).default(0) }),
    default: { quota: 0 }
  },
  {
    key: 'roles',
    label: 'Roles',
    type: FieldType.NUMBER_FIELD,
    fieldType: z.object({ quota: z.coerce.number().min(0).default(0) }),
    default: { quota: 0 }
  },
  {
    key: 'workflows',
    label: 'Workflows',
    type: FieldType.NUMBER_FIELD,
    fieldType: z.object({ quota: z.coerce.number().min(0).default(0) }),
    default: { quota: 0 }
  },
  {
    key: 'active_workflows',
    label: 'Active Workflows',
    type: FieldType.NUMBER_FIELD,
    fieldType: z.object({ quota: z.coerce.number().min(0).default(0) }),
    default: { quota: 0 }
  },
  {
    key: 'contract_entity',
    label: 'Contract Entity',
    type: FieldType.NUMBER_FIELD,
    fieldType: z.object({ quota: z.coerce.number().min(0).default(0) }),
    default: { quota: 0 }
  },
  {
    key: 'genai_rpm',
    label: 'GenAI Rate Limit (RPM)',
    type: FieldType.NUMBER_FIELD,
    fieldType: z.object({ quota: z.coerce.number().min(0).default(0) }),
    default: { quota: 10 }
  },
  {
    key: 'genai_tpm',
    label: 'GenAI Rate Limit (TPM)',
    type: FieldType.NUMBER_FIELD,
    fieldType: z.object({ quota: z.coerce.number().min(0).default(0) }),
    default: { quota: 10 }
  },
  {
    key: 'journey_context_parameters',
    label: 'Journey Context Parameters & Entity Updating Journeys',
    type: FieldType.SWITCH,
    fieldType: z.object({ enabled: z.boolean().default(false) }),
    default: { enabled: false }
  },
  {
    key: 'active_journeys',
    label: 'Active Journey Limit',
    type: FieldType.NUMBER_FIELD,
    default: { quota: -1 }
  },
  {
    key: 'audit_log',
    label: 'Audit log',
    type: FieldType.SWITCH,
    fieldType: z.object({ enabled: z.boolean().default(false) }),
    default: { enabled: false }
  },
  {
    key: 'journey_links',
    label: 'Journey Links (PQ links)',
    type: FieldType.SWITCH,
    fieldType: z.object({ enabled: z.boolean().default(false) }),
    default: { enabled: false }
  }
] as {
  key: string
  label: string
  type: number
  fieldType: z.ZodSchema
  default: unknown
}[]
