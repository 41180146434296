import { MarketplaceCMSComponents } from '@/api/marketplace-cms-client'

export type IMarketplaceBlueprint =
  MarketplaceCMSComponents.Schemas.MarketplaceBlueprint
export type ICreateMarketplaceBlueprint =
  MarketplaceCMSComponents.Schemas.CreateMarketplaceBlueprintBody

export const mainCategories = [
  {
    name: 'App Partner',
    id: '66e96940489fe3fce1b330b4'
  },
  {
    name: 'Netzprozesse',
    id: '66e6f7faa5f4d85bc99996a6'
  },
  {
    name: 'Commodities',
    id: '66e6f7faa5f4d85bc99996a5'
  },
  {
    name: 'Energielösungen',
    id: '66e6f7faa5f4d85bc99996a4'
  }
]

export const categories = [
  {
    name: 'E-Mobilität',
    id: '66e6f7faa5f4d85bc999953d',
    mainCategory: '66e6f7faa5f4d85bc99996a4'
  },
  {
    name: 'Solar',
    id: '66e6f7faa5f4d85bc999951e',
    mainCategory: '66e6f7faa5f4d85bc99996a4'
  },
  {
    name: 'Speicher',
    id: '66e6f7faa5f4d85bc99994fe',
    mainCategory: '66e6f7faa5f4d85bc99996a4'
  },
  {
    name: 'Wärme',
    id: '66e6f7faa5f4d85bc99994e0',
    mainCategory: '66e6f7faa5f4d85bc99996a4'
  },
  {
    name: 'B2C',
    id: '66e6f7faa5f4d85bc99994c4',
    mainCategory: '66e6f7faa5f4d85bc99996a4'
  },
  {
    name: 'B2B',
    id: '66e6f7faa5f4d85bc99994a4',
    mainCategory: '66e6f7faa5f4d85bc99996a4'
  },
  {
    name: 'Strom',
    id: '66e6f7faa5f4d85bc9999484',
    mainCategory: '66e6f7faa5f4d85bc99996a5'
  },
  {
    name: 'Gas',
    id: '66e6f7faa5f4d85bc9999468',
    mainCategory: '66e6f7faa5f4d85bc99996a5'
  },
  {
    name: 'Bündelprodukte',
    id: '66e6f7faa5f4d85bc9999446',
    mainCategory: '66e6f7faa5f4d85bc99996a4'
  },
  {
    name: 'Inbetriebsetzung',
    id: '66e6f7faa5f4d85bc9999424',
    mainCategory: '66e6f7faa5f4d85bc99996a6'
  },
  {
    name: 'Hausanschluss',
    id: '66e6f7faa5f4d85bc9999402',
    mainCategory: '66e6f7faa5f4d85bc99996a6'
  },
  {
    name: 'Anmeldung von Bezugs- und Erzeugungsanlagen',
    id: '66e6f7faa5f4d85bc99993e5',
    mainCategory: '66e6f7faa5f4d85bc99996a6'
  }
]

export type Category = {
  id: string
  name: string
}
