import { useEffect, useState } from 'react'
import { useParams } from 'react-router'

import { EntityComponents } from '@/api'
import { PartnerTableColumns } from '@/modules/partner/columns'
import { useDataPoints } from '@/providers'
import { useQueryGetActivePartnerByOrgId } from '@/services'

export const usePartnerTable = () => {
  const [partners, setPartners] = useState<
    EntityComponents.Schemas.EntityItem[]
  >([])
  const { orgId } = useParams<{
    orgId: string
  }>()

  const { dataPoints, isLoading: isDataPointsLoading } = useDataPoints()

  const { isLoading, data } = useQueryGetActivePartnerByOrgId(orgId!, {
    enabled: Boolean(orgId)
  })

  useEffect(() => {
    if (data?.data && dataPoints) {
      const partnersWithDP = data.data.results?.map((partner) => {
        const dp = dataPoints?.find(
          (dp) => dp?.org_id?.toString() === partner?.partner_org_id?.toString()
        )

        return {
          ...partner,
          actual_users: dp?.actual_users,
          max_users: dp?.max_users_last_month
        }
      })

      setPartners(partnersWithDP as any)
    }
  }, [data?.data, dataPoints])

  return {
    data: partners,
    isLoading: isLoading || isDataPointsLoading,
    columns: PartnerTableColumns
  }
}
