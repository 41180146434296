import { getClient } from '@epilot/internal-genai-client'
import { useMemo } from 'react'

import { config } from '../configs/api.config'

import { configureClient } from './api-utils'

let client: ReturnType<typeof getClient>

export const getGenAIClient = () => {
  if (!client) {
    client = getClient()

    configureClient(client, {
      baseURL: config.GENAI
    })
  }

  return client
}

export const useGenAIClient = () => useMemo(() => getGenAIClient(), [])

export type {
  Components as GenAIComponents,
  Paths as GenAIPaths
} from '@epilot/internal-genai-client'
