import React, { createContext, useContext } from 'react'

import { PermissionComponents } from '@/api/permissions-client'
import {
  useQueryGetAllAssignmentsAllOrgs,
  useQueryGetAllRolesAllOrgs
} from '@/services'

export interface IRoleAndPermissionContext {
  loadingGetAllRolesOrgIds: boolean
  loadingGetAssignedRoleUsers: boolean
  allAssignmentsAllOrgs?: PermissionComponents.Schemas.OrgAssignments[]
  allRolesAllOrgs?: PermissionComponents.Schemas.OrgRoles[]
  isReadyExport: boolean
}

const RoleAndPermissionContext = createContext<IRoleAndPermissionContext>({
  loadingGetAllRolesOrgIds: false,
  loadingGetAssignedRoleUsers: false,
  allAssignmentsAllOrgs: [],
  allRolesAllOrgs: [],
  isReadyExport: false
})

export const RoleAndPermissionContextProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const {
    data: allAssignmentsAllOrgs,
    isLoading: isLoadingAllAssignmentsAllOrgs
  } = useQueryGetAllAssignmentsAllOrgs()

  const { data: allRolesAllOrgs, isLoading: isLoadingAllRolesAllOrgs } =
    useQueryGetAllRolesAllOrgs()

  return (
    <RoleAndPermissionContext.Provider
      value={{
        loadingGetAllRolesOrgIds: isLoadingAllRolesAllOrgs,
        loadingGetAssignedRoleUsers: isLoadingAllAssignmentsAllOrgs,
        isReadyExport:
          !isLoadingAllAssignmentsAllOrgs && !isLoadingAllRolesAllOrgs,
        allAssignmentsAllOrgs: allAssignmentsAllOrgs?.data.assignments,
        allRolesAllOrgs: allRolesAllOrgs
      }}
    >
      {children}
    </RoleAndPermissionContext.Provider>
  )
}

export const useRoleAndPermission = () => useContext(RoleAndPermissionContext)
